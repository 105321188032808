import styled from "styled-components";
import {typoStyles} from "./typo.js";
import {DEFAULT_FONT_SIZE, BREAKPOINT, COLOR_ACCENT} from "./vars.js";

export const MainWrapper = styled.div`
    ${typoStyles}
`;


export const MainContainer = styled.div`
    margin: 0 auto;
    max-width: 1520px;
    padding: 0 ${30/DEFAULT_FONT_SIZE}em;
    box-sizing: content-box;
 
`;

export const MainSectionsWrapper = styled.div`
`;