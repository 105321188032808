import React, { useEffect, useRef, useState } from 'react';
import './PastOneYearSegmentation.css';
import TabComponent from '../common/TabComponent';
import TableComponent, { TableData, TableColumn } from './TableComponent';
import TittleBar from '../TittleBar';
import CBCVTab from '../../CBCVTab';
import useRESTAPI from '../Contexts/RESTAPI';
import { useAuth } from '../Contexts/AuthContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { formatNumberWithCommas } from '../common/Auxiliary';
import NoReport from '../common/Norepot';
import SaveAsPDFButton from '../common/SaveAsPDFButton';

export const PastOneYearSegmentation = (): JSX.Element => {
  const navi = useNavigate()
  const { tab } = useParams();
  const printRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!tab) {
      const searchParams = new URLSearchParams(location.search);
      const searchString = searchParams.toString();
      navi(`/customers-last-year/all${searchString ? `?${searchString}` : ''}`);
    }
  }, [tab]);

  const { user_id, admin_id } = useAuth();

  const {
    items: requests,
    fetchItems: fetchRequests,
  } = useRESTAPI('/api/cbcv-request');


  const location = useLocation();
  useEffect(() => {
    if (admin_id === undefined)
      return;
    const _ = async () => {
      const id = new URLSearchParams(location.search).get('request_id');
      if (id) {
        const req = await fetchRequests({ request_id: id })
        console.log(req);
        setLastRequests(req[0]);
      }
    }
    _();

  }, [admin_id, tab]);

  const [isRecv, setRecv] = useState<boolean>(false);
  useEffect(() => {

  }, [isRecv]);

  useEffect(()  => {
    const _ = async () => {
      if (user_id === undefined) 
        return;
         const data = await fetchRequests({ cbcv_user_id: user_id, status: 5 });
         console.log();
        if(!data || data.length ===0)
          setRecv(true);
    }
    _();

  }, [user_id, tab]);

  const [lastRequest, setLastRequests] = useState<any>();


  useEffect(() => {

    setLastRequests(requests[requests.length - 1]);
  }, [requests]);

  const [reportData, setReportData] = useState<any>();

  useEffect(() => {
    if (lastRequest === undefined)
      return;
    const url = '/api/cbcv-api/customer_past_1y_segment/'
    const _ = async () => {

      const data = {
        user_id: lastRequest.cbcv_user_id.id,
        request_id: lastRequest.request_id,
        channel_param: tab,
      }
      console.log(data);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      const responseData = await response.json();

      if(responseData.message === 'there is no report available')
        {
          alert('데이터가 존재하지 않습니다')
          navi(-1)
        }
        else
        {
          setReportData(responseData);
        }
        setRecv(true);
    };
    _();
  }, [lastRequest]);



  if (!reportData) {
    return (
      <div className="flex  text-[#474A4E]">
        <CBCVTab></CBCVTab>
        <div className="BrandAnalysis w-full  min-w-[1200px]">
          <TittleBar tittle="브랜드 고객 분석" />

          {isRecv ? <NoReport></NoReport> : <div className='flex flex-col gap-11 h-full w-full justify-center items-center' />}
        </div>
      </div>
    );
  }

  const columns: TableColumn[] = [
    { key: 'label', label: '', width: 250 },
    { key: 'vvip', label: 'VVIP (상위 1%)', width: 100 },
    { key: 'vip', label: 'VIP (상위 10%)', width: 100 },
    { key: 'gold', label: 'GOLD (상위 30%)', width: 100 },
    { key: 'silver', label: 'SILVER (상위 50%)', width: 100 },
    { key: 'total', label: '전체', width: 100 },
  ];

  const data = reportData.data.segment_table;


  const renderCol = (column: TableColumn) => {
    const needLine = column.key !== columns[0].key;

    const aa =
      column.key === columns[columns.length - 1].key
        ? 'border-gray-300    font-bold'
        : '';
    return (
      <div
        className={`bg-gray-200 ${aa} border-b-2 flex items-center justify-start w-full  h-[48px]`}
      >
        {needLine ? <div className="bg-gray-400 h-2/4 w-[1px]"></div> : <></>}

        <div className="flex w-full justify-center">{column.label}</div>
      </div>
    );
  };

  const renderCell = (
    rowindex: number,
    rowData: TableData,
    column: TableColumn,
  ) => {
    let leftBorder =
      columns[0].key !== column.key &&
        column.key !== columns[columns.length - 1].key
        ? ' border-l-2'
        : 'font-bold';

    let border =
      rowindex + 1 === data.length ? leftBorder : leftBorder + ' border-b-2 ';

    const bg =
      column.key !== columns[columns.length - 1].key
        ? ' bg-white'
        : ' border-gray-300 bg-gray-200';


    let text = rowData[column.key];

    if (text && column.key !== 'label') {
      if (rowindex === 0) {
        text = '₩ ' + formatNumberWithCommas(text);
      }
      if (rowindex === 2) {
        text = '₩ ' + formatNumberWithCommas(text);
      }
      if (rowindex === 3) {
        text = '₩ ' + formatNumberWithCommas(text);
      }
      if (rowindex === 4) {

        text = (Number.parseFloat(text) * 100).batterToFix(2).toString() + '%'
      }

    }


    //rowData[column.key] ? formatNumberWithCommas(rowData[column.key]) : ''
    if (column.key == 'label') {
      return (
        <div
          className={`${border} ${bg} flex  items-center justify-start w-full  h-[39px]`}
        >
          {true ? <div className={' h-full w-[1px]'}></div> : <></>}
          <div className="w-full flex justify-center"> {text}</div>
        </div>
      );
    }
    return (
      <div
        className={`${border} ${bg} flex  items-center justify-start w-full  h-[39px]`}
      >
        {true ? <div className={' h-full w-[1px]'}></div> : <></>}
        <div className="w-full flex justify-end pr-5"> {text}</div>
      </div>
    );
  };

  return (
    <div className="flex  text-[#474A4E]">
      <CBCVTab></CBCVTab>
      <div className='w-full'>
        <div className='min-w-[1200px]'>
          <TittleBar tittle='브랜드 고객 분석' />
          {reportData &&
            <div ref={printRef} className="flex justify-center item-center pt-[34px] h-screen w-full FutureClientSegmentation px] ">
              <div className="w-[1100px]">
                <div  className='flex justify-between items-center'>
                <div className="text-wrapper">지난 1년간 고객 세그먼트   </div>
                <SaveAsPDFButton printRef={printRef}/> 
                  </div>

                <div className="h-[45px]"></div>
                <TabComponent
                  mainClassName="flex text-[#7B828B] gap-5"
                  activeClassName="h-10 px-4 text-[#7B828B] "
                  itemClassName="h-10 font-bold px-4 text-[#4046E3] border-b-4 border-[#4046E3] "
                  tabs={[
                    { title: '전체', link: '/customers-last-year/all' },
                    { title: '쿠팡', link: '/customers-last-year/coupang' },
                    { title: '스마트스토어', link: '/customers-last-year/naver' },
                    { title: '카페24', link: '/customers-last-year/cafe24' },
                    /*           { title: '기타', link: '/customers-last-year/etc' }, */
                  ]}
                ></TabComponent>
                <div className='h-10'></div>
                <TableComponent
                  columns={columns}
                  data={data}
                  className="text-sm border-2 rounded-lg"
                  renderCell={renderCell}
                  renderColumn={renderCol}
                />
              </div>
            </div>}
        </div>
      </div>
    </div>
  );
};
export default PastOneYearSegmentation;
