import React from 'react';

const TermsOfUse: React.FC = () => {
    const txt= `1. 목적
                        본 약관은 헤드리스(주) (이하 ‘회사’)가 제공하는 오모밸류(이하 ‘서비스’)의 이용과 관련하여 ‘회사’와 ‘회원’과의 권리, 의무, 책임사항 및 기타 필요한 사항을 규정함을 목적으로 합니다.

                        2. 용어의 정의
                        (1) ‘서비스’라 함은 구현되는 단말기(PC, TV, 휴대형단말기 등의 각종 유무선 장치를 포함)와 상관없이 ‘회사’가 오모밸류를 통해 ‘회원’에게 실시간 커머스 채널 분석(카페24, 스마트스토어, 쿠팡)을 제공하는 일체의 서비스를 의미 합니다.
                        (2) ‘회원’이라 함은 ‘회사’의 ‘서비스’에 접속하여 서비스를 이용하고자 ‘회사’가 요구하는 정보를 기재하고, 본 약관에 동의한 후 ‘서비스’ 가입절차를 완료한 개인이나 법인 또는 법인에 준하는 단체를 의미합니다.
                        (3) ‘아이디(ID)’라 함은 ‘회원’의 식별과 ‘서비스’의 제공을 위하여 ‘회원’이 정하고 ‘회사’가 승인한 문자와 숫자 또는 문자와 숫자의 조합 문자를 의미합니다.
                        (4) ‘연동정보’라 함은 정상적인 ‘서비스’ 이용을 위해 회원이 ‘서비스'에 직접입력 또는 ‘서비스'를 통해 수락한 ‘데이터’ 수집을 위해 설정한 값들(ACCESS KEY/SECRET 등)을 의미합니다.
                        (5) ‘회원 서비스’라 함은 ‘회사’의 ‘서비스’와 연동한 ‘회원’이 관리 및 운영 권한을 가진 카페24, 스마트스토어, 쿠팡, 기타 판매 채널을 의미합니다.
                        (6) ‘데이터’라 함은 ‘회원 서비스’와 ’연동정보’를 통해 수집되는 정보로서 ‘회원 서비스’의 제품 정보, 구매 정보, 구매자 정보를 포함하여 ‘서비스’가 수집, 생성, 가공, 출력 등 처리하는 모든 데이터를 의미합니다.

                        3. 약관의 게시와 개정
                        (1) ‘회사’는 본 약관의 내용을 ‘회원’이 쉽게 알 수 있도록 ‘회사’의 웹사이트(이하 ‘회사의 웹사이트’)내에 게시하거나 제 6조의 방법으로 ‘회원’에게 통지함으로써 효력이 발생합니다.
                        (2) ‘회사’는 필요에 따라 관련법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
                        (3) ‘회사’가 약관을 개정할 경우에는 약관 개정 시행일 및 개정사유를 명시하여 현행약관과 함께 ‘회사의 웹사이트’에 적용예정일 10일 전부터 공지합니다. 다만, ‘회원’에게 불리하거나 중대한 사항을 변경하는 경우에는 개정약관의 적용일자 30일전부터 ‘회사의 웹사이트’에 게시하고 기존의 ‘회원’에게는 제 7조의 방법으로 통지합니다.
                        (4) 본 약관에 동의하는 것은 ‘회원’이 ‘회사’가 공지 또는 통지하는 약관의 변경사항을 확인하는 것에 동의함을 의미하며, ‘회사’는 ‘회사’의 과실없이 변경 약관에 대한 정보를 알지 못하여 발생하는 ‘회원’의 피해에 대해 책임지지 않습니다.
                        (5) ‘회원’은 개정약관의 적용에 동의하지 않는 경우 ‘서비스’의 해지를 신청할 수 있습니다.

                        4. ‘서비스’의 종류
                        ‘회사’는 ‘회사’가 책정한 이용요금을 지불하고 이용하는 유료 ‘서비스’(이하 ‘유료 서비스’)를 제공할 수 있으며, ‘회원’은 ‘서비스’를 선택하여 이용할 수 있습니다.

                        5. ‘서비스’ 이용계약 체결
                        (1) 이용계약은 ‘회원’이 되고자 하는 자(이하 ‘가입신청자’)가 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 ‘회사’가 이러한 신청에 대하여 승낙함으로써 체결됩니다. 다만 ‘회원’이 ‘유료 서비스’를 이용할 경우 이용요금을 결제하여야만 최종적인 계약이 성립되며, ‘회사’는 ‘유료 서비스’의 제공 및 결제를 위해 필요한 증빙 서류의 제공을 ‘회원’에게 요구할 수 있습니다.
                        (2) ‘회사’는 ‘가입신청자’의 신청에 대하여 ‘서비스’ 이용을 승낙함을 원칙으로 합니다.
                        다만, ‘회사’는 다음 각 호에 해당하는 신청에 대하여는 ‘서비스’의 승낙을 하지 않거나 유보할 수 있으며 해당 사유가 승낙이후 발견될 경우 사후에 이용계약을 해지할 수 있습니다.
                        ① 가입신청자가 본 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우
                        단 ‘회사’의 재가입 승낙을 얻은 경우에는 예외로 함.
                        ② 타인 또는 단체의 명의를 도용하거나 거짓정보를 기재한 경우
                        ③ 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우
                        ④ 법령에 위반되는 ‘회원 서비스’에 ‘서비스’를 적용하는 경우
                        ⑤ ‘회사’ 또는 ‘서비스’의 운영상, 사업상 위해 요소 또는 그러한 우려가 있는 경우
                        ⑥ 이용요금의 연체 또는 불량 사용 이력이 있는 경우
                        ⑦ 그 밖에 위 각호에 준하는 사유로서 승낙하는 것이 부적절하다고 판단되는 경우
                        (3) ‘회사’는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
                        (4) 제2항과 제3항에 따라 ‘서비스’ 승낙을 거부 또는 유보하는 경우 ‘회사’는 원칙적으로 이를 ‘서비스’가입 신청자 또는 ‘회원’에게 제 7조의 방법으로 통지합니다
                        (5) 이용계약의 성립 시기는 ‘회사’가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.
                        (6) ‘회사’는 ‘회원’에 대해 서비스 정책에 따라 등급별로 구분하여 서비스 메뉴, 제공기능 등을 세분하여 이용에 차등을 둘 수 있습니다.

                        6. ‘회원’에 대한 통지
                        (1) ‘회사’가 ‘회원’에게 통지를 하는 경우 본 약관에 별도 규정이 없는 한 ‘회원’이 ‘서비스’ 이용신청시 입력한 이메일을 이용합니다..
                        (2) ‘회사’는 ‘회원’ 전체에 대한 통지가 필요한 경우 10일 이상 ‘회사의 웹사이트’등에 게시 함으로서 전항의 통지에 갈음할 수 있습니다.
                        (3) ‘회원’은 ‘회사’의 통지를 수신할 수 있도록 이메일주소, 연락처등 ‘회원’의 정보를 항상 최신으로 유지하여야 합니다. 이를 이행하지않아 ‘회원’에게 발생하는 불이익에 대해서 ‘회사’는 책임지지 않습니다.

                        7. 개인정보보호 의무
                        ‘회사’는 ‘정보통신망법’ 등 관계 법령이 정하는 바에 따라 ‘회원’의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 ‘회사’의 개인정보처리방침이 적용됩니다.
                        단, ‘회사’의 공식 사이트 이외의 링크된 사이트에서는 ‘회사’의 개인정보처리방침이 적용되지 않습니다.

                        8. ‘회원’의 ‘아이디’ 및 비밀번호 관리에 대한 의무
                        (1) ‘회원’의 ‘아이디’와 비밀번호에 관한 관리책임은 ‘회원’에게 있으며, 이를 제3자에게 노출 또는 이용되지 않도록 철저히 관리하여야 합니다.
                        (2) ‘회원’이 전 항의 의무를 위반하여 발생하는 ‘서비스’ 이용상의 손해 또는 제 3자에 의한 부정 사용 등에 의하여 발생하는 모든 결과에 대한 책임은 ‘회원’에게 있으며 ‘회사’는 이에 대한 책임지지 않습니다.
                        (3) ‘회사’는 ‘회원’의 ‘아이디’가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 ‘회사’ 및 ‘서비스’의 운영자로 오인할 우려가 있는 경우, 해당 ‘아이디’의 이용을 제한할 수 있습니다.
                        (4) ‘회원’은 ‘아이디’ 및 ‘비밀번호’가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 ‘회사’에 통지하고 ‘회사’의 안내에 따라야 합니다.
                        (5) 제4항의 경우에 해당 ‘회원’이 ‘회사’에 그 사실을 통지하지 않거나, 통지한 경우에도 ‘회사’의 안내에 따르지 않아 발생한 불이익에 대하여 ‘회사’는 책임지지 않습니다.

                        9. ‘서비스’ 내용 및 변경
                        (1) ‘회사’는 ‘서비스’에 입력한 ‘연동정보’를 이용하여 ‘회원 서비스’에서 이뤄진 이용자의 주문정보 및 제품 데이터, 고객 데이터 등을 분석하여 ‘서비스’의 원활한 사용을 위해 리포트 기능을 제공합니다.
                        (2) ‘회사’는 ‘서비스’의 전부 또는 일부를 변경하거나 ‘서비스’ 내용 및 이용요금 등을 추가하거나 변경할 수 있으며, 그 내용에 대해서는 ‘회사의 웹사이트’에 게시하거나 제 6조의 방법으로 ‘회원’에게 통지합니다.

                        10. ‘데이터’의 수집 및 이용
                        (1) ‘회사’는 ‘서비스’에 입력한 ‘연동정보’를 이용하여 수집한 ‘데이터’를 이용계약 상의 ‘서비스’ 제공 목적 및 ‘회사’의 개인 맞춤형 추천 서비스, 기타 마케팅 활용등 ‘회사’의 사업 목적으로 이용하고 관련 법령의 요건을 갖추어 제3자에게 제공할 수 있습니다.
                        (2) ‘데이터’는 ‘회원’의 별도 요청이 없는 한 제 15조 ‘회원’ 탈퇴 및 ‘서비스’ 해지 처리 완료까지 유효하게 수집되며, ‘회사’의 ‘데이터’ 이용 목적 달성시까지 보유합니다.

                        11. ‘서비스’ 이용 요금 및 결제
                        (1) ‘유료 서비스’의 이용요금은 ‘회원’이 선택한 계약기간과 ‘서비스’ 종류를 기준으로 산정하며, 구체적인 ‘서비스’ 이용요금은 ‘회사가 전달한 견적서’에 표시된 요금표에 따릅니다.
                        (2) ‘회사’는 ‘회원’에게 이용계약에 따른 이용요금을 ‘유료 서비스’ 개시 전에 청구하며, ‘회원’은 청구 받은 이용요금을 ‘회사’가 미리 정한 납기일 이내에 납부하여야 합니다.
                        (3) ‘회사’또는 ‘회원’의 실수로 ‘서비스’이용요금이 과다 또는 과소 청구되었을 경우 이의 신청을 하여 조율을 통한 재정산을 할 수 있습니다. 다만, 그 사유가 발생한 청구일로부터 3개월을 경과한 때에는 이의제기를 할 수 없습니다.
                        (4) ‘서비스’ 이용 기간 중 ‘회원’이 납부한 이용계약 상의 ‘서비스’ 요금표 기준을 초과하거나 부가 기능등을 신청할 시 추가 요금이 발생할 수 있습니다.
                        (5) ‘회원’에게 추가 요금이 발생한 경우 ‘회사’는 ‘회사의 웹사이트’에 게시된 서비스 요금표에 따라 금액을 산정하며, ‘서비스’ 개시일부터 30일 단위로 추가요금액을 산정하여 그 익일에 ‘회원’에게 납기일을 정해 청구합니다.
                        (6) ‘회사’는 ‘회원’의 이용요금 또는 추가요금 결제 방법으로 무통장입금 또는 신용카드 결제 수단을 제공합니다.
                        (7) ‘회사’는 ‘회원’이 납기일까지 추가요금을 납입하지 않을 경우, 납입 불이행의 내용을 ‘회원’에게 제 6조의 방법으로 통보합니다.
                        (8) ‘회원’이 추가 요금을 30일 이상 미납할 경우, 사용하는 ‘유료 서비스’에 대해 이용을 제한하거나 ‘데이터’의 수집 및 분석 또는 리포트 제공을 중지할 수 있습니다. 이에 따른 ‘회원’의 손해에 대해서 ‘회사’는 어떠한 책임도 지지 않습니다.

                        12. ‘서비스’ 제공의 중지
                        (1) ‘회사’는 아래 각 호에 해당하는 경우 ‘서비스’의 일부 혹은 전부의 제공을 중지할 수 있습니다.
                        ① 전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를 중지했을 경우
                        ② ‘서비스’를 위한 설비 보수, 정비, 교체등의 사유가 발생하는 경우
                        ③ 천재지변, 국가비상사태, 정전 등의 불가항력의 사유가 발생하는 경우
                        ④ ‘서비스’ 이용의 폭주 등으로 시스템 설비 장애가 발생하는 경우
                        ⑤ 정기점검, 긴급한 시스템 점검 및 그 외 ‘회사’가 적절하다고 판단하는 경우
                        ⑥ 특정 ‘회원’의 ‘서비스’이용으로 인해 다른 ‘회원’의 ‘서비스’에 영향을 미칠 것으로 판단되는 경우
                        ⑦ 서비스 종료일이 지났을 때
                        ⑧ 그 밖에 위 각호에 준하는 사유로 ‘서비스’ 제공 중지의 조치가 필요한 경우
                        (2) ‘회사’는 ‘서비스’제공을 중지할 경우 ‘회사의 웹사이트’에 게시하거나 제6조에 따른 방법으로 ‘회원’에게 통지합니다. 다만, ‘회사’가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
                        (3) ‘회사’는 제1항에 따른 ‘서비스’ 제공 중단으로 발생한 ‘회원’의 손해에 대하여 고의 또는 과실이 없는 한 책임을 지지 않습니다.

                        13. 회사의 책임 및 의무
                        (1) ‘회사’는 관련법과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 지속적이고 안정적으로 ‘서비스’를 제공하기 위하여 최선을 다하여 노력합니다.
                        (2) ‘회사’는 ‘회원’의 개인정보 보호를 위한 보안 시스템을 구축하고 개인정보 취급방침을 공시하고 준수합니다.
                        (3) ‘회사’는 ‘회원’이 ‘서비스’를 정상적으로 활용할 수 있도록 지속적인 ‘서비스’운영지원을 해야합니다.

                        14. 회원의 책임 및 의무
                        (1) ‘회원’는 관련 법령, ‘약관’, ‘서비스’운영정책, 이용안내 및 ‘회사’가 공지하거나 통지한 사항 등을 준수하여야 하며, 기타 ‘회사’의 업무에 방해되는 행위를 하여서는 안 됩니다.
                        (2) ‘회원’은 다음과 같은 책임과 의무가 있습니다.
                        ① ‘회원’신청 또는 변경시 모든 내용을 사실에 근거하여 작성해야합니다.
                        ② 타인의 정보도용을 해서는 안됩니다.
                        ③ ‘회원’ 정보가 변경되면 즉시 갱신해야하며, 갱신지연으로 발생한 문제에 대해서 ‘회사’는 책임지지 않습니다.
                        ④ ‘회원’은 정상적인 ‘서비스’이용을 위해 ‘서비스’에 입력한 ‘연동정보’에 대한 업데이트 관리 책임이 있습니다.
                        ⑤ ‘회원’은 ‘회사’가 청구하는 ‘서비스’ 이용 요금에 대한 지급의 책임이 있습니다.

                        15. ‘서비스’ 해지
                        (1) ‘회원’은 언제든지 ‘서비스’ 이용 계약을 해지할 수 있으며, ‘서비스’를 해지하고자 할 경우 ‘회사의 웹사이트’에 공지한 절차와 방식에 따라 ‘회사’에 요청할 수 있습니다.
                        (2) 특별한 사정이 없는 한 ‘회원’이 요청한 ‘서비스’ 해지 일정에 따라 ‘서비스’이용계약을 종료합니다.
                        (3) ‘회원’이 ‘유료 서비스’이용 계약을 해지할 경우, 이미 경과된 계약기간에 대한 이용요금은 환불되지 않으며, 남은 ‘유료 서비스’계약기간의 이용요금에 대하여 환불합니다.
                        (4) ‘회사’는 남은 ‘유료서비스’계약기간에 해당하는 이용요금을 일할 계산하여 정산하는데, 이용요금에 적용된 할인율(결제기간할인을 포함한 모든 할인율) 및 위약금(총 이용요금의 10%)을 공제한 금액을 ‘회원’에게 환불합니다.
                        (5) ‘회사’는 ‘회원’으로부터 제공받은 ‘연동정보'를 ‘회원 서비스’에서 삭제하여야 합니다.
                        (6) ‘서비스’해지 후에도 제11조의 책임과 의무는 존속됩니다.

                        16. ‘회사’의 ‘서비스’ 이용제한 및 이용계약 해지
                        (1) ‘회사’는 ‘회원’의 ‘서비스’이용 내용이 다음 각호의 어느 하나에 해당하는 경우 ‘서비스’의 전부 또는 일부에 대해 이용을 제한하거나, 이용계약을 해지할 수 있습니다.
                        ① ‘서비스’ ‘이용요금’을 미납하는 경우
                        ② ‘이용신청’양식에 기재한 내용이 허위인 경우
                        ③ ‘회원 서비스’가 국내법에 위반되어 회사에 피해가 예상되는 경우
                        ④ ‘회원 서비스’를 목표로 네트워크 공격으로 인하여 ‘서비스’를 공유하는 다른 ‘회원 서비스’ 운영에 피해가 발생하거나 발생할 우려가 있는 경우
                        ⑤ 본 약관을 위반한 경우
                        ⑥ 기타 회사가 필요하다고 판단되는 경우
                        (2) ‘회사’가 본 조에 따라 ‘회원’에게 ‘서비스’이용을 제한하거나 이용계약을 해지하는 경우, ‘회사’는 사전 통보없이 ‘회원’에 대한 ‘서비스’를 중지할 수 있습니다. 이 경우 ‘회원’은 이로 인한 손해배상을 ‘회사’에 청구할 수 없습니다.

                        17. 손해 배상
                        (1) ‘회사’는 본 약관 및 ‘회사의 웹사이트’상의 내용을 위반하거나 이행하지 아니하여 발생하는 모든 손실 및 손해에 대해 책임을 부담합니다.
                        (2) ‘회사’의 책임 있는 사유로 장애가 발생하여 ‘회원’이 손해를 입은 경우, ‘회사’는 ‘회원’의 ‘유료 서비스’를 장애시간의 5배에 해당하는 시간(이하 ‘무상 보상 시간’)만큼 무상 제공하는 방법으로 ‘회원’의 손해를 배상합니다. 단, ‘무상 보상 시간’은 장애가 발생한 해당 ‘유료 서비스’의 이용 계약기간을 초과할 수 없습니다.
                        (3) ‘무상 보상 시간’은 ‘회원’의 ‘유료 서비스’ 이용 계약기간이 종료된 날의 익일에 개시됩니다.
                        (4) 본 조는 ‘회사’의 ‘유료 서비스’를 이용하는 ‘회원’에 한하여 적용되며, ‘무료 서비스’를 이용하는 경우 ‘회사’는 손해배상책임을 지지 않습니다.

                        18. ‘회사’의 면책
                        (1) ‘회사’는 다음 각 호의 사유로 인하여 발생한 ‘회원’의 손해에 대해서는 그 책임을 지지 않습니다.
                        ① 천재지변, 전쟁, IDC네트워크 장애, 클라우드서비스 장애 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우
                        ② ‘회원’의 귀책사유로 인한 경우
                        ③ 전기통신서비스의 특성상 ‘서비스’시설의 보수, 교체, 점검, 공사 등 부득이한 사유인 경우
                        ④ 외부의 불법적인 침입을 원인으로 ‘서비스’에 장애가 발생하는 경우
                        ⑤ ‘회원’이 ‘연동정보’를 임의로 수정하여 설치한 경우
                        (2) ‘회사’는 ‘회원’이 ‘서비스’를 이용하여 기대하는 수익을 상실한 것에 대한 책임을 지지 않으며, 그 밖에 ‘서비스’를 통해 얻은 자료로 인한 손해 등에 대하여도 책임을 지지 않습니다.

                        19. 준거법 및 재판관할
                        (1) 본 약관은 대한민국 법률에 따라 규율되고 해석됩니다.
                        (2) ‘회사’와 ‘회원’ 간 발생한 분쟁에 관한 소송은 ‘회사’의 본사 소재지 법원을 관할 법원으로 합니다.

                        부칙
                        (시행일)
                        이 약관은 2024년 7월 1일부터 적용됩니다.

`
    return (
        <div className="pt-32 flex justify-center item-center">
            <div>
                <img  className='h-8' src="logo-b.png" ></img>
                <h2 className="text-xl">서비스 이용약관</h2>
                <div className='h-8'></div>
                <div className="bg-white overflow-y-scroll text-sm border-[1px] w-[661px] h-[581px] overflow-hi p-6 rounded-lg ">
                    <div style={{whiteSpace:'pre-line'}}>
                        {txt}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsOfUse;
