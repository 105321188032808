
import React, { useState,useEffect  } from "react";

interface PopUpBaseProps {
  children: React.ReactNode;
}

const ModalBase: React.FC<PopUpBaseProps> = ({ children }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
   
    const originalStyle = window.getComputedStyle(document.body).overflow;  
    document.body.style.overflow = 'hidden';


    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []); 

  return (
    <div
      className="fixed inset-0 bg-zinc-700 bg-opacity-75 z-50 flex justify-center items-center"
    >
      <div ></div>
      {children}
    </div>
  );
};

export default ModalBase;