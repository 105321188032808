import React, { useEffect, useState } from 'react';
import TabComponent from '../common/TabComponent';
import TableComponent, { TableData, TableColumn } from './TableComponent';
import TittleBar from '../TittleBar';
import Pagination from 'react-js-pagination';
import CBCVTab from '../../CBCVTab';
import useRESTAPI from '../Contexts/RESTAPI';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../Contexts/AuthContext';
import { formatNumberWithCommas } from '../common/Auxiliary';
import NoReport from '../common/Norepot';

export const HighRepurchaseProducts = (): JSX.Element => {
  const navi = useNavigate()
  const { tab } = useParams();
  useEffect(() => {
    if (!tab) {
      const searchParams = new URLSearchParams(location.search);
      const searchString = searchParams.toString();
      navi(`/high-repurchase-products/all${searchString ? `?${searchString}` : ''}`);
    }
  }, [tab]);
  const { user_id ,admin_id} = useAuth();

  const {
    items: requests,
    fetchItems: fetchRequests,
  } = useRESTAPI('/api/cbcv-request');

  const [isRecv, setRecv] = useState<boolean>(false);
  useEffect(() => {

  }, [isRecv]);


  useEffect(()  => {
    const _ = async () => {
      if (user_id === undefined) 
        return;
         const data = await fetchRequests({ cbcv_user_id: user_id, status: 5 });
         console.log();
        if(!data || data.length ===0)
          setRecv(true);
    }
    _();

  }, [user_id, tab]);

  const [lastRequest, setLastRequests] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    setLastRequests(requests[requests.length - 1]);
  }, [requests]);

  const [reportData, setReportData] = useState<any>();
  const location = useLocation();

  useEffect(() => {
    if (admin_id === undefined)
      return;
    const _ = async () => {
      const id = new URLSearchParams(location.search).get('request_id');
      if(id){
        const req = await fetchRequests({request_id:id})
        console.log(req);
        setLastRequests(req[0]);
      }
    }
    _();
    
  }, [admin_id,tab]);
  
  
  useEffect(() => {
    if (lastRequest === undefined)
      return;
    const url = '/api/cbcv-api/product_repurchase_top100/'
    const _ = async () => {

      const data = {
        user_id: lastRequest.cbcv_user_id.id,
        request_id: lastRequest.request_id,
        channel_param: tab,
      }

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      const responseData = await response.json();


      if(responseData.message === 'there is no report available')
        {
          alert('데이터가 존재하지 않습니다')
          navi(-1)
        }
        else
        {
          setReportData(responseData);
        }
          setRecv(true);
    };
    _();
  }, [lastRequest]);



  const columns: TableColumn[] = [
    { key: 'rank', label: '순위', width: 50 },
    { key: 'product_name', label: '상품명', width: 650 },
    { key: 'order_total', label: '총 주문 금액', width: 130 },
    { key: 'customer_total', label: '총 구매자 수', width: 100 },
    { key: 'repurchase_total', label: '재구매자 수', width: 100 },
    { key: 'repurchase_rate', label: '재구매율', width: 100 },
  ];



  const renderCol = (column: TableColumn) => {
    const needLine = column.key !== columns[0].key;

    const aa =
      column.key === columns[columns.length - 1].key
        ? 'border-gray-300    font-bold'
        : '';
    return (
      <div
        className={`bg-gray-200 ${aa} border-b-2 flex items-center justify-start w-full  h-[48px]`}
      >
        {needLine ? <div className="bg-gray-400 h-2/4 w-[1px]"></div> : <></>}
        <div className="flex w-full justify-center">{column.label}</div>
      </div>
    );
  };

  const renderCell = (
    rowindex: number,
    rowData: TableData,
    column: TableColumn,
  ) => {
    let leftBorder =
      columns[0].key !== column.key &&
        column.key !== columns[columns.length - 1].key
        ? ' border-l-2'
        : 'font-bold';

    let border =
      rowindex + 1 === data.length ? leftBorder : leftBorder + ' border-b-2 ';

    const bg =
      column.key !== columns[columns.length - 1].key
        ? ' bg-white'
        : ' border-gray-300 bg-gray-200';
    console.log(rowData);

    let text = rowData[column.key]
    if (column.key === 'repurchase_rate') {
      text = `${(Number.parseFloat(rowData[column.key]) * 100).batterToFix(0)}%`
    }
    if (column.key === 'repurchase_total' || column.key === 'customer_total') {
      text = formatNumberWithCommas(rowData[column.key]);
    }
    if ( column.key === 'order_total') {
      text = '₩ ' + formatNumberWithCommas(rowData[column.key]);
    }

    if(column.key==='product_name')
    {
      return (
        <div
          className={`${border} ${bg} flex  items-center justify-start w-full  h-[39px]`}
        >
          {<div className={' h-full w-[1px]'}></div>}
          <div className="w-full truncate flex justify-start pl-5"> {text}</div>
        </div>
      );
    }


    return (
      <div
        className={`${border} ${bg} flex  items-center justify-start w-full  h-[39px]`}
      >
        {<div className={' h-full w-[1px]'}></div>}
        <div className="w-full flex justify-end pr-5"> {text}</div>
      </div>
    );
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };


  if (!reportData) {
    return (
      <div className="flex  text-[#474A4E]">
        <CBCVTab></CBCVTab>
        <div className="BrandAnalysis w-full  min-w-[1200px]">
        <TittleBar tittle="상품 분석" />
          
          {isRecv ? <NoReport></NoReport> :  <div className='flex flex-col gap-11 h-full w-full justify-center items-center'/>}
        </div>
      </div>
    );
  }
  const data = reportData.data.repurchase_top100_list;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = data.slice(indexOfFirstItem, indexOfLastItem);
  return (
    <div className="flex text-[#474A4E] ">
      <CBCVTab></CBCVTab>
      <div className="min-w-[1200px] w-full justify-center">
        <TittleBar tittle="상품 분석" />
        {reportData && <div className='flex justify-center item-center'>
          <div className="w-[1161px] pt-[34px] ">
            <div className="font-bold text-[26px] text-[#474a4e]">
              재구매율 높은 상품 TOP 100
            </div>
            <div className="h-[86px]"></div>
            <TabComponent
              mainClassName="flex text-[#7B828B] gap-5"
              activeClassName="h-10 px-4 text-[#7B828B] "
              itemClassName="h-10 font-bold px-4 text-[#4046E3] border-b-4 border-[#4046E3] "
              tabs={[
                { title: '전체', link: '/high-repurchase-products/all' },
                { title: '쿠팡', link: '/high-repurchase-products/coupang' },
                {
                  title: '스마트스토어',
                  link: '/high-repurchase-products/naver',
                },
                { title: '카페24', link: '/high-repurchase-products/cafe24' },
         /*        { title: '기타', link: '/high-repurchase-products/etc' }, */
              ]}
            ></TabComponent>
            <div className="h-[40px]"></div>
            <TableComponent
              columns={columns}
              data={currentData}
              className="text-sm border-2 rounded-lg"
              renderCell={renderCell}
              renderColumn={renderCol}
            />
            <Pagination
              totalItemsCount={data.length}
              itemsCountPerPage={itemsPerPage}
              activePage={currentPage}
              onChange={handlePageChange}
            ></Pagination>
          </div>
        </div>}</div>
    </div>
  );
};
export default HighRepurchaseProducts;
