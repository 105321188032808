import styled from "styled-components";
import {MainSection} from "./section.js";
import {DEFAULT_FONT_SIZE, BREAKPOINT, COLOR_ACCENT} from "./vars.js";

export const MainCtaSection = styled(MainSection)`
        padding: ${230/DEFAULT_FONT_SIZE}em 0 ${180/DEFAULT_FONT_SIZE}em;
        text-align: center;
        color: #fff;

        background: linear-gradient(90deg, #5368DE 0%, #A44680 100%);
        overflow: hidden;
        @media screen and (max-width: ${BREAKPOINT.MOBILE}px) {
            padding: ${100/DEFAULT_FONT_SIZE}em 0;
        }
        .__bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            svg {
                display: block;
                height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                overflow: visible;
                transform: translate(-51%, -50%);
                @media screen and (max-width: ${BREAKPOINT.MOBILE}px) {
                    height: 55%;
                }
            }

        }
        .__cta {
            margin-top: ${48/DEFAULT_FONT_SIZE}em;
            display: flex;
            justify-content: center;
        }
    
`;