/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const AccountIcon1 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`account-icon-1 ${className}`}
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_3_28410)">
        <mask className="mask" height="18" id="mask0_3_28410" maskUnits="userSpaceOnUse" width="18" x="0" y="0">
          <path className="path" d="M18 0H0V18H18V0Z" fill="white" />
        </mask>
        <g className="g" mask="url(#mask0_3_28410)">
          <path
            className="path"
            d="M9 7.5C10.6569 7.5 12 6.15685 12 4.5C12 2.84315 10.6569 1.5 9 1.5C7.34315 1.5 6 2.84315 6 4.5C6 6.15685 7.34315 7.5 9 7.5Z"
            stroke="#BCC2C9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            className="path"
            d="M16.1 16.5H1.9C1.79391 16.5 1.69217 16.4579 1.61716 16.3828C1.54214 16.3078 1.5 16.2061 1.5 16.1V14.5C1.5 13.4391 1.92143 12.4217 2.67157 11.6716C3.42172 10.9214 4.43913 10.5 5.5 10.5H12.5C13.5609 10.5 14.5783 10.9214 15.3284 11.6716C16.0786 12.4217 16.5 13.4391 16.5 14.5V16.1C16.5 16.2061 16.4579 16.3078 16.3828 16.3828C16.3078 16.4579 16.2061 16.5 16.1 16.5Z"
            stroke="#BCC2C9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
      <defs className="defs">
        <clipPath className="clipPath" id="clip0_3_28410">
          <rect className="rect" fill="white" height="18" width="18" />
        </clipPath>
      </defs>
    </svg>
  );
};
