import React ,{useState}from 'react';
import { Button } from '../Button';
import './FileUploadModal.css';
import ModalBase from '../common/ModalBase';
import { Link } from 'react-router-dom';
import FileUploadButton from '../common/FileUploadButton';
import { useAuth } from '../Contexts/AuthContext';
import useRESTAPI from '../Contexts/RESTAPI';
interface CURegiModalProps {
  onClose: () => void;
}

export const FileUploadModal = ({ onClose }: CURegiModalProps): JSX.Element => {
  
  const [file, setFile] = useState<File>();
  const {user_id} = useAuth() 
  const {fetchItems,removeItem} = useRESTAPI('/api/extraFile')
  const handleClose = () => {
    onClose();
  };

  const onFileSelect  = (file: File) => {
    setFile(file)

  };

  const handleSubmit = async  ()  => {
    ('handleSubmit');
    const formData = new FormData();

    try {
      const data = await fetchItems({cbcv_user_id:user_id})
      const id : number = data[0].id;
      removeItem(id);
    } catch (error) {

    }

    if(file)
      formData.append('extraFile', file);
    if(user_id)
      formData.append('cbcv_user_id',user_id.toString())
    try {
      const response = await fetch('/api/extraFile', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
      
      } else {
        console.error('Failed to upload file');
      }
      handleClose();
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <ModalBase>
      <div className="flex  rounded-lg flex-col justify-between FileUploadModal w-[664px] h-[400px] bg-white">
        <div className="p-[15px] div rounded-t-md">
          <div className="p-2">
            <div className="text-wrapper-14">주문 데이터 파일 업로드</div>
            <div className="h-4"></div>
            <hr></hr>
            <div className="h-4"></div>
            <div className="div2">
              <div className="">파일 업로드하기</div>
              <div className="flex gap-2">
                <div className="w-full font-normal bg-white flex items-center px-6 py-3 rounded border border-gray-300 text-center full text-gray-500">
                  {file?.name || '파일을 업로드 해주세요'}
                </div>
                <FileUploadButton accept='.xlsx' onFileSelect={onFileSelect}>
                <div className="text-[#474A4E] w-[149px] h-[40px] border-[1px] border-gray-300 rounded-lg bg-white flex items-center justify-center ">
                  파일 첨부
                </div></FileUploadButton>
              </div>
              <div className="h-4"></div>
            </div>
            <div className="p-6">
              <div className="text-wrapper-16">주문 데이터 파일 업로드</div>
              <div className="h-1"> </div>
              <div className="w-[493px]">
                <div className="font-medium text-[#474a4e] text-[12px] tracking-[0] leading-[normal]">
                  추가적으로 분석할 주문데이터 파일을 다운받아 작성 후 업로드해
                  주세요
                </div>
              </div>
              <div className="h-2"></div>
              <a
                href="https://docs.google.com/spreadsheets/d/1h1tbeNh-itVDcy9G0Gov8WUgklP75kel/edit?usp=sharing&ouid=111933547079138714568&rtpof=true&sd=true"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="font-medium text-[#424242] text-[12px] underline whitespace-nowrap">
                  템플릿 다운로드
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="w-full rounded-b-md flex justify-end p-2 gap-2 bg-slate-200">
          <button
            onClick={handleClose}
            className="bg-white border-gray-300 border-2 rounded-md w-[91px] h-[34px]  flex items-center justify-center"
          >
            취소
          </button>
          <button
            onClick={handleSubmit}
            className={`${file ? ' bg-[#4046E3]' : 'bg-[#BCC2C9]'}  h-[34px] w-[96px] rounded-md text-white flex items-center justify-center`}
          >
            연결하기
          </button>
        </div>
      </div>
    </ModalBase>
  );
};
export default FileUploadModal;
