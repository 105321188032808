
import MainKV from "./section/KV.jsx";
import MainPartners from "./section/Partners.jsx";
import MainSummary from "./section/Summary.jsx";
import MainFeature1 from "./section/Feature1.jsx";
import MainClv from "./section/Clv.jsx";
import MainFeature2 from "./section/Feature2.jsx";
import MainCta from "./section/Cta.jsx";
import MainContact from "./section/Contact.jsx";
import {MainSectionsWrapper} from "./styles/common.js";
import {gsap, Quart} from "gsap";
import {useGSAP} from "@gsap/react";
import ScrollTrigger from "gsap/ScrollTrigger";
import {useContext, useRef} from "react";
import {LangContext} from "./Contexts/LangContext.jsx";

export default function MainSections() {

  gsap.registerPlugin(useGSAP, ScrollTrigger);
  const container = useRef();
  const {lang} = useContext(LangContext);
  useGSAP(
      () => {
        document.querySelectorAll('[data-anims]').forEach( anims => {
          anims.querySelectorAll('[data-anim]').forEach((anim) => {
            gsap.killTweensOf(anim);
            const isImg = anim.dataset.anim === 'img';
            if (isImg) {
              gsap.set(anim, {autoAlpha: 0, y:'16rem'});
            } else {
              gsap.set(anim, {autoAlpha: 0, y:'4rem'});
            }
          });
          ScrollTrigger.create({
            trigger: anims,
            start: 'top 80%',
            onEnter: () => {
              gsap.to(anims.querySelectorAll('[data-anim]'), {
                autoAlpha: 1,
                y: 0,
                stagger: 0.2,
                duration: 2,
                ease: Quart.easeOut
              });
            }
          });
        })
      },
      { scope: container, dependencies: [lang] }
  );

  return (
      <MainSectionsWrapper ref={container}>
        <MainKV />
        <MainPartners />
        <MainSummary />
        <MainFeature1 />
        <MainClv />
        <MainFeature2 />
        <MainCta />
        <MainContact />
      </MainSectionsWrapper>
  )
}
