import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from 'react';

interface ModalContextProps {
  showModal: (content: ReactNode) => void;
  hideModal: () => void;
  modalContent: ReactNode | null;
  isVisible: boolean;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [modalContent, setModalContent] = useState<ReactNode | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  const showModal = useCallback((content: ReactNode) => {
    setModalContent(content);
    setIsVisible(true);
  }, []);

  const hideModal = useCallback(() => {
    setIsVisible(false);
    setModalContent(null);
  }, []);

  return (
    <ModalContext.Provider
      value={{ showModal, hideModal, modalContent, isVisible }}
    >
      {children}
      {isVisible && (
        <div className="fixed pointer-events-none inset-0 z-50 flex items-center justify-center">
          <div className="pointer-events-auto">{modalContent}</div>
        </div>
      )}
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextProps => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
