
import {MainSectionContainer, MainSectionHeading, MainSectionInner} from "../styles/section.js";
import {MainSummaryDesc, MainSummarySection} from "../styles/summary.js";
import {useContext} from "react";
import {LangContext} from "../Contexts/LangContext.jsx";

export default function MainSummary() {
    const {t} = useContext(LangContext);
    return (
        <MainSummarySection>
            <MainSectionInner>
                <MainSectionContainer>
                    <MainSectionHeading data-anims>
                        <h2 className="__title __size-xl" data-anim dangerouslySetInnerHTML={{__html:t('summaryTitle')}}></h2>
                    </MainSectionHeading>
                    <MainSummaryDesc data-anims>
                        <div className="__title" data-anim>
                            <h3>{t('summaryDescTitle')}</h3>
                        </div>
                        <div className="__desc">
                            <ul>
                                <li data-anim>{t('summaryDesc1')}</li>
                                <li data-anim>{t('summaryDesc2')}</li>
                                <li data-anim>{t('summaryDesc3')}</li>
                            </ul>
                        </div>
                    </MainSummaryDesc>
                </MainSectionContainer>
            </MainSectionInner>
        </MainSummarySection>
    )
}
