import React from 'react';
import { Line } from 'react-chartjs-2';
import { Link } from 'react-router-dom';

interface NoreportProps {
    message?: string;
}

const NoReport: React.FC<NoreportProps> = ({ message = '현재 발행된 분석 레포트가 없습니다.' }) => {
    return (
        <div className='flex flex-col gap-11 h-full w-full justify-center items-center'>

            <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_1466_532" maskUnits="userSpaceOnUse" x="0" y="0" width="61" height="60">
                    <path d="M60.5 0H0.5V60H60.5V0Z" fill="white" />
                </mask>
                <g mask="url(#mask0_1466_532)">
                    <path d="M20.4999 45.89C15.9253 45.3819 11.7191 43.1407 8.74592 39.627C5.77278 36.1133 4.25863 31.5941 4.51472 26.9985C4.77082 22.4028 6.7777 18.0799 10.1228 14.9183C13.468 11.7567 17.8972 9.99665 22.4999 10V28H31.4999" stroke="#4046E3" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M46.5 22C46.5 17.2261 44.6036 12.6477 41.2279 9.27208C37.8523 5.89642 33.2739 4 28.5 4V22H46.5Z" stroke="#4046E3" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M39.357 26L22.214 56H56.5L39.357 26Z" stroke="#4046E3" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M38.1559 49.7191C38.2986 49.4715 38.506 49.2676 38.7559 49.1291C39.0096 48.9866 39.296 48.9125 39.5869 48.9141C39.868 48.9134 40.1441 48.9876 40.3869 49.1291C40.6347 49.2703 40.8415 49.4737 40.9869 49.7191C41.1343 49.9674 41.212 50.2508 41.212 50.5396C41.212 50.8283 41.1343 51.1117 40.9869 51.3601C40.8413 51.6079 40.6348 51.8144 40.3869 51.9601C40.145 52.1041 39.8685 52.1798 39.5869 52.1791C39.2955 52.1807 39.0088 52.1051 38.7559 51.9601C38.5059 51.8173 38.2987 51.6101 38.1559 51.3601C38.0174 51.1088 37.9448 50.8265 37.9448 50.5396C37.9448 50.2526 38.0174 49.9704 38.1559 49.7191ZM40.8159 47.7621H38.3159L38.0619 37.8621H41.0699L40.8159 47.7621Z" fill="#4046E3" />
                </g>
            </svg>
            <div className='flex flex-col justify-center'>
                <div className=' text-[20px] leading-[32px] font-bold text-[#474A4E] tracking-[-0.017em] flex-none order-0 flex-grow-0 text-center'>
                    현재 발행된 분석 레포트가 없습니다.
                </div>
                <div className='font-medium text-base leading-[22px] text-center tracking-[-0.017em] text-gray-500 order-1 flex-none'> 데이터 연동과 분석 요청이 필요합니다.</div>
              
            </div>
            <Link to={'/connect-data'}>
            <button className="flex text-white flex-row justify-center items-center px-6 py-2.5 gap-1 w-34 h-10 bg-blue-600 rounded-md order-2 flex-none">
                    데이터 연동하기
                </button></Link>
        </div>
    );
};



export default NoReport;
