import {MainClvSection} from "../styles/clv.js";
import {MainSectionInner} from "../styles/section.js";
import {MainKVSectionContainer} from "../styles/kv.js";
import ImgDiagram3 from "../imgs/diagram3.png";
import ImgDiagram3En from "../imgs/diagram3-en.png";
import {useContext} from "react";
import {LangContext} from "../Contexts/LangContext.jsx";
import ImgDiagram1En from "../imgs/diagram1-en.png";
import ImgDiagram1 from "../imgs/diagram1.png";
export default function MainClv() {
    const {t, lang} = useContext(LangContext);
    return (
        <MainClvSection>
            <MainSectionInner>
                <MainKVSectionContainer>
                    <div className="__grid" data-anims>

                        <div className="__desc">
                            <p className="__size-xl" data-anim dangerouslySetInnerHTML={{__html:t('clvTitle')}}></p>
                        </div>
                        <div className="__img">
                            <img src={lang === 'en' ? ImgDiagram3En : ImgDiagram3} alt="" data-anim="img" style={{aspectRatio:'1072/1072'}}/>
                        </div>
                    </div>
                </MainKVSectionContainer>
            </MainSectionInner>
        </MainClvSection>
    )
}