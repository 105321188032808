import React, { ChangeEvent, useEffect, useState } from 'react';
import TableComponent, { TableColumn, TableData } from './TableComponent';
import Dropdown from './DropDown';
import useRESTAPI from '../Contexts/RESTAPI';
import { useAuth } from '../Contexts/AuthContext';
import Button from '../Button';
import TittleBar from '../TittleBar';
import { useNavigate } from 'react-router-dom';
import CBCVTab from '../../CBCVTab';
import { extractNumbersAsSingleNumber, formatDateTime, formatNumberWithCommas } from '../common/Auxiliary';
import BackButton from '../common/BackButton';

interface CBCVCostData {
  id?: number;
  cbcv_user_id: number;
  accounting_year: string;
  revenue: number;
  creation_datetime: Date,
  cost_of_revenue: number;
  sga_expense: number;
}
/* 
CREATE TABLE `accounting_data` (
  `id` INT(10) UNSIGNED NOT NULL AUTO_INCREMENT,
  `cbcv_user_id` INT(10) NULL DEFAULT NULL,
  `request_id` INT(10) UNSIGNED NULL DEFAULT NULL,
  `accounting_year` VARCHAR(8) NULL DEFAULT NULL COLLATE 'utf8mb4_0900_ai_ci',
  `revenue` INT(10) NULL DEFAULT NULL,
  `cost_of_revenue` INT(10) NULL DEFAULT NULL,
  `sga_expense` INT(10) NULL DEFAULT NULL,
  `creation_datetime` TIMESTAMP NULL DEFAULT NULL,
  PRIMARY KEY (`id`) USING BTREE
)
COLLATE='utf8mb4_0900_ai_ci'
ENGINE=InnoDB
;

 */
///api/cbcv-api
const CostDataPage: React.FC = () => {
  const navi = useNavigate();

  const { items, fetchItems, addItem, removeItem } =

    useRESTAPI('/api/analysis/accountingdata');

  const [costData, setCostData] = useState<any>([]);
  const { user_id } = useAuth();

  const [showTable, setShowTable] = useState<boolean>(true);
  useEffect(() => {
    fetchItems({ cbcv_user_id: user_id, pageSize: 9999 });
  }, [user_id]);



  useEffect(() => {
   
  }, [costData]); 

  useEffect(() => {

    if (items.length !== 0) {
      setCostData(items);
    }
    else {
      handleDropdown(new Date().getFullYear().toString())
    }

  }, [items]);

  function getYearsList(startYear: number): number[] {
    const currentYear = new Date().getFullYear() - 1;
    const yearsList: number[] = [];

    for (let year = startYear; year <= currentYear; year++) {
      yearsList.push(year);
    }

    return yearsList.reverse();
  }
  const generateYearOptions = (): { key: string; label: string }[] => {
    const currentYear = new Date().getFullYear();
    const years: { key: string; label: string }[] = [];

    for (let i = 0; i < 20; i++) {
      const year = currentYear - i;

      years.push({ key: year.toString(), label: year.toString() });
    }

    return years;
  };
  const postDate = async () => {
    setShowTable(false);
    for (const item of items) {
      if (item.id) {
        await removeItem(item.id);
        console.log('removed: ' + item.id);
      }
    }

    for (const item of costData) {
      delete item.id;
      await addItem(item);
      console.log('added');
    }
    setShowTable(true);
    fetchItems({ cbcv_user_id: user_id, pageSize: 9999 });
    navi('/connect-data');
  };
  const handleInputChange = (
    rowIndex: number,
    columnKey: string,
    value: any,
  ) => {
    const updatedCostData = costData.map((row: any, index: number) => {
      if (index === rowIndex) {
        return {
          ...row,
          [columnKey]: extractNumbersAsSingleNumber(value),
        };
      }
      return row;
    });
    setCostData(updatedCostData);
  };/* 
  CREATE TABLE `accounting_data` (
    `id` INT(10) UNSIGNED NOT NULL AUTO_INCREMENT,
    `cbcv_user_id` INT(10) NULL DEFAULT NULL,
    `request_id` INT(10) UNSIGNED NULL DEFAULT NULL,
    `accounting_year` VARCHAR(8) NULL DEFAULT NULL COLLATE 'utf8mb4_0900_ai_ci',
    `revenue` INT(10) NULL DEFAULT NULL,
    `cost_of_revenue` INT(10) NULL DEFAULT NULL,
    `sga_expense` INT(10) NULL DEFAULT NULL,
    `creation_datetime` TIMESTAMP NULL DEFAULT NULL,
    PRIMARY KEY (`id`) USING BTREE
  )
  COLLATE='utf8mb4_0900_ai_ci'
  ENGINE=InnoDB
  ;
 */
  const columns: TableColumn[] = [
    { key: 'accounting_year', label: '기준 연도', width: 1 },
    { key: 'revenue', label: '매출', width: 1 },
    { key: 'cost_of_revenue', label: '매출 원가', width: 1 },
    { key: 'sga_expense', label: '판매비와 관리비', width: 1 },
  ];


  const renderCol = (column: TableColumn) => {
    const needLine = column.key !== columns[0].key;

    return (
      <div
        className={`${!needLine ? 'border-b-[1px] border-b-[#BCC2C9]' : ''} bg-[#EAECEF] font-bold flex items-center justify-start w-full h-[48px]`}
      >
        {needLine ? <div className="bg-[#BCC2C9] h-3/5 w-[1px]"></div> : <></>}
        <div className="flex w-full justify-center">{column.label}</div>
      </div>
    );
  };

  const renderCell = (
    rowindex: number,
    rowData: TableData,
    column: TableColumn,
  ) => {
    if (column.key === 'accounting_year' && rowindex < 4) {
      return (
        <div
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInputChange(rowindex, column.key, e.target.value)
          }
          className="bg-[#EAECEF] flex justify-between w-full h-[33px]  border-b-[1px] border-[#BCC2C9]"
        >
          <div className="w-1/2 pl-4 flex  items-center">
            {rowData[column.key].split('-')[0]}
          </div>
          <div className="w-1/2 flex  items-center">
            <div className="bg-[#BCC2C9] h-3/5 w-[1px]" />
            <div className="w-full flex justify-end pr-5"> {rowData[column.key].split('-')[1]}분기</div>
            
          </div>
        </div>
      );
    } else if (column.key === 'accounting_year') {
      return (
        <div
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInputChange(rowindex, column.key, e.target.value)
          }
          className="pl-4 bg-[#EAECEF] flex justify-between  items-center w-full h-[33px]  border-b-[1px] border-[#BCC2C9]"
        >
          {rowData[column.key]}
        </div>
      );
    } else if (column.key !== 'accounting_year') {
      return (
        <input
          value={formatNumberWithCommas( extractNumbersAsSingleNumber(rowData[column.key]))}
          onChange={(e) =>
            handleInputChange(rowindex, column.key, e.target.value)
          }
          className="pl-4 w-full h-[33px] border-r-[1px]  border-b-[1px] border-[#EAECEF]"
        />
      );
    }
    /* 
  
   { key: 'year', label: '기준 연도', width: 1 },
    { key: 'revenue', label: '매출', width: 1 },
    { key: 'cost_of_revenue', label: '매출 원가', width: 1 },
    { key: 'sga', label: '판매비와 관리비', width: 1 },
      let leftBorder =
      columns[0].key !== column.key &&
      column.key !== columns[columns.length - 1].key
        ? ' border-l-2'
        : 'font-bold';

    let border =
      rowindex + 1 === data.length ? leftBorder : leftBorder + ' border-b-2 ';

    const bg =
      column.key !== columns[columns.length - 1].key
        ? ' bg-white'
        : ' border-gray-300 bg-gray-200';

    return (
      <div
        className={`${border} ${bg} flex  items-center justify- w-full  h-[39px]`}
      >
        <input
          type="number"
          value={rowData[column.key]}
          onChange={(e) =>
            handleInputChange(rowindex, column.key, e.target.value)
          }
          className="pl-4"
        />

        {true ? <div className={' h-full w-[1px]'}></div> : <></>}
      </div> */
  };

  const handleDropdown = async (value: string) => {
    console.log(value)
    if (user_id === -1) return;
    const list = getYearsList(Number.parseInt(value));

    console.log(list);
    setCostData([]);

    if (list.length === 0) {
      setCostData((prevData: any) => [
        ...prevData,
        {
          cbcv_user_id: user_id || -1,
          cost_of_revenue: 0,

          revenue: 0,
          sga_expense: 0,
          creation_datetime: new Date(),
          accounting_year: value + '-' + (1).toString(),
        },
      ]);
      setCostData((prevData: any) => [
        ...prevData,
        {
          cbcv_user_id: user_id || -1,
          cost_of_revenue: 0,
          creation_datetime: new Date(),
          revenue: 0,
          sga_expense: 0,
          accounting_year: value + '-' + (2).toString(),
        },
      ]);
      setCostData((prevData: any) => [
        ...prevData,
        {
          cbcv_user_id: user_id || -1,
          cost_of_revenue: 0,
          creation_datetime: new Date(),
          revenue: 0,
          sga_expense: 0,
          accounting_year: value + '-' + (3).toString(),
        },
      ]);
      setCostData((prevData: any) => [
        ...prevData,
        {
          cbcv_user_id: user_id || -1,
          cost_of_revenue: 0,
          creation_datetime: new Date(),
          revenue: 0,
          sga_expense: 0,
          accounting_year: value + '-' + (4).toString(),
        },
      ]);
    }
    else {
      setCostData((prevData: any) => [
        ...prevData,
        {
          cbcv_user_id: user_id || -1,
          cost_of_revenue: 0,
          revenue: 0,
          sga_expense: 0,
          creation_datetime: new Date(),
          accounting_year: (list[0] + 1).toString() + '-' + (1).toString(),
        },
      ]);
      setCostData((prevData: any) => [
        ...prevData,
        {
          cbcv_user_id: user_id || -1,
          cost_of_revenue: 0,
          revenue: 0,
          creation_datetime: new Date(),
          sga_expense: 0,
          accounting_year: (list[0] + 1).toString() + '-' + (2).toString(),
        },
      ]);
      setCostData((prevData: any) => [
        ...prevData,
        {
          cbcv_user_id: user_id || -1,
          cost_of_revenue: 0,
          revenue: 0,
          creation_datetime: new Date(),
          sga_expense: 0,
          accounting_year: (list[0] + 1).toString() + '-' + (3).toString(),
        },
      ]);
      setCostData((prevData: any) => [
        ...prevData,
        {
          cbcv_user_id: user_id || -1,
          cost_of_revenue: 0,
          creation_datetime: new Date(),
          revenue: 0,
          sga_expense: 0,
          accounting_year: (list[0] + 1).toString() + '-' + (4).toString(),
        },
      ]);

      for (const element of list) {
        const obj: CBCVCostData = {
          cbcv_user_id: user_id || -1,
          cost_of_revenue: 0,
          revenue: 0,
          creation_datetime: new Date(),
          sga_expense: 0,
          accounting_year: element.toString(),
        };
        setCostData((prevData: any) => [...prevData, obj]);
      }
    }

  };
  let updateDate = '';

  if ((costData as []).length > 0) {
    updateDate = costData[0].created;
  }
  function formatDateTime(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더해줍니다.
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}.${month}.${day} ${hours}:${minutes} 업데이트`;
  }
  let datetimestring = '';
  if (items.length > 0)
    datetimestring = formatDateTime(new Date(items[0].creation_datetime));

  return (
    <div className="flex">
      <CBCVTab></CBCVTab>
      <div className=" min-h-[977px] w-[1600px] flex flex-col justify-between">
        <TittleBar tittle="기본 설정 - 비용 데이터 입력"></TittleBar>

        <div className=" h-full ">
          <div className="pl-8 flex flex-col items-center justify-center">
            <div className="h-8" />
            <div className="w-[744px] h-[120px] p-5 bg-[#F2F4F7] border-[#D8DDE3] border-[1px] rounded-lg">
              <div className="flex justify-between">
                <div className="font-bold">분석 시작 연도</div>
                <>{datetimestring}</>
                {/*              <>2024.01.01 20:33 </> */}
              </div>
              <div className="h-4" />

              <Dropdown
                onOptionChange={handleDropdown}
                selectedOptionKey={new Date().getFullYear().toString()}
                options={generateYearOptions()}
                buttonClassName="px-4 border-[1px] border-[#D8DDE3] w-[329px] h-[40px] flex justify-between items-center rounded-lg bg-white "
                dropdownClassName="flex flex-col border-[1px] border-[#D8DDE3] w-[329px]  flex justify-between items-start rounded-lg bg-white "
                itemClassName="px-4  w-full h-[30px]"
                itemSelectedClassName="px-4 h-[30px] w-full bg-gray-200 "
                itemHoverClassName="px-4 h-[30px] w-full bg-gray-100 "
              />
            </div>
            <div className="h-8" />

            {showTable && (
              <TableComponent
                columns={columns}
                data={costData}
                maxHeight={465}
                className="text-sm border-2 border-[#D8DDE3] overflow-clip rounded-lg  w-[744px] "
                renderCell={renderCell}
                renderColumn={renderCol}
              />
            )}
          </div>
        </div>
        <div className="flex justify-center item-center h-[60px] border-t-2 w-full">
          <div className="flex  justify-end items-center gap-2 w-[750px]">
            <BackButton>
            <button
              className={`border-2 h-[40px] w-[96px] rounded-md  flex items-center justify-center`}
            >
              이전
            </button></BackButton>
            <button
              className={` bg-[#4046E3] h-[40px] w-[96px] rounded-md text-white flex items-center justify-center`}
              onClick={postDate}
            >
              연결하기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CostDataPage;
