import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  FC,
} from 'react';
import { useModal } from '../modal/ModalContext';

interface ModalMessageContextProps {
  message: string;
  updateMessage: (type: 'error' | 'confirm', newMessage: string) => void;
}

const ModalMessageContext = createContext<ModalMessageContextProps | undefined>(
  undefined,
);

interface ModalMessageProviderProps {
  children: ReactNode;
}

export const CBCVModalMessageProvider: FC<ModalMessageProviderProps> = ({
  children,
}) => {
  const { showModal, hideModal } = useModal();
  const [message, setMessage] = useState<string>('');

  const updateMessage = (type: 'error' | 'confirm', newMessage: string) => {
    const sgv =
      type === 'error' ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_275_1194)">
            <path
              d="M7 7L13 13"
              stroke="#DE407A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13 7L7 13"
              stroke="#DE407A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
              stroke="#DE407A"
              strokeWidth="2"
            />
          </g>
          <defs>
            <clipPath id="clip0_275_1194">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_275_1218)">
            <path
              d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
              stroke="#75D696"
              strokeWidth="2"
            />
            <mask
              id="mask0_275_1218"
              
              maskUnits="userSpaceOnUse"
              x="4"
              y="4"
              width="12"
              height="12"
            >
              <path d="M16 4H4V16H16V4Z" fill="white" />
            </mask>
            <g mask="url(#mask0_275_1218)">
              <path
                d="M6 10L9 13L14 7"
                stroke="#75D696"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_275_1218">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );

    showModal(
      <div
        onClick={hideModal}
        className="absolute bottom-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      >
        <div className="bg-black bg-opacity-75 rounded-lg flex h-12 w-[800px] pl-5 gap-2.5 items-center">
          {sgv}
          <p className="text-white">{newMessage}</p>
        </div>
      </div>,
    );
    setTimeout(hideModal, 10000);
  };

  return (
    <ModalMessageContext.Provider value={{ message, updateMessage }}>
      {children}
    </ModalMessageContext.Provider>
  );
};

export const useModalMessage = (): ModalMessageContextProps => {
  const context = useContext(ModalMessageContext);
  if (!context) {
    throw new Error(
      'useModalMessage must be used within a ModalMessageProvider',
    );
  }
  return context;
};
export default CBCVModalMessageProvider;
