import React, { useState } from 'react';
import ModalBase from '../common/ModalBase';
import Checkbox from '../common/Checkbox';
import { formatDate } from '../common/Auxiliary';

interface ModalProps {

  onClose: () => void;
  user_id: number;
  request_id:number;
}

const AnalysisOptions: React.FC<ModalProps> = ({
  onClose,
  user_id,
  request_id,
}) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };
  const [cost_sga_rate, setCostSgaRate] = useState<number>(94);
  const [capital_cost_rate, setCapitalCostRate] = useState<number>(6);
  const [sga_override_param, setSgaOverrideParam] = useState<boolean>(false);

  
  const renderCheckbox = (checked: boolean) => {
    return !checked ? (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="19"
          height="19"
          rx="3.5"
          stroke="#D8DDE3"
        />
      </svg>
    ) : (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_413_680)">
          <path
            d="M16 0H4C1.79086 0 0 1.79086 0 4V16C0 18.2091 1.79086 20 4 20H16C18.2091 20 20 18.2091 20 16V4C20 1.79086 18.2091 0 16 0Z"
            fill="#4046E3"
          />
          <path
            d="M15.7087 6.284C15.5184 6.10174 15.2651 6 15.0017 6C14.7382 6 14.4849 6.10174 14.2947 6.284L8.75168 11.659L5.70868 8.70901C5.51843 8.52676 5.26515 8.42499 5.00168 8.42499C4.73822 8.42499 4.48493 8.52676 4.29468 8.70901C4.20215 8.7976 4.12851 8.90401 4.0782 9.02182C4.02789 9.13963 4.00195 9.2664 4.00195 9.3945C4.00195 9.5226 4.02789 9.64937 4.0782 9.76718C4.12851 9.88499 4.20215 9.99143 4.29468 10.08L7.33768 13.03L8.04568 13.717C8.23572 13.8989 8.48862 14.0004 8.75168 14.0004C9.01474 14.0004 9.26765 13.8989 9.45768 13.717L10.1657 13.03L15.7087 7.65601C15.8014 7.5674 15.8751 7.46092 15.9255 7.34302C15.9759 7.22511 16.0019 7.09823 16.0019 6.97C16.0019 6.84178 15.9759 6.71489 15.9255 6.59698C15.8751 6.47908 15.8014 6.3726 15.7087 6.284Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_413_680">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  };



  const analyze = async () => {
    const url = '/api/cbcv-api/cbcv-analysis/';
    const data = {
      user_id,
      request_id,
      cost_sga_rate,
      capital_cost_rate,
      sga_override_param,
      standard_date: formatDate(new Date())
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();

  
    } catch (error) {
      console.error('Error sending POST request:', error);
    }

    onClose();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
  
  return (
    <ModalBase>
      <div className="flex flex-col text-gray-600 bg-white p-2 rounded-lg w-[341px] h-[229px] items-center justify-center">
        <div className="text-lg font-bold mb-4">분석 옵션</div>
        <hr className="w-full" />
        <div className="h-4"></div>
        <form
          onSubmit={handleSubmit}
          className="flex text-sm w-5/6 flex-col gap-2 items-start "
        >
          <div className="flex w-full justify-center items-start ">
            <div className="w-1/2 text-xs font-bold">매출 대비 비용 비율</div>
            <div className="w-1/2 flex gap-2 justify-start items-center ">
            <input
                type="number"
                className="pl-2 w-16 border-2 rounded-lg h-6"
                value={cost_sga_rate}
                onChange={(e) => setCostSgaRate(parseFloat(e.target.value))}
              />
              %
            </div>
          </div>
          <div className="flex w-full justify-center items-start ">
            <div className="w-1/2 text-xs font-bold">SGA 입력값 강제 적용</div>
            <div className="w-1/2 flex gap-2 justify-start items-center ">
            <Checkbox
                checked={sga_override_param}
                onChange={setSgaOverrideParam}
                renderCheckbox={renderCheckbox}
              ></Checkbox>
            </div>
          </div>
          <div className="flex w-full justify-center items-start ">
            <div className="w-1/2 text-xs font-bold">자본 비용 비율</div>
            <div className="w-1/2 flex gap-2 justify-start items-center ">
            <input
                type="number"
                className="pl-2 w-16 border-2 rounded-lg h-6"
                value={capital_cost_rate}
                onChange={(e) => setCapitalCostRate(parseFloat(e.target.value))}
              />
              %
            </div>
          </div>

          <div className="flex justify-center gap-4 w-full">
            <button
              onClick={onClose}
              type="submit"
              style={{ background: '#7D7D7D' }}
              className="text-white font-bold w-[72px] h-[36px] py-1 px-2 rounded-md focus:outline-none focus:shadow-outline"
            >
              취소
            </button>
            <button
              onClick={analyze}
              type="submit"
              style={{ background: '#4046E3' }}
              className="text-white font-bold w-[72px] h-[36px] py-1 px-2 rounded-md focus:outline-none focus:shadow-outline"
            >
              분석시작
            </button>
          </div>
        </form>
      </div>
    </ModalBase>
  );
};

export default AnalysisOptions;
