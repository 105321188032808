import {MainContactSection} from "../styles/contact.js";
import {MainSectionContainer, MainSectionInner} from "../styles/section.js";
import {Button} from "../styles/btn.js";
import {useContext, useState} from "react";
import {LangContext} from "../Contexts/LangContext.jsx";

export default function MainContact() {

    const {t} = useContext(LangContext);

    const [formData, setFormData] = useState({
        company: '',
        name: '',
        position: '',
        email: '',
        phone: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault(); // 페이지 리로드 방지
        // console.log('submit start');

        // 폼 데이터 validation
        if(formData.company.replace(' ', '') === '' || !formData.company.trim() ) {
            console.log('회사명은 필수!!!');
            alert(t('contactInputCheck1'));
            return;
        }
        if(formData.name.replace(' ', '') === '' || !formData.name.trim() ) {
            console.log('성함은 필수!!!');
            alert(t('contactInputCheck2'));
            return;
        }
        // if(formData.position.replace(' ', '') === '' || !formData.position.trim() ) {
        //     console.log('직책은 필수!!!');
        //     alert(t('contactInputCheck3'));
        //     return;
        // }
        if(formData.email.replace(' ', '') === '' || !formData.email.trim() ) {
            console.log('이메일은 필수!!!');
            alert(t('contactInputCheck4'));
            return;
        }
        if(formData.phone.replace(' ', '') === '' || !formData.phone.trim() ) {
            console.log('전화번호는 필수!!!');
            alert(t('contactInputCheck5'));
            return;
        }

        const payload = {
            company: formData.company,
            name: formData.name,
            position: formData.position,
            email: formData.email,
            phone: formData.phone
        };
        
        // 서버로 데이터 전송
        try {
            // const response = await fetch('https://omovalue.com/api/send-email/service-application', {
            const response = await fetch('/api/send-email/service-application', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            // console.log('Form submitted');

            if (response.ok) {
                const result = await response.json();
                // console.log('Success:', result);
                alert(t('contactSubmitSuccess'));
                if (result.result === true) {
                    setFormData({
                        company: '',
                        name: '',
                        position: '',
                        email: '',
                        phone: ''
                    });
                    // console.log('cleared');
                }
            } else {
                console.error('Error:', response.statusText);
                // 여기서 에러 처리
            }
        } catch (error) {
            console.error('Network error:', error);
            // 네트워크 오류 처리
        }
    };

    return (
        <MainContactSection>
            <MainSectionInner>
                <MainSectionContainer>
                    <div className="__grid" id="inquiry">
                        <div className="__desc" data-anims>
                            <h3 className="__size-xl" data-anim dangerouslySetInnerHTML={{__html:t('contactTitle')}}></h3>
                            <p className="__size-md" data-anim dangerouslySetInnerHTML={{__html:t('contactDesc')}}></p>
                        </div>
                        <div className="__form" data-anims>
                            {/* <form onSubmit={handleSendContact} data-anim> */}
                            <form onSubmit={handleSubmit} data-anim>
                                <input type="text" name="company" value={formData.company} onChange={handleChange} placeholder={t('contactInput1')}/>
                                <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder={t('contactInput2')}/>
                                <input type="text" name="position" value={formData.position} onChange={handleChange} placeholder={t('contactInput3')}/>
                                <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder={t('contactInput4')}/>
                                <input type="tel" name="phone" value={formData.phone} onChange={handleChange} placeholder={t('contactInput5')}/>
                                <Button as={"button"} type={'submit'} className="__accent __lg"><span>{t('contactSubmit')}</span></Button>
                            </form>
                        </div>
                    </div>
                </MainSectionContainer>
            </MainSectionInner>
        </MainContactSection>
    )
}