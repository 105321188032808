import styled from "styled-components";
import {MainContainer} from "./common.js";
import {DEFAULT_FONT_SIZE, BREAKPOINT, COLOR_ACCENT} from "./vars.js";

export const MainSection = styled.section`
    overflow: hidden;
    position: relative;
`;

export const MainSectionInner = styled.div`
    position: relative;
`;

export const MainSectionContainer = styled(MainContainer)`
    
`;


export const MainSectionInnerPadded = styled(MainSectionInner)`
    padding: ${100/DEFAULT_FONT_SIZE}em 0;
`

export const MainSectionHeading = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${30/DEFAULT_FONT_SIZE}em;
    margin-bottom: ${56/DEFAULT_FONT_SIZE}em;
    .__sub-title {
        color: #000;
        strong {
            color: ${COLOR_ACCENT};
        }
    }
    .__title {
        
        color: #000;
        span {
            display: inline-block;
        }
        strong {
            color: ${COLOR_ACCENT};
        }
    }
`;

export const MainSectionImg = styled.div`
    margin: 0 auto;
    img {
        display: block;
        width: 100%;
    }
`