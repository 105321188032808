import styled from "styled-components";
import {MainSection} from "./section.js";
import {DEFAULT_FONT_SIZE, BREAKPOINT, COLOR_ACCENT} from "./vars.js";

export const MainClvSection = styled(MainSection)`
    padding: ${140/DEFAULT_FONT_SIZE}em 0;
    background: linear-gradient(90deg, rgba(83, 104, 222, 0.1) 0%, rgba(164, 70, 128, 0.1) 100%);
    .__grid {
        display: flex;
        justify-content: space-between;
        gap: ${36/DEFAULT_FONT_SIZE}em;
        align-items: center;
        max-width: 1140px;
        margin: 0 auto;
        .__img {
            width: ${530/1280*100}%;
            img {
                display: block;
                width: 100%;
            }
        }
        .__desc {
            color: #000;
            strong {
                display: block;
                background: linear-gradient(90deg, #5368DE 0%, #A44680 100%);
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;
            }
        }
        @media screen and (max-width: ${BREAKPOINT.MOBILE}px) {
            flex-direction: column;
            justify-content: center;
            text-align: center;
            gap: ${50/DEFAULT_FONT_SIZE}em;
            .__img {
                width: 100%;
                padding: 0 ${10/DEFAULT_FONT_SIZE}em;
                box-sizing: border-box;
            }
        }
    }
`;