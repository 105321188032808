import styled from "styled-components";
import {MainContainer} from "./common";
import {DEFAULT_FONT_SIZE, BREAKPOINT, COLOR_ACCENT} from "./vars.js";


export const MainHeaderWrapper = styled.header`
    position: sticky;
    top: 0;
    z-index: 999999;
    border-bottom: 1px solid #D8DDE3;
    background: #fff;
`;

export const MainHeaderInner = styled(MainContainer)`
    @media screen and (max-width: ${BREAKPOINT.MIN}px) {
        padding: 0 12px;
    }
`;


export const MainHeaderContent = styled.div`
    display: flex;
    align-items: center;
    gap: ${40/DEFAULT_FONT_SIZE}em;
    height: max(60px, ${80/DEFAULT_FONT_SIZE}em);
    
    .__brand {
        img {
            display: block;
            width: ${200/DEFAULT_FONT_SIZE}em;
            height: auto;
            @media screen and (max-width: ${BREAKPOINT.MIN}px) {
                width: 100px;
            }
        }
    }
    
    nav {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: ${24/DEFAULT_FONT_SIZE}em;
        font-weight: 700;
        .__langs {
            display: flex;
            align-items: center;
            gap: ${8/DEFAULT_FONT_SIZE}em;
            margin-right: ${6/DEFAULT_FONT_SIZE}em;
            img {
                display: block;
                width: ${22/DEFAULT_FONT_SIZE}em;
            }
            a {
                opacity: .5;
                &.__active {
                    opacity: 1;
                }
            }
        }
        .__cta {
            .__m {
                display: none;
            }
            @media screen and (max-width: ${BREAKPOINT.MIN}px) {
                .__m {
                    display: block;
                }
                .__d {
                    display: none;
                }
            }
        }
    }
    
`;