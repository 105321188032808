import {
    MainSection,
    MainSectionContainer,
    MainSectionHeading,
    MainSectionImg,
    MainSectionInnerPadded
} from "../styles/section.js";
import ImgDiagram4 from "../imgs/diagram4.png";
import ImgDiagram5 from "../imgs/diagram5.png";
import ImgDiagram4En from "../imgs/diagram4-en.png";
import ImgDiagram5En from "../imgs/diagram5-en.png";
import {useContext} from "react";
import {LangContext} from "../Contexts/LangContext.jsx";

export default function MainFeature2() {
    const {t, lang} = useContext(LangContext);
    return (
        <> 
            <MainSection>
                <MainSectionInnerPadded>
                    <MainSectionContainer>
                        <MainSectionHeading data-anims>
                            <h2 className="__sub-title __size-lg" data-anim
                                dangerouslySetInnerHTML={{__html: t('feature3SubTitle')}}></h2>
                            <h3 className="__title __size-xl" data-anim
                                dangerouslySetInnerHTML={{__html: t('feature3Title')}}></h3>
                            <p className="__desc __size-md" data-anim
                               dangerouslySetInnerHTML={{__html: t('feature3Desc')}}></p>
                        </MainSectionHeading>
                        <MainSectionImg data-anims style={{maxWidth: '1280px'}}>
                            <img src={lang === 'en' ? ImgDiagram4En : ImgDiagram4} alt="" data-anim="img" style={{aspectRatio:'2563/1642'}}/>
                        </MainSectionImg>
                    </MainSectionContainer>
                </MainSectionInnerPadded>
            </MainSection>

            <MainSection>
                <MainSectionInnerPadded>
                    <MainSectionContainer>
                        <MainSectionHeading data-anims>
                            <h2 className="__sub-title __size-lg" data-anim
                                dangerouslySetInnerHTML={{__html: t('feature4SubTitle')}}></h2>
                            <h3 className="__title __size-xl" data-anim
                                dangerouslySetInnerHTML={{__html: t('feature4Title')}}></h3>
                            <p className="__desc __size-md" data-anim
                               dangerouslySetInnerHTML={{__html: t('feature4Desc')}}></p>
                        </MainSectionHeading>
                        <MainSectionImg data-anims style={{maxWidth: '1280px'}}>
                            <img src={lang === 'en' ? ImgDiagram5En : ImgDiagram5} alt="" data-anim="img" style={{aspectRatio:'2563/1078'}}/>
                        </MainSectionImg>
                    </MainSectionContainer>
                </MainSectionInnerPadded>
            </MainSection>
        </>
    )
}