import styled from "styled-components";
import {MainSection} from "./section.js";
import {DEFAULT_FONT_SIZE, BREAKPOINT, COLOR_ACCENT} from "./vars.js";

export const MainContactSection = styled(MainSection)`
    padding: ${120/DEFAULT_FONT_SIZE}em 0;
    
    .__grid {
        max-width: 980px;
        margin: 0 auto;
        display: flex;
        gap: ${60/DEFAULT_FONT_SIZE}em;
        justify-content: space-between;
        align-items: center;
        
        @media screen and (max-width: ${BREAKPOINT.TABLET}px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
        .__desc {
          display: flex;
            flex-direction: column;
            gap: ${30/DEFAULT_FONT_SIZE}em;
            h3 {
                color: ${COLOR_ACCENT};
            }
        }
        .__form {
            width: 420px;
            @media screen and (max-width: ${BREAKPOINT.TABLET}px) {
                max-width: 540px;
                margin: 0 auto;
                width: 100%;
                padding: 0 20px;
            }
            form {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: ${16/DEFAULT_FONT_SIZE}em;
                input {
                    appearance: none;
                    -webkit-appearance: none;
                    border: 0;
                    box-shadow: none;
                    outline: none;
                    font-size: ${20/DEFAULT_FONT_SIZE}em;
                    display: block;
                    width: 100%;
                    box-sizing: border-box;
                    font-weight: 500;
                    letter-spacing: -0.03em;
                    border-radius: ${24/24}em;
                    background: #F4F7F9;
                    padding: ${32/24}em ${40/24}em;
                    font-family: inherit;
                    color: inherit;
                    &:focus {
                        background: #E9F0F3;
                    }
                }
                button {
                    margin-top: ${16/DEFAULT_FONT_SIZE}em;
                    min-width: 10em;
                }
            }
        }
    }
`;