/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const NewsfeedIcon1 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`newsfeed-icon-1 ${className}`}
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_3_28405)">
        <mask className="mask" height="18" id="mask0_3_28405" maskUnits="userSpaceOnUse" width="18" x="0" y="0">
          <path className="path" d="M18 0H0V18H18V0Z" fill="white" />
        </mask>
        <g className="g" mask="url(#mask0_3_28405)">
          <path
            className="path"
            d="M7.1 1.5H1.9C1.67909 1.5 1.5 1.67909 1.5 1.9V7.1C1.5 7.32091 1.67909 7.5 1.9 7.5H7.1C7.32091 7.5 7.5 7.32091 7.5 7.1V1.9C7.5 1.67909 7.32091 1.5 7.1 1.5Z"
            stroke="#BCC2C9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            className="path"
            d="M16.1 10.5H1.9C1.67909 10.5 1.5 10.6791 1.5 10.9V16.1C1.5 16.3209 1.67909 16.5 1.9 16.5H16.1C16.3209 16.5 16.5 16.3209 16.5 16.1V10.9C16.5 10.6791 16.3209 10.5 16.1 10.5Z"
            stroke="#BCC2C9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            className="path"
            d="M10.5 1.5H16.5"
            stroke="#BCC2C9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            className="path"
            d="M10.5 4.5H13.5"
            stroke="#BCC2C9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            className="path"
            d="M10.5 7.5H16.5"
            stroke="#BCC2C9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            className="path"
            d="M5.1 3.5H3.9C3.79391 3.5 3.69217 3.54214 3.61716 3.61716C3.54214 3.69217 3.5 3.79391 3.5 3.9V5.1C3.5 5.20609 3.54214 5.30783 3.61716 5.38284C3.69217 5.45786 3.79391 5.5 3.9 5.5H5.1C5.20609 5.5 5.30783 5.45786 5.38284 5.38284C5.45786 5.30783 5.5 5.20609 5.5 5.1V3.9C5.5 3.79391 5.45786 3.69217 5.38284 3.61716C5.30783 3.54214 5.20609 3.5 5.1 3.5Z"
            fill="#BCC2C9"
          />
        </g>
      </g>
      <defs className="defs">
        <clipPath className="clipPath" id="clip0_3_28405">
          <rect className="rect" fill="white" height="18" width="18" />
        </clipPath>
      </defs>
    </svg>
  );
};
