import React, { useEffect } from 'react';
import TableComponent, { TableColumn, TableData } from './TableComponent';
import useRESTAPI from '../Contexts/RESTAPI';
import ComapanyInfoModal from '../modals/ComapanyInfoModal';
import { useModal } from '../modal/ModalContext';

import ReportCompleted from '../modals/ReportCompleted';
import ReportDeleted from '../modals/ReportDeleted';
import { formatDate, formatDateTime } from '../common/Auxiliary';
import CBCVAdminTab from '../../CBCVAdminTab';
import TittleBar from '../TittleBar';
import { EDataStatus } from '../../types';
import AnalysisOptions from '../modals/AnalysisOptions';
import AdminTittleBar from '../AdminTittleBar';
import { Link } from 'react-router-dom';

const DeleteButton = () => {
  return (
    <svg
      className="cursor-pointer"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <mask
        id="mask0_270_485"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="17"
        height="17"
      >
        <path d="M16.003 0H0V16.003H16.003V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_270_485)">
        <path
          d="M6.499 4V2H9.499V4"
          stroke="#A1A7B1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.501 4H10.063H14.001"
          stroke="#A1A7B1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.002 4H6.5"
          stroke="#A1A7B1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 6V12.222C3.03144 12.7222 3.25927 13.1898 3.63388 13.5228C4.00849 13.8559 4.49951 14.0274 5 14H11C11.5005 14.0274 11.9915 13.8559 12.3661 13.5228C12.7407 13.1898 12.9686 12.7222 13 12.222V6"
          stroke="#A1A7B1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.505 8V11"
          stroke="#A1A7B1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.505 8V11"
          stroke="#A1A7B1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};


export const ManageRequests = (): JSX.Element => {
  const { items, fetchItems, addItem, editItem, removeItem } =
    useRESTAPI('/api/cbcv-request');

  useEffect(() => {
    fetchItems({ pageSize: 8192 });
  }, []);

  useEffect(() => {
    console.log('items');
   }, [items]);
  
  const { hideModal, showModal } = useModal();


  const showCompanyInfo = (data: any) => {
    showModal(
      <ComapanyInfoModal
        onClose={hideModal}
        phoneNumber={data.phone_number}
        email={data.email}
        name={data.company_name}
      ></ComapanyInfoModal>,
    );
  };


  const downloadFile = (name: string, url: string) => {
    const fileUrl = url;
    const fileName = name;
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const showReport = (request_id: number) => {
    sendemail(request_id)
    showModal(<ReportCompleted onClose={hideModal} />);
  };

  const showDelete = async (id: any) => {
    showModal(<ReportDeleted onClose={hideModal} />);
    await removeItem(items[id].request_id);
    await fetchItems({ pageSize: 1024 });
  };

  const columns: TableColumn[] = [
    { key: 'status', label: '상태', width: 172 },
    { key: 'com_name', label: '기업명', width: 550 },
    { key: 'request_id', label: '분석 요청 ID', width: 136 },
    { key: 'createdAt', label: '시작일 (업데이트)', width: 196 },
    { key: 'management', label: '관리', width: 126 },
    { key: 'delete', label: '삭제', width: 100 },
  ];

  const openAnalysisOptionsModal = (request_id: number, user_id: number) => {
    showModal(<AnalysisOptions key={request_id.toString()} onClose={hideModal} request_id={request_id} user_id={user_id} />)
  }

  const cellData = (data: any, row: number, col: string) => {
    console.log(items[row].cbcv_user_id);
    if (col === 'request_id') {
      return <div className="px-5 ">{items[row].request_id}</div>;
    }

    if (col === 'com_name') {
      return (
        <div className="px-5 flex justify-between items-center">
          <div
            onClick={() => showCompanyInfo(items[row].cbcv_user_id)}
            className="cursor-pointer underline"
          >
            {items[row].cbcv_user_id.company_name}

          </div>
          {items[row].cbcv_user_id.localStorages.leading > 0 &&
            <a className='w-[180px] h-[34px] flex justify-center items-center bg-[#F2F3FF] rounded-md text-[#4046E3]'
              target="_blank"
              href={'/api/extraFile/download/' + items[row].cbcv_user_id.localStorages[0].uuid}
              download={items[row].cbcv_user_id.localStorages[0].file_name}>
              주문 데이터 파일 다운로드
            </a>}


          {items[row].status > 3 && 
          <Link  target="_blank"  to={`/customer-value-status?request_id=${items[row].request_id}`} ><div className="font-bold p-2 bg-gray-200 rounded-lg text-gray-900">
            보고서 조회
          </div></Link>}
        </div>
      );
    }

    if (col === 'createdAt') {
      return (
        <div className="px-4">
          <div className="text-center font-Pretendard font-medium text-gray-400 text-sm leading-22 tracking-tight">
            {formatDateTime(new Date(data), '.')}
          </div>
          <div className="text-center font-Pretendard font-medium text-gray-700 text-xs leading-22 tracking-tight">
            {'(' + formatDateTime(new Date(items[row].updatedAt), '.') + ')'}
          </div>
        </div>
      );
    }

    if (col === 'delete') {
      return (
        <div onClick={() => showDelete(row)} className="px-4">
          {DeleteButton()}
        </div>
      );
    }
    if (col === 'com_name') {
      const management = items[row].status;
      return <div>{management}</div>;
    }

    if (col === 'management') {
      const management = items[row].status;
  
      const stat = EDataStatus[management];
      console.log(items[row].request_id);
      console.log({status:0});
      if (stat === 'requested')
        return (
          <div onClick={() => transmit(items[row].cbcv_user_id.id, items[row].request_id)} className="cursor-pointer text-[#4046E3] underline font-bold flex justify-center items-center">
            전송 시작
          </div>
        );
      if (stat === 'transmitting')
        return (
          <div  onClick={async  ()  =>  { await editItem(items[row].request_id,{status:0}); await fetchItems({ pageSize: 8192 });}} className="text-[#424242] underline font-bold flex justify-center items-center cursor-pointer">
            일시 중지
          </div>
        );
      if (stat === 'paused-transmitting')
        return (
          <div className="text-[#4046E3] underline font-bold flex justify-center items-center cursor-pointer">
            재개
          </div>
        );
      if (stat === 'transmitted')
        return (
          <div onClick={() => openAnalysisOptionsModal(items[row].request_id, items[row].cbcv_user_id.id)} className="text-[#4046E3] underline font-bold flex justify-center items-center cursor-pointer">
            분석 시작
          </div>
        );
      if (stat === 'analyzing')
        return (
          <div  onClick={async () => {await editItem(items[row].request_id,{status:2});await fetchItems({ pageSize: 8192 });}}  className="text-[#424242] underline font-bold flex justify-center items-center cursor-pointer">
            일시 정지
          </div>
        );
      if (stat === 'paused-analyzing')
        return (
          <div className="text-[#4046E3] underline font-bold flex justify-center items-center cursor-pointer">
            재개
          </div>
        );
      if (stat === 'analyzed')
        return (
          <div
            onClick={() => showReport(items[row].request_id)}
            className="text-[#4046E3] underline font-bold flex justify-center items-center cursor-pointer"
          >
            보고서 발송
          </div>
        );
      if (stat === 'reported') return <></>;
    }

    if (col === 'status') {

      const stat = EDataStatus[data];
      if (stat === 'requested')
        return (
          <div
            style={{ background: '#EAEBFA' }}
            className="m-4 px-2  flex items-center justify-start w-[141px] h-[34px] rounded-md gap-2"
          >
            <div
              style={{ background: '#CFD1F8' }}
              className=" w-5 h-5 flex items-center justify-center rounded-full"
            >
              1
            </div>
            <div style={{ color: '#4046E3' }} className="rounded-full">
              전송 대기중
            </div>
          </div>
        );
      if (stat === 'transmitting')
        return (
          <div
            style={{ background: '#EAEBFA00' }}
            className="m-4 px-2 flex items-center justify-start  w-[141px] h-[34px] rounded-md gap-2"
          >
            <svg
              style={{ background: '#D1D1D100' }}
              className="w-5 h-5 flex items-center justify-center rounded-full"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 8 3"
              fill="none"
            >
              <circle cx="1.1765" cy="1.05882" r="1.05882" fill="#D1D1D1" />
              <circle cx="3.99999" cy="1.05882" r="1.05882" fill="#D1D1D1" />
              <circle cx="6.82359" cy="1.05882" r="1.05882" fill="#D1D1D1" />
            </svg>

            <div style={{ color: '#A1A7B1' }} className="rounded-full">
              데이터 전송중
            </div>
          </div>
        );
      if (stat === 'paused-transmitting')
        return (
          <div
            style={{ background: '#EAEBFA00' }}
            className="m-4 px-2 flex items-center justify-start  w-[141px] h-[34px] rounded-md gap-2"
          >
            <svg
              style={{ background: '#D1D1D100' }}
              className="w-5 h-5 flex items-center justify-center rounded-full"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 8 3"
              fill="none"
            >
              <circle cx="1.1765" cy="1.05882" r="1.05882" fill="#D1D1D1" />
              <circle cx="3.99999" cy="1.05882" r="1.05882" fill="#D1D1D1" />
              <circle cx="6.82359" cy="1.05882" r="1.05882" fill="#D1D1D1" />
            </svg>

            <div style={{ color: '#A1A7B1' }} className="rounded-full">
              데이터 전송중
            </div>
          </div>
        );
      if (stat === 'transmitted')
        return (
          <div
            style={{ background: '#EAEBFA00' }}
            className="m-4 px-2 flex items-center justify-start  w-[141px] h-[34px] rounded-md gap-2"
          >
            <div
              style={{ background: '#D1D1D1' }}
              className=" w-5 h-5 flex items-center justify-center rounded-full"
            >
              2
            </div>
            <div style={{ color: '#474A4E' }} className="rounded-full">
              데이터 전송 완료
            </div>
          </div>
        );
      if (stat === 'analyzing')
        return (
          <div
            style={{ background: '#EAEBFA00' }}
            className="m-4 px-2 flex items-center justify-start  w-[141px] h-[34px] rounded-md gap-2"
          >
            <svg
              style={{ background: '#D1D1D100' }}
              className="w-5 h-5 flex items-center justify-center rounded-full"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 8 3"
              fill="none"
            >
              <circle cx="1.1765" cy="1.05882" r="1.05882" fill="#D1D1D1" />
              <circle cx="3.99999" cy="1.05882" r="1.05882" fill="#D1D1D1" />
              <circle cx="6.82359" cy="1.05882" r="1.05882" fill="#D1D1D1" />
            </svg>

            <div style={{ color: '#A1A7B1' }} className="rounded-full">
              데이터 분석중
            </div>
          </div>
        );
      if (stat === 'paused-analyzing')
        return (
          <div
            style={{ background: '#EAEBFA00' }}
            className="m-4 px-2 flex items-center justify-start  w-[141px] h-[34px] rounded-md gap-2"
          >
            <svg
              style={{ background: '#D1D1D100' }}
              className="w-5 h-5 flex items-center justify-center rounded-full"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 8 3"
              fill="none"
            >
              <circle cx="1.1765" cy="1.05882" r="1.05882" fill="#D1D1D1" />
              <circle cx="3.99999" cy="1.05882" r="1.05882" fill="#D1D1D1" />
              <circle cx="6.82359" cy="1.05882" r="1.05882" fill="#D1D1D1" />
            </svg>

            <div style={{ color: '#A1A7B1' }} className="rounded-full">
              데이터 분석중
            </div>
          </div>
        );
      if (stat === 'analyzed')
        return (
          <div
            style={{ background: '#EAEBFA00' }}
            className="m-4 px-2 flex items-center justify-start  w-[141px] h-[34px] rounded-md gap-2"
          >
            <div
              style={{ background: '#D1D1D1' }}
              className=" w-5 h-5 flex items-center justify-center rounded-full"
            >
              3
            </div>
            <div style={{ color: '#474A4E' }} className="rounded-full">
              데이터 분석 완료
            </div>
          </div>
        );
      if (stat === 'reported')
        return (
          <div
            style={{ background: '#EAECEF' }}
            className="m-4 px-2 flex items-center justify-start  w-[141px] h-[34px] rounded-md gap-2"
          >
            <div
              style={{ background: '#474A4E' }}
              className=" w-5 h-5 flex items-center justify-center rounded-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="8"
                viewBox="0 0 9 8"
                fill="none"
              >
                <mask
                  id="mask0_259_435"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="9"
                  height="8"
                >
                  <path d="M8.09985 0H0.0998535V8H8.09985V0Z" fill="white" />
                </mask>
                <g mask="url(#mask0_259_435)">
                  <path
                    d="M1.09961 4.00049L3.09961 6.00049L7.09961 2.00049"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            </div>
            <div style={{ color: '#474A4E' }} className="rounded-full">
              보고서 발행 완료
            </div>
          </div>
        );
    }

    return <></>;
  };

  const sendemail = async (request_id: number) => {
    const url = '/api/cbcv-api/send-report-email';
    const data = {
      request_id,
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      console.error(responseData);
      fetchItems({ pageSize: 8192 });
    } catch (error) {
      console.error('Error sending POST request:', error);
    }


    fetchItems({ pageSize: 8192 });

  }



  const transmit = async (user_id: number, request_id: number) => {

    const url = '/api/cbcv-api/cbcv-crawl';
    const data = {
      api_mode: "crawl",
      user_id,
      request_id,
      standard_date: formatDate(new Date())
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      console.log(response.status);
      const responseData = await response.json();
      console.log(responseData);

      setTimeout(() => {
        fetchItems({ pageSize: 8192 });
      }, 1000);

 
    } catch (error) {
      console.error('Error sending POST request:', error);
    }
  }

  const renderCell = (
    rowindex: number,
    rowData: TableData,
    column: TableColumn,
  ) => {
    let border = 'overflow-y-scroll';

      border = 'border-b-2';


    if (column.key !== columns[0].key) {
      border += ' border-l-2';
    }

    return (
      <div
        className={`${border}  flex items-center justify-start w-full text-[#474A4E] h-[56px]`}
      >
        <div className="w-full">
          {cellData(rowData[column.key] as string, rowindex, column.key)}
        </div>
      </div>
    );
  };

  const renderCol = (column: TableColumn) => {
    const needLine = column.key !== columns[0].key;

    return (
      <div
        className={`bg-[#F9FAFB]  border-b-2 flex items-center justify-start w-full  h-[48px] text-[#474A4E]`}
      >
        {needLine ? <div className="bg-[#D8DDE3] h-full w-[1px]"></div> : <></>}

        <div className="flex w-full justify-center">{column.label}</div>
      </div>
    );
  };

  return (
    <div className='flex'>
      <CBCVAdminTab /> 
      <div className="text-xs  w-full min-w-[1400px] min-h-[811px] ">
        <AdminTittleBar tittle='분석 요청 관리' />
        <div className='flex justify-center item-center'>
          <div className='max-w-[1448px]  pt-[38px] '>

          <style>
        {`
   
          ::-webkit-scrollbar {
            width: 12px;
            height: 12px;
          }

   
          ::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 6px;
          }

   
          ::-webkit-scrollbar-thumb:hover {
            background: #555;
          }

       
          ::-webkit-scrollbar-track {
            background: #f1f1f1;
          }
        `}
      </style>

            <TableComponent
              columns={columns}
              data={items}
              className=" border-2 rounded-lg overflow-hidden"
              renderCell={renderCell}
              renderColumn={renderCol}
              maxHeight={811}
            /></div>
        </div>
      </div>{' '}
    </div>
  );
};

export default ManageRequests;
