import {
    MainSection,
    MainSectionContainer,
    MainSectionHeading,
    MainSectionImg,
    MainSectionInnerPadded
} from "../styles/section.js";
import ImgDiagram1 from "../imgs/diagram1.png";
import ImgDiagram2 from "../imgs/diagram2.png";
import ImgDiagram1En from "../imgs/diagram1-en.png";
import ImgDiagram2En from "../imgs/diagram2-en.png";
import {useContext} from "react";
import {LangContext} from "../Contexts/LangContext.jsx";
export default function MainFeature1() {
    const {t, lang} = useContext(LangContext);
    return (
        <>
            <MainSection>
                <MainSectionInnerPadded>
                    <MainSectionContainer>
                        <MainSectionHeading data-anims>
                            <h2 className="__sub-title __size-lg" data-anim
                                dangerouslySetInnerHTML={{__html: t('feature1SubTitle')}}></h2>
                            <h3 className="__title __size-xl" data-anim
                                dangerouslySetInnerHTML={{__html: t('feature1Title')}}></h3>
                            <p className="__desc __size-md" data-anim
                               dangerouslySetInnerHTML={{__html: t('feature1Desc')}}></p>
                        </MainSectionHeading>
                        <MainSectionImg data-anims style={{maxWidth: '1280px'}}>
                            <img src={lang === 'en' ? ImgDiagram1En : ImgDiagram1} alt="" data-anim="img" style={{aspectRatio:'2562/1371'}}/>
                        </MainSectionImg>
                    </MainSectionContainer>
                </MainSectionInnerPadded>
            </MainSection>

            <MainSection>
                <MainSectionInnerPadded>
                    <MainSectionContainer>
                        <MainSectionHeading data-anims>
                            <h2 className="__sub-title __size-lg" data-anim
                                dangerouslySetInnerHTML={{__html: t('feature2SubTitle')}}></h2>
                            <h3 className="__title __size-xl" data-anim
                                dangerouslySetInnerHTML={{__html: t('feature2Title')}}></h3>
                            <p className="__desc __size-md" data-anim
                               dangerouslySetInnerHTML={{__html: t('feature2Desc')}}></p>
                        </MainSectionHeading>
                        <MainSectionImg data-anims style={{maxWidth: '934px'}}>
                            <img src={lang === 'en' ? ImgDiagram2En : ImgDiagram2} alt="" data-anim="img" style={{aspectRatio:'1867/967'}}/>
                        </MainSectionImg>
                    </MainSectionContainer>
                </MainSectionInnerPadded>
            </MainSection>
        </>
    )
}