import React, { useEffect, useState } from 'react';
import { Button } from '../Button';
import './CURegiModal.css';
import ModalBase from '../common/ModalBase';
import useRESTAPI from '../Contexts/RESTAPI';
import { useAuth } from '../Contexts/AuthContext';
import { Link } from 'react-router-dom';

interface ApiKeys {
  id?: number;
  /*   cbcv_user: User; */
  cbcv_user_id: number;
  api_keys: string;
  api_kind: string;
  api_verified: string;
}

interface CURegiModalProps {
  onClose: () => void;
}
export const CURegiModal = ({ onClose }: CURegiModalProps): JSX.Element => {
  const { user_id } = useAuth();
  const { items, fetchItems, addItem, editItem, removeItem } =
    useRESTAPI('/api/api-keys');
  const [companyCode, setCompanyCode] = useState<string>();
  const [accessKey, setAccessKey] = useState<string>();
  const [secretKey, setSecretKey] = useState<string>();

  const [companyCodeError, setCompanyCodeError] = useState<string>();
  const [accessKeyError, setaccessKeyError] = useState<string>();
  const [secretKeyError, setSecretKeyError] = useState<string>();
  const [keyConfirmed, setKeyConfirmed] = useState<boolean>(false);
  useEffect(() => {}, [user_id]);
  useEffect(() => {}, []);

  useEffect(() => {
    setCompanyCodeError(undefined);
    setaccessKeyError(undefined);
    setSecretKeyError(undefined);
  }, [companyCode, accessKey, secretKey]);

  
  const handlePostRequest = async () => {
    const target = await fetchItems({
      cbcv_user_id: user_id,
      api_kind: 'coupang',
    });
    
    if(target.length > 0)
      await removeItem(target[0].id)

    await addItem({
      cbcv_user_id: user_id,
      api_kind: 'coupang',
      
      api_verified: false,
      cp_seller_code: companyCode,
      cp_access_key: accessKey,
      cp_secret_key: secretKey
  }) 
      setKeyConfirmed(true)
  };

  function handleConnectCoupang(): void {
    ('handleConnectCoupang')
    if (!secretKey || secretKey === '') {
      setSecretKeyError('키가 입력되지 않았습니다.');
    }

    if (!companyCode || companyCode === '') {
      setCompanyCodeError('업체 코드가 입력되지 않았습니다.');
    }

    if (!accessKey || accessKey === '') {
      setaccessKeyError('키가 입력되지 않았습니다.');
    }

    if (
      user_id === undefined ||
      companyCode === undefined ||
      companyCode === '' ||
      accessKey === undefined ||
      accessKey === '' ||
      secretKey === undefined ||
      secretKey === ''
    ) {
      return;
    }

    handlePostRequest();
  }

  async function onConnect() 
  {
    const url = '/api/cbcv-api/cbcv-check';
    const data = {
      api_mode: 'check',
      channel: 'coupang',
      user_id: user_id,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();

      if(responseData.result_code ===500)
      {
        throw new Error('500error ');
      }

      const data2 = await fetchItems({
        cbcv_user_id: user_id,
        api_kind: 'coupang',
      });
      editItem(data2[0].id, {
        api_verified: true,
      });

      onClose();

      setKeyConfirmed(true);
    } catch (error) {
      console.error('Error sending POST request:', error);

      setKeyConfirmed(false);

      setSecretKeyError('올바르지 않은 키 또는 아이디');
      setCompanyCodeError('올바르지 않은 키 또는 아이디');
      setaccessKeyError('올바르지 않은 키 또는 아이디');
    } 

  /*   COUPANG_COM_CODE: companyCode,
    COUPANG_ACCESS_KEY: accessKey,
    COUPANG_SECRET_KEY: secretKey,
     */
    /* const a = await fetchItems({
      cbcv_user_id: user_id,
      api_kind: 'COUPANG_COM_CODE',
    });

    const b = await fetchItems({
      cbcv_user_id: user_id,
      api_kind: 'COUPANG_ACCESS_KEY',
    });

    const c = await fetchItems({
      cbcv_user_id: user_id,
      api_kind: 'COUPANG_SECRET_KEY',
    });

    await removeItem(a.id)
    await removeItem(b.id)
    await removeItem(c.id)

    await addItem( {
      api_keys: companyCode,
      api_kind: 'COUPANG_COM_CODE',
      api_verified: 'verifying',
      cbcv_user_id: user_id,
    })

    await addItem( {
      api_keys: accessKey,
      api_kind: 'COUPANG_ACCESS_KEY',
      api_verified: 'verifying',
      cbcv_user_id: user_id,
    })
    await addItem( {
      api_keys: secretKey,
      api_kind: 'COUPANG_SECRET_KEY',
      api_verified: 'verifying',
      cbcv_user_id: user_id,
    }) */

    /* if (user_id === undefined) return;

    if (!keyConfirmed) return;

    const keys = {
      COUPANG_COM_CODE: companyCode,
      COUPANG_ACCESS_KEY: accessKey,
      COUPANG_SECRET_KEY: secretKey,
    };

    const item: ApiKeys = {
      api_keys: JSON.stringify(keys),
      api_kind: 'COUPANG',
      api_verified: 'verifying',
      cbcv_user_id: user_id,
    };

    const data = await fetchItems({
      cbcv_user_id: user_id,
      api_kind: 'COUPANG',
    });
    (data);
    try {
      (await editItem(data[0].id, item));
    } catch (error) {
      await addItem(item);
    }

    onClose(); */
  }

  function onCancel() {
    onClose();
  }

  return (
    <ModalBase>
      <div className="CURegiModal rounded-lg bg-white">
        <div className="div rounded-t-md">
          <div className="p-5 ">
            <div className="text-wrapper-14">쿠팡 주문 데이터 연동</div>
            <div className="h-4"></div>
            <hr></hr>
            <div className="h-4"></div>
            <div className="div2">
              <div className="">업체 코드</div>

              <div className="flex gap-2">
                {keyConfirmed ? (
                  <div className="bg-[#BCC2C9] border  rounded-md flex items-center h-10 w-full">
                    <div className="pl-7 text-gray-500">
                      업체 코드를 입력해 주세요.
                    </div>
                  </div>
                ) : (
                  <input
                    value={companyCode}
                    onChange={(e) => setCompanyCode(e.target.value)}
                    placeholder={
                      companyCodeError || '업체 코드를 입력해 주세요.'
                    }
                    type="text"
                    className={`bg-white border ${companyCodeError ? 'border-[#E53073] placeholder-[#E53073]' : 'border-[#d8dde3]'} rounded-[5px] flex items-center pl-[25px] h-[38px] w-full text-left ${companyCodeError ? 'text-[#E53073]' : 'text-gray-500'}`}
                  />
                )}
              </div>

              <div className="">Access Key</div>
              <div className="flex gap-2">
                {keyConfirmed ? (
                  <div className="bg-[#BCC2C9] border  rounded-md flex items-center h-10 w-full">
                    <div className="pl-7 text-gray-500">
                      Access Key를 입력해 주세요.
                    </div>
                  </div>
                ) : (
                  <input
                    value={accessKey}
                    onChange={(e) => setAccessKey(e.target.value)}
                    placeholder={
                      accessKeyError || 'Access Key를 입력해 주세요.'
                    }
                    type="text"
                    className={`bg-white border ${accessKeyError ? 'border-[#E53073] placeholder-[#E53073]' : 'border-[#d8dde3]'} rounded-[5px] flex items-center pl-[25px] h-[38px] w-full text-left ${accessKeyError ? 'text-[#E53073]' : 'text-gray-500'}`}
                  />
                )}
              </div>
              <div className="">Secret Key</div>
              <div className="flex gap-2">
                {keyConfirmed ? (
                  <div className="bg-[#BCC2C9] border  rounded-md flex items-center h-10 w-full">
                    <div className="pl-7 text-gray-500">
                      Secret Key를 입력해 주세요.
                    </div>
                  </div>
                ) : (
                  <input
                    value={secretKey}
                    onChange={(e) => setSecretKey(e.target.value)}
                    placeholder={
                      secretKeyError || 'Secret Key를 입력해 주세요.'
                    }
                    type="text"
                    className={`bg-white border ${secretKeyError ? 'border-[#E53073] placeholder-[#E53073]' : 'border-[#d8dde3]'} rounded-[5px] flex items-center pl-[25px] h-[38px] w-full text-left ${secretKeyError ? 'text-[#E53073]' : 'text-gray-500'}`}
                  />
                )}
              </div>
              <div className="flex justify-center">
                {!keyConfirmed ? (
                  <button
                    onClick={handleConnectCoupang}
                    className="border-2 h-12 rounded-lg bg-white flex items-center justify-center w-36"
                  >
                    쿠팡 계정 연동
                  </button>
                ) : (
                  <div className="bg-[#BCC2C9] border  rounded-md flex justify-center items-center h-10 w-36">
                    <div className="  text-gray-500">연동 완료</div>
                  </div>
                )}
              </div>
            </div>
            <div className="p-10">
              <div className="text-wrapper-16">쿠팡 인증 가이드</div>
              <div className="font-medium">
                <p className="">
                  아래 가이드 버튼을 통해 연결 가이드를 확인해 보세요
                </p>
              </div>
              <div className="h-4"></div>
              <div className="rounded-md bg-[#F2F4F7] h-10 w-48 gap-2 flex text-center items-center justify-center">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_45_3130"
                    maskUnits="userSpaceOnUse"
                    x="1"
                    y="0"
                    width="14"
                    height="16"
                  >
                    <path d="M14.75 0.25H1.25V15.75H14.75V0.25Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_45_3130)">
                    <path
                      d="M14 1H5V11H14V1Z"
                      stroke="#474A4E"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 11H4C3.46957 11 2.96086 11.2107 2.58579 11.5858C2.21071 11.9609 2 12.4696 2 13C2 13.5304 2.21071 14.0391 2.58579 14.4142C2.96086 14.7893 3.46957 15 4 15H14C13.4564 14.461 13.105 13.7583 13 13"
                      stroke="#474A4E"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.99995 13V2.99995C1.99436 2.73577 2.04227 2.4732 2.1408 2.22802C2.23933 1.98284 2.38645 1.76013 2.57329 1.57329C2.76013 1.38645 2.98284 1.23933 3.22802 1.1408C3.4732 1.04227 3.73577 0.994361 3.99995 0.99995H8.99995"
                      stroke="#474A4E"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>

                <a
                  href={
                    'https://local-room-f00.notion.site/Wing-b31e7e285a5744c599e0332a4b589af3'
                  }
                >
                  <div>쿠팡 연결 가이드</div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full rounded-b-md flex justify-end p-2 gap-2 bg-slate-200">
          <button
            onClick={onCancel}
            className="bg-white border-gray-300 border-2 rounded-md w-[91px] h-[34px]  flex items-center justify-center"
          >
            취소
          </button>
          <button
            onClick={onConnect}
            className={`${keyConfirmed ? ' bg-[#4046E3]' : 'bg-[#BCC2C9]'}  h-[34px] w-[96px] rounded-md text-white flex items-center justify-center`}
          >
            연결하기
          </button>
        </div>
      </div>
    </ModalBase>
  );
};
export default CURegiModal;
