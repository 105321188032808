import React, { useRef,useEffect, useState } from 'react';
import './CustomerStatus.css';
import TabComponent from '../common/TabComponent';
import TittleBar from '../TittleBar';
import SaveAsPDFButton from '../common/SaveAsPDFButton';
import BarChartComponent from '../common/BarChart';
import CBCVTab from '../../CBCVTab';
import { useAuth } from '../Contexts/AuthContext';
import useRESTAPI from '../Contexts/RESTAPI';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { formatNumberWithCommas } from '../common/Auxiliary';
import NoReport from '../common/Norepot';


export const CustomerStatus = (): JSX.Element => {
  const navi = useNavigate()
  const { tab } = useParams();
  const printRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!tab) {
      const searchParams = new URLSearchParams(location.search);
      const searchString = searchParams.toString();
      navi(`/customer-status/all${searchString ? `?${searchString}` : ''}`);
    }
  }, [tab]);

  const { user_id, admin_id } = useAuth();

  const {
    items: requests,
    fetchItems: fetchRequests,
  } = useRESTAPI('/api/cbcv-request');

  const location = useLocation();
  useEffect(() => {
    if (admin_id === undefined)
      return;
    const _ = async () => {
      const id = new URLSearchParams(location.search).get('request_id');
      if (id) {
        const req = await fetchRequests({ request_id: id })
        console.log(req);
        setLastRequests(req[0]);
      }
    }
    _();

  }, [admin_id, tab]);

  const [isRecv, setRecv] = useState<boolean>(false);
  useEffect(() => {

  }, [isRecv]);

  useEffect(()  => {
    const _ = async () => {
      if (user_id === undefined) 
        return;
         const data = await fetchRequests({ cbcv_user_id: user_id, status: 5 });
         console.log();
        if(!data || data.length ===0)
          setRecv(true);
    }
    _();

  }, [user_id, tab]);

  const [lastRequest, setLastRequests] = useState<any>();


  useEffect(() => {

    setLastRequests(requests[requests.length - 1]);
  }, [requests]);

  const [reportData, setReportData] = useState<any>();

  useEffect(() => {

    if (lastRequest === undefined)
      return;
    const url = '/api/cbcv-api/customer_analysis/'
    const _ = async () => {

      const data = {
        user_id: lastRequest.cbcv_user_id.id,
        request_id: lastRequest.request_id,
        channel_param: tab,
      }
      console.log(data);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      const responseData = await response.json();
  
      if(responseData.message === 'there is no report available')
        {
          navi(-1)
          alert('데이터가 존재하지 않습니다')

        }
        else
        {
          setReportData(responseData);
        }
        setRecv(true);
    };
    _();
  }, [lastRequest]);

  if (!reportData) {
    return (
      <div className="flex  text-[#474A4E]">
        <CBCVTab></CBCVTab>
        <div className="BrandAnalysis w-full  min-w-[1200px]">
          <TittleBar tittle="고객 현황 분석" />
          
          {isRecv ? <NoReport></NoReport> :  <div className='flex flex-col gap-11 h-full w-full justify-center items-center'/>}
        </div>
      </div>
    );
  }

  const x = reportData.data.clv_breakdown_chart.map((item: {x:string,y:string}) => item.x);
  console.log(reportData.data.repurchase_breakdown_chart[0]);
  return (
    <div className="flex text-[#474A4E]">
      <CBCVTab></CBCVTab>
      {reportData && <div ref={printRef} className="BrandAnalysis w-full min-w-[1200px]">
        <TittleBar tittle="브랜드 고객 분석" />
        <div className=" flex justify-center item center pt-[34px] ">
          <div className="w-[1100px]">
            <div className="flex justify-between items-end">
              <div className="text-wrapper">고객 현황 분석</div>
           
            <SaveAsPDFButton printRef={printRef} /> 
            </div>
            <div className="h-[45px] "></div>
            <TabComponent
              mainClassName="flex text-[#7B828B] gap-5"
              activeClassName="h-10 px-4 text-[#7B828B] "
              itemClassName="h-10 font-bold px-4 text-[#4046E3] border-b-4 border-[#4046E3] "
              tabs={[
                { title: '전체', link: '/customer-status/all' },
                { title: '쿠팡', link: '/customer-status/coupang' },
                { title: '스마트스토어', link: '/customer-status/naver' },
                { title: '카페24', link: '/customer-status/cafe24' },
              /*   { title: '기타', link: '/customer-status/etc' }, */
              ]}
            ></TabComponent>
            <div className="my-8 h-[1px] bg-gray-200"></div>
            <div className="h-[37px]"></div>
            <div className="text-wrapper-3">고객 분석</div>
            <div className="h-[20px]"></div>
            <div className="flex  rounded-lg border-2 bg-white w-[1094px] h-[114px]">
              <div className="w-1/3 flex flex-col p-5">
                <div className="h-1/2">
                  <div>전체 고객</div>
                </div>
                <div className="flex h-1/2 justify-end items-end">
                  <div className="text-3xl font-bold">{formatNumberWithCommas(reportData.data.total_customer)} </div>
                </div>
              </div>
              <div className="w-1/3 flex flex-col  p-5  border-l-2 border-r-2">
                <div className="h-1/2 ">
                  <div>재구매 고객</div>
                </div>
                <div className="flex h-1/2 justify-end items-end">
                  <div className="text-3xl font-bold">{formatNumberWithCommas(reportData.data.repurchase_customer)}</div>
                </div>
              </div>
              <div className="w-1/3 flex flex-col  p-5">
                <div className="h-1/2">
                  <div>재구매 고객 비율</div>
                </div>
                <div className="flex h-1/2 justify-end items-end">
                  <div className="text-3xl font-bold">{(reportData.data.repurchase_rate*100).batterToFix(2)}%</div>
                </div>
              </div>
            </div>
            <div className="h-[60px]"></div>
            <div className="text-wrapper-3">CLV 20% 매출 비중</div>
            <div className="h-[20px]"></div>
            <div className="w-1/2 h-[88px] border-2 rounded-lg">
              <div className="bg-gray-100 flex h-1/2  w-full  border-gray-950">
                <div className="flex justify-center w-1/3 border-r-2 items-center ">
                  <div className="font-bold">과거 주문 금액 비중</div>
                </div>
                <div className="flex justify-center  w-1/3 border-r-2 items-center ">
                  <div className="font-bold">과거 주문 건수 비중</div>
                </div>
                <div className="flex justify-center w-1/3 border-r-2 items-center">
                  <div className="font-bold">미래 주문 금액 비중</div>
                </div>
              </div>
              <div className="flex h-1/2  w-full  border-gray-100">
                <div className="flex border-r-2  justify-end w-1/3 items-center pl-5 pr-5">
                  <div>{(reportData.data.clv_20p_p_order_amount * 100).batterToFix(2)}%</div>
                </div>
                <div className="flex border-r-2  justify-end w-1/3 items-center pl-5 pr-5">
                  <div>{(reportData.data.clv_20p_p_purchase_count * 100).batterToFix(2)}%</div>
                </div>
                <div className="flex border-r-2  justify-end w-1/3 items-center pl-5 pr-5">
                  <div>{(reportData.data.clv_20p_f_order_amount * 100).batterToFix(2)}%</div>
                </div>
              </div>
            </div>
            <div className="h-[45px]"></div>
            <div className="text-wrapper-3">CLV 분포도</div>
            <div className="h-[20px]"></div>
{/* 
            
  const defaultXTickCallback = (value: any, index: number): string | null => {
    return  labels[value] ;
  }; */}
            <div className="bg-white border border-gray-300 rounded w-[1093px] flex items-center justify-start p-5 ">
              <BarChartComponent xTickCallback={ (value: any, index: number)=> formatNumberWithCommas(reportData.data.clv_breakdown_chart[index].x)} data={reportData.data.clv_breakdown_chart}></BarChartComponent>
            </div>
            <div className="h-[45px]"></div>
            <div className="text-wrapper-3">신규 고객 분포</div>
            <div className="h-[20px]"></div>
            <div className="bg-white border border-gray-300 rounded w-[1093px] flex items-center justify-start p-5 ">
              <BarChartComponent data={reportData.data.new_customer_breakdown_chart}></BarChartComponent>
            </div>
            <div className="h-[45px]"></div>
            <div className="text-wrapper-3">재구매 구간의 분포(일)</div>
            <div className="h-[20px]"></div>
            <div className="w-1/2 h-[88px] border-2 rounded-lg">
              <div className="bg-gray-100 flex h-1/2  w-full  border-gray-950">
                <div className="flex w-1/3 border-r-2 items-center justify-center">
                  <div className="font-bold">평균(일)</div>
                </div>
                <div className="flex w-1/3 border-r-2 items-center justify-center">
                  <div className="font-bold">중간값(일)</div>
                </div>
                <div className="flex w-1/3 border-r-2 items-center justify-center">
                  <div className="font-bold">95% 분위수(일)</div>
                </div>
              </div>
              <div className="flex h-1/2  w-full  border-gray-100">
                <div className="flex border-r-2  justify-end w-1/3 items-center pl-5 pr-5">
                  <div>{reportData.data.repurchase_avg}</div>
                </div>
                <div className="flex border-r-2  justify-end w-1/3 items-center pl-5 pr-5">
                  <div>{reportData.data.repurchase_median}</div>
                </div>
                <div className="flex border-r-2  justify-end w-1/3 items-center pl-5 pr-5">
                  <div>{reportData.data.repurchase_95p}</div>
                </div>
              </div>
            </div>
            <div className="h-[20px]"></div>
            <div className="bg-white border border-gray-300 rounded flex items-center justify-start p-5  w-[1093px] ">
              <BarChartComponent xTickCallback={(value: any, index: number)=> (reportData.data.repurchase_breakdown_chart[index].x)} data={reportData.data.repurchase_breakdown_chart}></BarChartComponent>
            </div>
            <div className='h-32'></div>
          </div>
        </div>

      </div>}
    </div>
  );
};
export default CustomerStatus;
