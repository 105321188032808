import React, { useEffect, useRef, useState } from 'react';
import './ScenarioBasedRevenueForecast.css';
import TableComponent, { TableData, TableColumn } from './TableComponent';
import TittleBar from '../TittleBar';
import SaveAsPDFButton from '../common/SaveAsPDFButton';
import Dropdown from './DropDown';
import CBCVTab from '../../CBCVTab';
import useRESTAPI from '../Contexts/RESTAPI';
import { useAuth } from '../Contexts/AuthContext';
import { formatNumberWithCommas } from '../common/Auxiliary';
import { useLocation, useNavigate } from 'react-router-dom';
import NoReport from '../common/Norepot';
import { set } from 'date-fns';

export const ScenarioBasedRevenueForecast = (): JSX.Element => {
  const { user_id, admin_id } = useAuth();
  const printRef = useRef<HTMLDivElement>(null);
  const [lastRequest, setLastRequests] = useState<any>();
  const [reportData, setReportData] = useState<any>();
  const {
    items: requests,
    fetchItems: fetchRequests,
  } = useRESTAPI('/api/cbcv-request');

  const [purchaseParam, setPurchaseParam] = useState<number>(0);
  const [churnParam, setChurnParam] = useState<number>(0);
  const [acqParam, setAcqParam] = useState<number>(0);
  const [orderParam, setOrderParam] = useState<number>(0);
  const [channel, setChannel] = useState<string>('all');

  const location = useLocation();
 
  useEffect(() => {
    if (admin_id === undefined)
      return;
    const _ = async () => {
      const id = new URLSearchParams(location.search).get('request_id');
      if (id) {
        const req = await fetchRequests({ request_id: id })
        console.log(req);
        setLastRequests(req[0]);
      }
    }
    _();
  }, [admin_id]);

  const [isRecv, setRecv] = useState<boolean>(false);
  useEffect(() => {
  }, [isRecv]);

  useEffect(()  => {
    const _ = async () => {
      if (user_id === undefined) 
        return;
         const data = await fetchRequests({ cbcv_user_id: user_id, status: 5 });
         console.log();
        if(!data || data.length ===0)
          setRecv(true);
    }
    _();

  }, [user_id]);


  useEffect(() => {

    setLastRequests(requests[requests.length - 1]);
  }, [requests]);
 
  useEffect(() => {
    if (lastRequest === undefined)
      return;
    const url = '/api/cbcv-api/brand_scenario_future_revenue/'
    const _ = async () => {
      const data = {
        user_id: lastRequest.cbcv_user_id.id,
        request_id: lastRequest.request_id,
        purchase_param: purchaseParam,
        churn_param: churnParam,
        acq_param: acqParam,
        order_param: orderParam,
        channel_param: channel,
      }
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      const responseData = await response.json();

      if(responseData.message === 'there is no report available')
      {
        alert('데이터가 존재하지 않습니다')
        setChannel('all')

        return;
      }


      const total = {
        revenue: 0,
        profit: 0,
        value: 0
      };
      console.log(responseData.data.scenario_future_revenue);
      responseData.data.scenario_future_revenue.forEach((item: any) => {
        total.revenue += item.revenue;
        total.profit += item.profit;
        total.value += item.value;
      });

      responseData.data.scenario_future_revenue.push({
        "year": "합계",
        "revenue": total.revenue,
        "profit": total.profit,
        "value": total.value
      })
      setRecv(true);
      setReportData(responseData)
    };
    _();
  }, [lastRequest, purchaseParam, churnParam, acqParam, orderParam, channel]);


  const columns: TableColumn[] = [
    { key: 'year', label: '년도', width: 50 },
    { key: 'revenue', label: '시나리오의 예상 연 매출액', width: 100 },
    { key: 'profit', label: '시나리오의 예상 연도별 순익', width: 150 },
    { key: 'value', label: '시나리오의 예상 연도별 가치', width: 150 },
  ];


  const renderCell = (
    rowindex: number,
    rowData: TableData,
    column: TableColumn,
  ) => {
    let border = 'border-b-2';
    let text = '';

    if (column.key === 'year') {
      text = rowData[column.key]
    }
    else {

      text = '₩ ' + formatNumberWithCommas(rowData[column.key])
    }

    let color = ''
    if (rowindex === reportData.data.scenario_future_revenue.length-1)
    {   
      color = 'bg-gray-200'
    }
  
    if (column.key === 'year') {
      return (
        <div
          className={`${border} ${color} flex items-center justify-start w-full  h-[39px] text-sm`}
        >
          {true ? <div className="bg-gray-200 h-full w-[1px]"></div> : <></>}
          <div className="w-full flex justify-center "> {text}</div>
        </div>
      );
    }





    return (
      <div
        className={`${border} ${color} flex items-center justify-start w-full  h-[39px] text-sm`}
      >
        {true ? <div className="bg-gray-200 h-full w-[1px]"></div> : <></>}
        <div className="w-full flex justify-end pr-5"> {text}</div>
      </div>
    );

  };

  const renderCol = (column: TableColumn) => {
    const needLine = column.key !== columns[0].key;

    return (
      <div
        className={`bg-gray-200  border-b-2 flex items-center justify-start w-full  h-[48px] text-sm`}
      >
        {needLine ? <div className="bg-gray-400 h-2/4 w-[1px]"></div> : <></>}


        <div className="flex w-full justify-center">{column.label}</div>
      </div>
    );
  };

  const options = [
    {
      key: '0%',
      label: '기본',
    },
    {
      key: '+10%',
      label: 'UP (+10%)',
    },
    {
      key: '-10%',
      label: 'DOWN (-10%)',
    },
  ];




  const handleDropdown = async (value: string) => { };


  const handlePurchaseParamChange = (value: string) => {
    switch (value) {
      case '0%':
        setPurchaseParam(0);
        break;
      case '+10%':
        setPurchaseParam(1);
        break;
      case '-10%':
        setPurchaseParam(-1);
        break;
    }
  };

  const handleChurnParamChange = (value: string) => {
    switch (value) {
      case '0%':
        setChurnParam(0);
        break;
      case '+10%':
        setChurnParam(1);
        break;
      case '-10%':
        setChurnParam(-1);
        break;

    }
  };

  const handleAcqParamChange = (value: string) => {
    switch (value) {
      case '0%':
        setAcqParam(0);
        break;
      case '+10%':
        setAcqParam(1);
        break;
      case '-10%':
        setAcqParam(-1);
        break;
    }
  };

  const handleOrderParamChange = (value: string) => {
    switch (value) {
      case '0%':
        setOrderParam(0);
        break;
      case '+10%':
        setOrderParam(1);
        break;
      case '-10%':
        setOrderParam(-1);
        break;
    }
  };

  const options2 = [
    {
      key: 'all',
      label: '전체',
    },
    {
      key: 'cafe24',
      label: '카페24',
    },
    {
      key: 'naver',
      label: '스마트 스토어',
    },
    {
      key: 'coupang',
      label: '쿠팡',
    },

  ];
  const handlechannel = (value: string) => {
    setChannel(value)
  };




  if (!reportData) {
    return (
      <div className="flex  text-[#474A4E]">
        <CBCVTab></CBCVTab>
        <div className="BrandAnalysis w-full  min-w-[1200px]">
          <TittleBar tittle='브랜드 가치 분석' />

          {isRecv ? <NoReport></NoReport> : <div className='flex flex-col gap-11 h-full w-full justify-center items-center' />}
        </div>
      </div>
    );
  }


  return (
    <div className="flex text-[#474A4E]">
      <CBCVTab></CBCVTab>
      <div ref = {printRef} className="BrandExpact w-full  min-w-[1200px]">
        <TittleBar tittle='브랜드 가치 분석' />
        {reportData &&
          <div className='flex justify-center items-center'>
            <div className=" pt-[34px] ">
              <div className="  w-[885px]">
                <div className="flex w-[885px] justify-between">
                  <div className="text-wrapper-3 ">브랜드 미래 예상 매출</div>
                  <SaveAsPDFButton printRef={printRef} /> 
                  
                </div>
                <div className="h-[49px]"></div>
                <div className="font-normal pl-5 flex justify-start items-center bg-white border-2 rounded-lg h-14 left-66 top-43 w-[885px]">
                  가치 생성 일자&nbsp;&nbsp;{reportData.data.report_created}
                </div>
                <div className="h-[40px]"></div>
                <div className="text-wrapper-6">시나리오</div>
                <div className="h-[18px]"></div>
                <div className="w-[885px] h-[88px] border-2 rounded-lg">
                  <div className="font-bold bg-gray-100 flex h-1/2  w-full  border-gray-950">
                    <div className="flex justify-center w-1/5 border-r-2 items-center pl-5">
                      <div>구매 확률</div>
                    </div>
                    <div className="flex justify-center w-1/5 border-r-2 items-center pl-5">
                      <div>이탈 확률</div>
                    </div>
                    <div className="flex justify-center w-1/5 border-r-2 items-center pl-5">
                      <div>신규 고객</div>
                    </div>
                    <div className="flex justify-center w-1/5 border-r-2 items-center pl-5">
                      <div>주문 고객</div>
                    </div>
                    <div className="flex justify-center w-1/5 border-r-2 items-center pl-5">
                      <div>채널</div>
                    </div>
                  </div>
                  <div className="flex h-1/2  w-full  border-gray-100">
                    <div className="flex border-r-2  justify-between w-1/5 items-center ">
                      <Dropdown 
                        onOptionChange={handlePurchaseParamChange}
                        selectedOptionKey={'0%'}
                        options={options}
                        buttonClassName="px-4   w-[180px] h-[40px] flex justify-between items-center rounded-lg  "
                        dropdownClassName="flex flex-col border-[1px] border-[#D8DDE3]  w-[180px]  flex justify-between items-start rounded-lg bg-white "
                        itemClassName="px-4  w-full h-[30px]"
                        itemSelectedClassName="px-4 h-[30px] w-full  "
                        itemHoverClassName="px-4 h-[30px] w-full "
                      />
                    </div>
                    <div className="flex border-r-2  justify-between w-1/5 items-center ">
                      <Dropdown
                        onOptionChange={handleChurnParamChange}
                        selectedOptionKey={'0%'}
                        options={options}
                        buttonClassName="px-4   w-[180px] h-[40px] flex justify-between items-center rounded-lg  "
                        dropdownClassName="flex flex-col border-[1px] border-[#D8DDE3]  w-[180px]  flex justify-between items-start rounded-lg bg-white "
                        itemClassName="px-4  w-full h-[30px]"
                        itemSelectedClassName="px-4 h-[30px] w-full  "
                        itemHoverClassName="px-4 h-[30px] w-full "
                      />
                    </div>
                    <div className="flex border-r-2  justify-between w-1/5 items-center ">
                      <Dropdown 
                    
                        onOptionChange={handleAcqParamChange}
                        selectedOptionKey={'0%'}
                        options={options}
                        buttonClassName="px-4   w-[180px] h-[40px] flex justify-between items-center rounded-lg  "
                        dropdownClassName="flex flex-col border-[1px] border-[#D8DDE3]  w-[180px]  flex justify-between items-start rounded-lg bg-white "
                        itemClassName="px-4  w-full h-[30px]"
                        itemSelectedClassName="px-4 h-[30px] w-full  "
                        itemHoverClassName="px-4 h-[30px] w-full "
                      />
                    </div>
                    <div className="flex border-r-2  justify-between w-1/5 items-center ">
                      <Dropdown
                      
                        onOptionChange={handleOrderParamChange}
                        selectedOptionKey={'0%'}
                        options={options}
                        buttonClassName="px-4   w-[180px] h-[40px] flex justify-between items-center rounded-lg  "
                        dropdownClassName="flex flex-col border-[1px] border-[#D8DDE3]  w-[180px]  flex justify-between items-start rounded-lg bg-white "
                        itemClassName="px-4  w-full h-[30px]"
                        itemSelectedClassName="px-4 h-[30px] w-full  "
                        itemHoverClassName="px-4 h-[30px] w-full "
                      />
                    </div>
                    <div className="flex border-r-2  justify-between w-1/5 items-center ">
                      <Dropdown
                          key={channel}
                        onOptionChange={handlechannel}
                        selectedOptionKey={channel}
                        options={options2}
                        buttonClassName="px-4   w-[180px] h-[40px] flex justify-between items-center rounded-lg  "
                        dropdownClassName="flex flex-col border-[1px] border-[#D8DDE3]  w-[180px]  flex justify-between items-start rounded-lg bg-white "
                        itemClassName="px-4  w-full h-[30px]"
                        itemSelectedClassName="px-4 h-[30px] w-full  "
                        itemHoverClassName="px-4 h-[30px] w-full "
                      />
                    </div>
                  </div>
                </div>
                <div className="h-[49px]"></div>
                <div className="text-wrapper-8">가치 산출 가정</div>
                <div className="h-[18px]"></div>
                <div className="flex rounded-lg border-2 bg-white w-[885px] h-[114px]">
                  <div className="w-1/2 flex justify-between items-center  p-5  ">
                    <div>비용 비율</div>
                    <div className="text-3xl font-bold">{reportData.data.cost_sga_rate}%</div>
                  </div>
                  <div className="h-full w-0.5 bg-slate-200"></div>
                  <div className="w-1/2 flex justify-between items-center  p-5 ">
                    <div>가중 평균 자본 비용</div>
                    <div className="text-3xl font-bold">{reportData.data.capital_cost_rate}%</div>
                  </div>
                </div>
                <div className="flex  w-[885px] justify-end">
                  <div className="pt-3">
                    *비용 데이터를 입력하시면 정확한 가치를 산출할 수 있습니다.
                  </div>
                </div>
                <div className="h-[49px]"></div>
                <div className="text-wrapper-9">시나리오 최종 결과</div>
                <div className="h-[18px]"></div>
                <div className="flex rounded-lg border-2 bg-white w-[885px] h-[114px]">
                  <div className="w-full flex justify-between items-center  p-5 ">
                    <div>회사 가치</div>
                    <div className="text-3xl font-bold">₩ {formatNumberWithCommas(reportData.data.company_value)}</div>
                  </div>
                </div>
                <div className="h-[49px]"></div>
                <div className="text-wrapper-7">매출, 순익 및 가치</div>
                <div className="h-[18px]"></div>
                <TableComponent
                  columns={columns}
                  data={reportData.data.scenario_future_revenue}
                  className="border-2 rounded-lg w-[885px]"
                  renderCell={renderCell}
                  renderColumn={renderCol}
                />
              </div>
            </div>
          </div>}
        <div className='h-32'></div>
      </div>

    </div>
  );
};
