import React, { ReactNode, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../Contexts/AuthContext';
interface RequireAuthProps {
  children: ReactNode;
}

const RequireAuth = ({ children }: RequireAuthProps) => {
  const [isSignedIn, setIsSignedIn] = useState<boolean | undefined>(undefined);
  const { user_id } = useAuth();

  const fetchData = async () => {
    try {
      const response = await fetch('/api/auth/readSession');

      if (response.ok) {
        const data = await response.json();
        
        setIsSignedIn(true);
      } else {
        setIsSignedIn(false);
      }
    } catch (error) {
      setIsSignedIn(false);
    }
  };

  useEffect(() =>  {
    if (!user_id) {
      fetchData();
    } else {
      setIsSignedIn(true);
    }
  }, []);
  
  useEffect(() =>  {
  
  }, [isSignedIn]);


  if (isSignedIn === false) 
  {
/*     return <>{children}</>; */
    return <Navigate to="/login" replace />;
  } 
  else if (isSignedIn === true) 
  {
    return <>{children}</>;
  }
  
  return <></>;
};

export default RequireAuth;
