import {MainHeaderContent, MainHeaderInner, MainHeaderWrapper} from "../styles/header.js";
import ImgLogo from "../imgs/logo.svg";
import ImgIconGlobe from "../imgs/globe.svg";
import {Button} from "../styles/btn.js";
import {LangContext} from "../Contexts/LangContext.jsx";
import {useContext} from "react";

export default function MainHeader() {
    const { lang, setLang, t } = useContext(LangContext);
    
    return (
        <MainHeaderWrapper>
            <MainHeaderInner>
                <MainHeaderContent>
                    <h1 className="__brand"><a href="#"><img src={ImgLogo} alt="OMOVALUE"/></a></h1>
                    <nav>
                        <div className="__langs">
                            <img src={ImgIconGlobe} alt="Languages"/>
                            <a href="#" onClick={ () => setLang('ko')} className={lang  === 'ko' ? `__active` : undefined}>ko</a>
                            <a href="#" onClick={ () => setLang('en')} className={lang  === 'en' ? `__active` : undefined}>en</a>
                        </div>
                        <a href="/login">{t('login')}</a>
                        <Button href="#inquiry" className={'__cta __accent'}>
                            <span className="__d">{t('applyFree')}</span>
                            <span className="__m">{t('apply')}</span>
                        </Button>
                    </nav>
                </MainHeaderContent>

            </MainHeaderInner>
        </MainHeaderWrapper>
    )
}