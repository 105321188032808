/* import { useState, useEffect, useCallback } from 'react';
import { json } from 'stream/consumers';

const getAllItems = async (endpoint: string) => {
  const response = await fetch(endpoint);
  if (!response.ok) {
    throw new Error('Failed to fetch items');
  }
  const data = await response.json();
  return data;
};

const getOneItem = async (endpoint: string, id: number) => {
  const response = await fetch(`${endpoint}/${id}`);
  if (!response.ok) {
    throw new Error(`Failed to fetch item with id ${id}`);
  }
  const data = await response.json();
  return data;
};

const createItem = async (endpoint: string, data: any) => {
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error('Failed to create item');
  }
  const newItem = await response.json();
  return newItem;
};

const updateItem = async (endpoint: string, id: number, data: any) => {
  data.id = id;
  const response = await fetch(endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error(`Failed to update item with id ${id}`);
  }
  const updatedItem = await response.json();
  return updatedItem;
};

const deleteItem = async (endpoint: string, id: number) => {

  const body = JSON.stringify({id})


  const response = await fetch(endpoint, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  });
  const data = await response.json();
  (data); 
  if (!response.ok) {
    throw new Error(`Failed to delete item with id ${id}`);
  }
};

export function buildQueryParams(params: { [key: string]: any }): string {
  const queryParams = new URLSearchParams();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      queryParams.append(key, params[key]);
    }
  }
  return queryParams.toString();
}

const useRESTAPI = (endpoint: string) => {
  const [items, setItems] = useState<any[]>([]);
  const [hasFetchedSuccessfully, setHasFetchedSuccessfully] = useState(false);
  useEffect(() => {
    (items);
  }, [items]);

  const fetchItems = useCallback(async (query: { [key: string]: any }) => {
    try {
      const queryParams = buildQueryParams(query);

      const data = await getAllItems(endpoint +'?'+ queryParams);
      setItems(prevItems => data.data);
      setHasFetchedSuccessfully(true);
      (data);
      return data.data;
    } catch (error) {
      console.error(error);
    }
  }, [endpoint]);

  const fetchItem = useCallback(async (id: number) => {
    try {
      const item = await getOneItem(endpoint, id);
      setHasFetchedSuccessfully(true);
      return item;
    } catch (error) {
      console.error(error);
      return null;
    }
  }, [endpoint]);

  const addItem = useCallback(async (item: Omit<any, 'id'>) => {
    try {
      const newItem = await createItem(endpoint, item);
      setItems(prevItems => [...prevItems, newItem]);
      setHasFetchedSuccessfully(true);
      return newItem;
    } catch (error) {
      console.error(error);
      return null;
    }
  }, [endpoint]);

  const editItem = useCallback(async (id: number, item: Omit<any, 'id'>) => {
    try {
      const updatedItem = await updateItem(endpoint, id, item);
      setItems(prevItems => prevItems.map((i) => (i.id === id ? updatedItem : i)));
      setHasFetchedSuccessfully(true);
      return updatedItem;
    } catch (error) {
      console.error(error);
      return null;
    }
  }, [endpoint]);

  const removeItem = useCallback(async (id: number) => {
    try {
      await deleteItem(endpoint, id);
      setItems(prevItems => prevItems.filter((i) => i.id !== id));
      setHasFetchedSuccessfully(true);
    } catch (error) {
      console.error(error);
    }
  }, [endpoint]);

  

  return { items, fetchItems, fetchItem, addItem, editItem, removeItem,hasFetchedSuccessfully  };
};

export default useRESTAPI;
 */import { useState, useEffect, useCallback } from 'react';

const getAllItems = async (endpoint: string) => {
  const response = await fetch(endpoint);
  if (!response.ok) {
    throw new Error('Failed to fetch items');
  }
  const data = await response.json();
  return data;
};

const getOneItem = async (endpoint: string, id: number) => {
  const response = await fetch(`${endpoint}/${id}`);
  if (!response.ok) {
    throw new Error(`Failed to fetch item with id ${id}`);
  }
  const data = await response.json();
  return data;
};

const createItem = async (endpoint: string, data: any) => {
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error('Failed to create item');
  }
  const newItem = await response.json();
  return newItem;
};

const updateItem = async (endpoint: string, id: number, data: any) => {
  data.id = id;
  console.log(data);
  const response = await fetch(endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error(`Failed to update item with id ${id}`);
  }
  const updatedItem = await response.json();
  return updatedItem;
};

const deleteItem = async (endpoint: string, id: number) => {
  const body = JSON.stringify({ id });

  const response = await fetch(endpoint, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(`Failed to delete item with id ${id}`);
  }
};

export function buildQueryParams(params: { [key: string]: any }): string {
  const queryParams = new URLSearchParams();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      queryParams.append(key, params[key]);
    }
  }
  return queryParams.toString();
}

const useRESTAPI = (endpoint: string) => {
  const [items, setItems] = useState<any[]>([]);
  const [hasFetchedSuccessfully, setHasFetchedSuccessfully] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    console.log(items);
  }, [items]);

  const fetchItems = useCallback(async (query: { [key: string]: any }) => {
    setIsFetching(true);
    try {
      const queryParams = buildQueryParams(query);
      const data = await getAllItems(endpoint + '?' + queryParams);
      setItems(data.data);
      setHasFetchedSuccessfully(true);
      return data.data;
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  }, [endpoint]);

  const fetchItem = useCallback(async (id: number) => {
    setIsFetching(true);
    try {
      const item = await getOneItem(endpoint, id);
      setHasFetchedSuccessfully(true);
      return item;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      setIsFetching(false);
    }
  }, [endpoint]);

  const addItem = useCallback(async (item: Omit<any, 'id'>) => {
    setIsFetching(true);
    try {
      const newItem = await createItem(endpoint, item);
      setItems(prevItems => [...prevItems, newItem]);
      setHasFetchedSuccessfully(true);
      return newItem;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      setIsFetching(false);
    }
  }, [endpoint]);

  const editItem = useCallback(async (id: number, item: Omit<any, 'id'>) => {
    setIsFetching(true);
    try {
      console.log(item);
      const updatedItem = await updateItem(endpoint, id, item);
      setItems(prevItems => prevItems.map((i) => (i.id === id ? updatedItem : i)));
      setHasFetchedSuccessfully(true);
      return updatedItem;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      setIsFetching(false);
    }
  }, [endpoint]);

  const removeItem = useCallback(async (id: number) => {
    setIsFetching(true);
    try {
      await deleteItem(endpoint, id);
      setItems(prevItems => prevItems.filter((i) => i.id !== id));
      setHasFetchedSuccessfully(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  }, [endpoint]);

  return { items, fetchItems, fetchItem, addItem, editItem, removeItem, hasFetchedSuccessfully, isFetching };
};

export default useRESTAPI;
