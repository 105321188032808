import styled from "styled-components";
import {DEFAULT_FONT_SIZE, BREAKPOINT, COLOR_ACCENT} from "./vars.js";

export const Button = styled.a`
    font-size: ${18/DEFAULT_FONT_SIZE}em;
    padding: 0 ${24/DEFAULT_FONT_SIZE}em;
    height: max(32px, ${46/DEFAULT_FONT_SIZE}em);
    gap: ${6/DEFAULT_FONT_SIZE}em;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 1px solid #D6D6DE;
    border-radius: 2em;
    font-weight: 700;
    letter-spacing: -0.03em;
    line-height: ${24/DEFAULT_FONT_SIZE}em;
    box-sizing: border-box;
    color: inherit;
    i {
        img {
            display: block;
            width: 1.2em;
            height: auto;
        }
    }
    &.__accent {
        background: linear-gradient(90deg, #5368DE 0%, #A44680 100%);
        border: 0;
        color: #fff;
    }
    
    &.__white {
        background: #fff;
        color: ${COLOR_ACCENT};
    }
    
    &.__lg {
        font-size: ${20/DEFAULT_FONT_SIZE}em;
        height: ${68/20}em;
        padding: 0 ${40/20}em;
        gap: ${10/DEFAULT_FONT_SIZE}em;
    }
`;