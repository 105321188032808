import styled from "styled-components";
import {MainContainer} from "./common.js";
import {DEFAULT_FONT_SIZE, BREAKPOINT, COLOR_ACCENT} from "./vars.js";

export const MainKVSectionContainer = styled(MainContainer)`
    //max-width: 1740px;
`;

export const MainKVContent = styled.div`
    display: flex;
    padding: ${220/DEFAULT_FONT_SIZE}em 0;
    gap: ${26/DEFAULT_FONT_SIZE}em;
    align-items: center;
    @media screen and (max-width: ${BREAKPOINT.TABLET}px) {
        flex-direction: column;
        padding: ${160/DEFAULT_FONT_SIZE}em 0;

    }
    @media screen and (max-width: ${BREAKPOINT.MOBILE}px) {
        padding: ${120/DEFAULT_FONT_SIZE}em 0;

    }
`;
export const MainKVDesc = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${38/DEFAULT_FONT_SIZE}em;
    flex: 1;
    h2 {
        color: #000;
        strong {
            color: ${COLOR_ACCENT};
        }
    }
    
    
    .__cta {
        margin-top: ${12/DEFAULT_FONT_SIZE}em;
        display: flex;
    }
    @media screen and (max-width: ${BREAKPOINT.TABLET}px) {
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-bottom: ${60/DEFAULT_FONT_SIZE}em;
    }
`;

export const MainKVImg = styled.div`
    flex: 1;
    img {
        display: block;
        width: 100%;
        transform-origin: 60% 50%;
        transform: scale(1.125);
        min-width: 500px;
        position: relative;
        left: 0;
    }
    @media screen and (max-width: ${BREAKPOINT.TABLET}px) {
        img {
            transform: none;
            max-width: 640px;
            min-width: 240px;
        }
    }
`;