import React, { createContext, useState } from 'react';
import {translations} from "../../langs/translations.js";
import {MainWrapper} from "../styles/common.js";

export const LangContext = createContext();

export const LangProvider = ({ children }) => {
    const [lang, setLang] = useState('ko');
    const t = (key) => translations[lang][key] || key;
    return (
        <LangContext.Provider value={{ lang, setLang, t }}>
            <div id="langProvider">
                <MainWrapper data-lang={lang}>
                    {children}
                </MainWrapper>
            </div>
        </LangContext.Provider>
    );
};