import MainHeader from "../layout/Header";
import MainFooter from "../layout/Footer";

import {LangProvider} from "../Contexts/LangContext";
import MainSections from "../MainSections.jsx";
export default function Main() {

    return (
        <LangProvider>
            <MainHeader />
            <MainSections />
            <MainFooter />
        </LangProvider>
    )
}