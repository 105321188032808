import React from 'react';

const PrivacyPolicy: React.FC = () => {
    const txt= `헤드리스(주)(이하 “회사”라 함)가 제공하는 오모밸류(이하 ‘서비스’라 합니다)는 이용자의 개인정보보호를 소중하게 생각하고, 이용자의 개인정보를 보호하기 위하여 노력하고 있습니다.

1. 총칙
(1) "개인정보"라 함은 생존하는 개인에 관한 정보로서 당해 정보에 포함되어 있는 당해 개인을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)를 말합니다.
(2) 회사는 개인정보보호를 가장 소중하게 생각하며, 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 “정보통신망법”이라 함) 및 개인정보보호법을 비롯한 모든 개인정보보호 관련 법률규정을 준수하고 있습니다.
(3) 회사는 본 개인정보보호정책을 통하여 이용자들이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
(4) 본 서비스의 개인정보취급방침은 관련 법률 및 관련 규정의 변경 또는 내부 운영 방침의 변경에 따라 변경될 수 있습니다. 개인정보취급방침이 변경될 경우 변경된 사항을 홈페이지를 통하여 공지함으로써 개정된 내용을 이용자가 쉽게 알아보실 수 있도록 하고 있습니다.

2. 개인정보의 수집 항목 및 이용 목적
회사는 서비스의 원활한 제공을 위해 다음의 정보를 필수적으로 수집, 이용하고 있습니다.
이용 목적에 따른 수집 항목
(1) 서비스 제공을 위한 본인확인 및 사이트 등록
(2) 아이디, 비밀번호, 성명, 회사명, 이메일, 연락처(일반유선전화번호 또는 휴대폰번호), 사이트 URL
(3) 서비스 문의에 대한 고객 응대를 위한 이용자의 이메일, 연락처, 사이트 URL

3. 개인정보 수집에 대한 동의
이용자가 본 개인정보 취급방침의 내용에 대하여 “동의합니다” 버튼을 누르면, 개인정보 수집에 대해 동의한 것으로 간주됩니다.

4. 개인정보의 수집 방법
회사는 이용자에게 서비스를 제공하기 위한 필수적인 정보들을 제공 받고 있습니다. 홈페이지의 가입 절차, 상담/문의 절차, 인터넷 서비스 사용시 생성정보 수집 툴을 통한 방법(쿠키) 등으로 개인 정보를 수집합니다.

5. 개인정보 자동 수집 장치의 설치·운영 및 거부
회사는 이용자 개개인에게 개인화되고 맞춤화된 서비스를 제공하기 위해 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.
(1) 쿠키의 사용 목적
회원과 비회원의 접속 빈도나 방문 시간 등의 분석, 이용자의 취향과 관심분야의 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공
(2) 쿠키 설정 거부 방법
이용자는 쿠키 설치에 대해 거부할 수 있습니다. 단, 쿠키 설치를 거부하였을 경우 로그인이 필요한 일부 서비스의 이용이 어려울 수 있습니다.
가. 웹 브라우저
Chrome: 메뉴 > 설정 > 개인정보 및 보안
Edge: 설정 및 기타 항목 > 설정 > 사이트 사용 권한 > 쿠키 및 사이트 데이터
Safari: 환경설정 > 크로스 사이트 추적 방지 및 모든 쿠키 차단
이 외에도 Firefox, Opera 등 주요 인터넷 웹브라우저들도 쿠키 삭제 기능을 제공하고 있습니다.
나. 모바일
Android: 홈 > 설정 > Google > 광고 > 광고 맞춤설정 선택 해제 (ON)
iPhone: 홈 > 설정 > 개인 정보 보호 > 추적 > 앱이 추적을 요청하도록 허용 (OFF)

6. 수집한 개인정보의 이용
(1) 회원 가입 의사의 확인, 이용자의 본인 확인, 이용자 식별, 회원탈퇴 의사의 확인 등 회원관리를 위하여 개인정보를 이용합니다.
(2) 유료 서비스 제공에 따르는 본인인증, 요금 결제를 위하여 개인정보를 이용합니다.
(3) 이벤트 정보 및 참여기회 제공, 광고성 정보 제공 등 마케팅 및 프로모션 목적으로 개인정보를 이용합니다.
(4) 서비스 이용기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스 분석 및 통계에 따른 맞춤 서비스 제공 및 광고 게재 등에 개인정보를 이용합니다.
(5) 보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는 서비스 이용환경 구축을 위해 개인정보를 이용합니다.

7. 개인정보의 제 3자 제공
회사는 원칙적으로 개인정보를 타인 또는 타기업,기관 등 제 3자에게 제공하지 않습니다. 단, 이용자가 개인정보 제공에 직접 동의를 하였거나 다음에 해당하는 경우는 예외로 합니다.
(1) 서비스의 제공에 관한 계약의 이행을 위하여 필요한 개인정보로서 경제적, 기술적인 사유로 통상의 동의를 받는 것이 현저히 곤란한 경우
(2) 서비스 제공에 따른 요금정산을 위하여 필요한 경우
(3) 통계작성/학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우

8. 개인정보의 안전성 확보조치
회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
– 관리적 조치 : 내부관리계획 수립·시행, 정기적 직원 교육
– 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 개인정보의 암호화, 보안프로그램 설치 및 갱신
– 물리적 조치 : IDC등의 접근 통제

9. 개인정보의 보유 및 이용기간
(1) 이용자가 회사에서 제공하는 서비스를 이용하는 동안 회사는 이용자의 개인정보를 지속적으로 보유하며 편리한 서비스의 제공을 위한 기초 자료로 이용합니다.다만 이용자 본인이 개인정보를 변경하거나 삭제 요청을 하는 경우는 회사 정책에 따라서 삭제하여 추후 열람이나 이용이 불가능한 상태로 처리하고 있습니다.
(2) 회사는 부정한 방법으로 서비스를 이용한 회원의 재발 방지를 위해, 이용계약 해지일로부터 1년간 해당 회원의 개인정보를 보유할 수 있습니다. 그리고 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 다른 개인정보와 분리하여서 저장 및 관리합니다. 보존기간은 아래와 같습니다.
– 계약 또는 청약철회 등에 관한 기록
– 보존이유 : 전자상거래 등에서의 소비자보호에 관한 법률
– 보존기간 : 5년
– 대금결제 및 재화 등의 공급에 관한 기록
– 보존이유 : 전자상거래 등에서의 소비자보호에 관한 법률
– 보존기간 : 5년
– 소비자의 불만 또는 분쟁처리에 관한 기록
– 보존이유 : 전자상거래 등에서의 소비자보호에 관한 법률
– 보존기간 : 3년

12. 개인정보 파기 절차 및 방법
회사는 수집한 개인정보의 이용목적이 달성된 후에는 보관기간 및 이용기간에 따라 해당 정보를 지체 없이 파기합니다. 파기절차, 방법, 시점은 다음과 같습니다.
(1) 파기절차 및 시점
이용자가 서비스 가입 등을 위해 기재한 개인정보는 서비스 해지 등 이용목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유(위 개인정보의 보유 및 이용기간 참조)에 따른 보유기간이 경과한 후에 삭제되거나 파기합니다. 일반적으로 잔존하는 채권, 채무 관계가 없는 경우 본 서비스 회원가입 시 수집되어 전자적 파일형태로 관리하는 개인정보는 회원 탈퇴 시점에 바로 삭제 됩니다.
(2) 파기방법
종이에 출력된 개인정보는 분쇄기로 분쇄 또는 소각하여 파기하고, 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.

13. 개인정보취급방침의 고지 또는 통지방법
(1) 회사는 이용자가 동의한 범위를 넘어 개인정보를 이용하거나 제3자에게 제공하기 위해 추가적인 동의를 얻고자 하는 때에는 미리 개별적으로 서면, E-mail, 전화, 인터넷 홈페이지 등에 개재하는 등으로 해당사항을 통지하고 이에 대한 동의를 얻습니다.
(2) 타인에게 이용자의 개인정보의 수집, 보관, 처리, 이용, 제공, 관리, 파기 등을 위탁하는 경우에는 홈페이지의 개인정보취급방침 등을 통하여 인터넷 홈페이지에 공개합니다.

14. 개인정보보호를 위한 기술 및 관리적 대책
회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고, 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.
그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려고 노력하고 있습니다. 회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시교육을 통하여 개인정보보호정책의 준수를 강조하고 있습니다.
회사는 이용자 부주의로 인한 개인정보의 유출에 대해서는 책임을 지지 않으므로 이용자 본인의 개인정보보호를 위해 노력을 기울여주시길 바랍니다.

15. 개인정보 관련 의견 수렴 및 불만처리에 관한 사항
회사는 개인정보보호와 관련하여 이용자의 의견을 수렴하고 있으며 불만을 처리하기 위하여 모든 절차와 방법을 마련하고 있습니다. 이용자는 회사의 개인정보 관리 책임자 및 담당자에게 불만사항을 신고할 수 있으며, 회사는 이용자의 신고사항에 대하여 신속하고도 충분한 답변을 해 드릴 것입니다. 또 정부에서 설치하여 운영 중인 아래의 기관에 불만을 처리할 수 있습니다.
가. 개인정보침해신고센터
– 전화: 118
– URL: http://privacy.kisa.or.kr
나. 대검찰청 사이버범죄수사단
– 전화: 02-3480-3571
– E-mail: cybercid@spo.go.kr
다. 경찰청 사이버테러대응센터
– 전화: 1566-0112
– URL: http://www.netan.go.kr

16. 개인정보관리 책임자 및 담당자
(1) 회사는 이용자의 개인정보보호를 매우 소중하게 생각하며, 개인정보가 훼손, 침해 또는 누설되지 않도록 최선을 다하고 있습니다. 그러나 기술적인 보완조치를 했음에도 불구하고, 해킹 등 기본적인 네트워크상의 위험성에 의해 발생하는 예기치 못한 사고로 인한 정보의 훼손 및 방문자가 작성한 게시물에 의한 각종 분쟁에 관해서는 책임이 없습니다.
(2) 이용자의 개인정보보호 관련 문의 시 고객센터에서 신속하고 성실하게 답변을 드리도록 하고 있습니다. 또한 본 서비스의 개인정보보호 담당자와 연락을 원하실 경우 아래의 E-mail로 연락을 주시면 개인정보 관련 문의사항에 대하여 신속하고 성실하게 답변해 드리겠습니다.
개인정보관리책임자
– 성명: 남궁지환
– 소속/직위 : 경영/대표
– 이메일 : contact@headless.co.kr

17. 고지의무
정부의 정책 또는 보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 경우에는 개정 최소 7일 전부터 홈페이지의 ‘공지’를 통해 고지합니다.

(시행일)
이 개인정보보호정책은 2024 년 7월 1일부터 적용됩니다.


`
    return (
        <div className="pt-32 flex justify-center item-center">
            <div>
                <img  className='h-8' src="logo-b.png" ></img>
                <h2 className="text-xl">개인정보처리방침</h2>
                <div className='h-8'></div>
                <div className="bg-white overflow-y-scroll text-sm border-[1px] w-[661px] h-[581px] overflow-hi p-6 rounded-lg ">
                    <div style={{whiteSpace:'pre-line'}}>
                        {txt}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
