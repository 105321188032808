import {MainSection, MainSectionContainer, MainSectionHeading, MainSectionInner} from "../styles/section.js";
import {MainPartnersContent, MainPartnersList} from "../styles/partners.js";

import ImgPartner1 from "../imgs/partner1.png";
import ImgPartner2 from "../imgs/partner2.png";
import ImgPartner3 from "../imgs/partner3.png";
import ImgPartner4 from "../imgs/partner4.png";
import ImgPartner5 from "../imgs/partner5.png";
import {LangContext} from "../Contexts/LangContext.jsx";
import {useContext} from "react";

export default function MainPartners() {
    const {t} = useContext(LangContext);
    return (
        <MainSection>
            <MainSectionInner>
                <MainPartnersContent>
                    <MainSectionContainer>
                        <MainSectionHeading data-anims>
                            <h2 className="__title __size-xl" data-anim dangerouslySetInnerHTML={{__html:t('partnersTitle')}}></h2>
                        </MainSectionHeading>
                    </MainSectionContainer>
                    <MainPartnersList data-anims>
                        <div className="__item" data-anim><img src={ImgPartner1} alt="현대기술투자" style={{aspectRatio: '768/454'}}/></div>
                        <div className="__item" data-anim><img src={ImgPartner2} alt="D.CAMP 은행청년창업재단" style={{aspectRatio: '768/454'}}/></div>
                        <div className="__item" data-anim><img src={ImgPartner3} alt="서울경제진흥원" style={{aspectRatio: '768/454'}}/></div>
                        <div className="__item" data-anim><img src={ImgPartner4} alt="The Invention Lab" style={{aspectRatio: '768/454'}}/></div>
                        <div className="__item" data-anim><img src={ImgPartner5} alt="TIPS KOREA" style={{aspectRatio: '768/454'}}/></div>
                    </MainPartnersList>
                </MainPartnersContent>
            </MainSectionInner>
        </MainSection>
    )
}