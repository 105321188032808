import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { format } from 'date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

interface BarChartComponentProps {
  data: { x: any; y: any }[];
  xTickCallback?: (value: any, index: number, values: any[]) => any;
}

const BarChartComponent: React.FC<BarChartComponentProps> = ({ data, xTickCallback }) => {
  const labels = data.map(item => item.x.toString());
  const chartData = data.map(item => item.y);

  const transformedData = {
    labels,
    datasets: [
      {
        label: '값',
        data: chartData,
        backgroundColor: '#FFCC6E',
        borderColor: '#FFCC6E',
        borderWidth: 1,
      },
    ],
  };

  const defaultXTickCallback = (value: any, index: number): string | null => {
    return  labels[value] ;
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top' as const,
      },
      title: {
        display: false,
        text: 'Chart Title',
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          callback: xTickCallback || defaultXTickCallback,
        },
      },
      y: {
        display: true,
      },
    },
    barPercentage: 1.0,
    categoryPercentage: 1.0,
  };

  return <Bar data={transformedData} options={options} />;
};

export default BarChartComponent;
