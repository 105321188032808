import React, { useEffect, useRef, useState } from 'react';
import './FutureRevenueForecast.css';
import TableComponent, { TableData, TableColumn } from './TableComponent';
import Pagination from 'react-js-pagination';
import './Paging.css';
import TittleBar from '../TittleBar';
import SaveAsPDFButton from '../common/SaveAsPDFButton';
import CBCVTab from '../../CBCVTab';
import { useAuth } from '../Contexts/AuthContext';
import useRESTAPI from '../Contexts/RESTAPI';
import { formatNumberWithCommas, removeDecimal } from '../common/Auxiliary';
import { useLocation } from 'react-router-dom';
import NoReport from '../common/Norepot';
import { set } from 'date-fns';

export const FutureRevenueForecast = (): JSX.Element => {

  const { user_id ,admin_id} = useAuth();
  const printRef = useRef<HTMLDivElement>(null);

  const [lastRequest, setLastRequests] = useState<any>();
  const [reportData, setReportData] = useState<any>();
  const {
    items: requests,
    fetchItems: fetchRequests,
  } = useRESTAPI('/api/cbcv-request');

  
  const location = useLocation();
  useEffect(() => {
    if (admin_id === undefined)
      return;
    const _ = async () => {
      const id = new URLSearchParams(location.search).get('request_id');
      if(id){
        const req = await fetchRequests({request_id:id})
        console.log(req);
        setLastRequests(req[0]);
      }
    }
    _();
  }, [admin_id]);
  

  const [isRecv, setRecv] = useState<boolean>(false);
  useEffect(() => {
  }, [isRecv]);

  useEffect(()  => {
    const _ = async () => {
      if (user_id === undefined) 
        return;
         const data = await fetchRequests({ cbcv_user_id: user_id, status: 5 });
         console.log();
        if(!data || data.length ===0)
          setRecv(true);
    }
    _();

  }, [user_id]);

  useEffect(() => {

    setLastRequests(requests[requests.length - 1]);
  }, [requests]);

  useEffect(() => {
    if (lastRequest === undefined)
      return;
    const url = '/api/cbcv-api/brand_future_revenue/'
    const _ = async () => {
      const data = {
        user_id: lastRequest.cbcv_user_id.id,
        request_id: lastRequest.request_id,
      }
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      /* 
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
       */
      const responseData = await response.json();
      setRecv(true);
      setReportData(responseData)
    };
    _();
  }, [lastRequest]);

  useEffect(() => {
    console.log(reportData);
  }, [reportData]);

  const columns: TableColumn[] = [
    { key: 'year', label: '년도', width: 100 },
    { key: 'total', label: '총액', width: 100 },
    { key: 'profit', label: '세전 이익', width: 100 },
    { key: 'value', label: '가치', width: 100 },
    { key: 'coupang', label: '쿠팡(유입기준)', width: 100 },
    { key: 'naver', label: '스마트 스토어', width: 100 },
    { key: 'cafe24', label: '카페24', width: 100 },
  
  ];


  const renderCell = (
    rowindex: number,
    rowData: TableData,
    column: TableColumn,
  ) => {
    let text = '';
    let border = 'border-b-2';

    if (column.key === 'year') {
      text = rowData[column.key];
      return (
        <div
          className={`${border} flex items-center justify-start w-full  h-[39px]`}
        >
          {true ? <div className="bg-gray-200 h-full w-[1px]"></div> : <></>}
          <div className="w-full flex justify-center"> {text}</div>
        </div>
      );
    }
    else {

      const value = formatNumberWithCommas(rowData[column.key])
      if(value){
        text = '₩ ' + value;
      }
      else{       text = '₩ ' + '0';}
    }

 
    return (
      <div
        className={`${border} flex items-center justify-start w-full  h-[39px]`}
      >
        {true ? <div className="bg-gray-200 h-full w-[1px]"></div> : <></>}
        <div className="w-full flex justify-end pr-5"> {text}</div>
      </div>
    );
  };

  const renderCol = (column: TableColumn) => {
    const needLine = column.key !== columns[0].key;

    return (
      <div
        className={`bg-gray-200  border-b-2 flex items-center  w-full  h-[48px]`}
      >
        {needLine ? <div className="bg-gray-400 h-2/4 w-[1px]"></div> : <></>}
    
        <div className="flex w-full justify-center">{column.label}</div>
      </div>
    );
  };

  
  
  if (!reportData) {
    return (
      <div className="flex  text-[#474A4E]">
        <CBCVTab></CBCVTab>
        <div className="BrandAnalysis w-full  min-w-[1200px]">
        <TittleBar  tittle='브랜드 가치 분석' />
          
          {isRecv ? <NoReport></NoReport> :  <div className='flex flex-col gap-11 h-full w-full justify-center items-center'/>}
        </div>
      </div>
    );
  }

  return (
    <div className="flex text-[#474A4E]">
      <CBCVTab></CBCVTab>
      {reportData &&
        <div ref={printRef} className="BrandFurture w-full  min-w-[1200px]">
          <TittleBar tittle="브랜드 가치 분석" />
          <div className='flex justify-center items-center'>
            <div className=" pt-[34px] ">
              <div className="w-[1095px] flex justify-between items-end">
                <div className="text-wrapper-9 ">브랜드 미래 예상 매출 </div>
              
               <SaveAsPDFButton printRef={printRef} />
              </div>
              <div className="h-[49px]"></div>
              <div className="w-[1095px] font-normal pl-5 flex justify-start items-center bg-white border-2 rounded-lg h-14 left-66 top-43 ">
                가치 생성 일자&nbsp;&nbsp;{reportData.data.report_created}
              </div>
              <div className="h-[18px]"></div>
              <div className="h-[49px]"></div>
              <TableComponent
                columns={columns}
                data={reportData.data.future_revenue}
                className="w-[1095px] border-2 rounded-lg"
                renderCell={renderCell}
                renderColumn={renderCol}
              />
            </div>
          </div>
        </div>}
    </div>
  );
};
export default FutureRevenueForecast;
