import styled from "styled-components";
import {MainSection} from "./section.js";
import {DEFAULT_FONT_SIZE, BREAKPOINT, COLOR_ACCENT} from "./vars.js";
import ImgArrow from "../imgs/arrow.svg";
export const MainSummarySection = styled(MainSection)`
    background: #F4F7F9;
    padding: ${140/DEFAULT_FONT_SIZE}em 0;
`;

export const MainSummaryDesc = styled.div`
    .__title {
        display: flex;
        font-size: clamp(${22 / DEFAULT_FONT_SIZE}em, ${32 / 1024 * 100}vw, ${32 / DEFAULT_FONT_SIZE}em);
        text-align: center;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        h3 {
            background-color: ${COLOR_ACCENT};
            font-weight: 700;
            text-align: center;
            padding: ${24 / 32}em ${36 / 32}em;
            border-radius: ${20 / 32}em;
            color: #fff;
            line-height: ${38 / 32}em;
            letter-spacing: -0.03em;
        }

        &:after {
            background: url(${ImgArrow}) 50% 50% no-repeat;
            background-size: contain;
            width: ${340 / DEFAULT_FONT_SIZE}em;
            aspect-ratio: 340/143;
            content: '';
            display: block;
            margin: ${20 / DEFAULT_FONT_SIZE}em auto;
            max-width: 80%;
        }
    }

    .__desc {
        display: flex;
        font-size: clamp(${16 / DEFAULT_FONT_SIZE}em, ${20 / 1024 * 100}vw, ${20 / DEFAULT_FONT_SIZE}em);
        text-align: center;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        // padding: 0 ${10 / DEFAULT_FONT_SIZE}em;

        ul {
            display: flex;
            justify-content: center;
            gap: ${32 / 20}em;
            line-height: ${26 / 20}em;
            letter-spacing: -0.03em;
            font-weight: 500;
            max-width: 1000px;
            width: 100%;
            white-space: nowrap;


            @media screen and (max-width: ${BREAKPOINT.MOBILE}px) {
                white-space: normal;
                gap: 1em;
            }


            li {
                flex: 1;
                background: #fff;
                box-shadow: 0 3px 30px rgba(104, 117, 152, 0.12);
                border-radius: ${16 / 20}em;
                padding: ${24 / 20}em ${28 / 20}em;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

            }
        }
    }
`;