import styled from "styled-components";
import {MainContainer} from "./common";
import {DEFAULT_FONT_SIZE, BREAKPOINT, COLOR_ACCENT} from "./vars.js";

export const MainFooterWrapper = styled.footer`
    border-top: 1px solid #D6D6DE;
    padding: ${40/DEFAULT_FONT_SIZE}em 0 ${60/DEFAULT_FONT_SIZE}em;
`;

export const MainFooterInner = styled(MainContainer)`
    @media screen and (max-width: ${BREAKPOINT.MIN}px) {
        padding: 0 12px;
    }
`;

export const MainFooterContent = styled.div`
    font-size: ${18/DEFAULT_FONT_SIZE}em;
    font-weight: 700;
    
    .__grid {
        display: flex;
        justify-content: space-between;
        gap: ${40/DEFAULT_FONT_SIZE}em;
        @media screen and (max-width: ${BREAKPOINT.TABLET}px) {
            flex-direction: column;
            gap: 0px;
            position: relative;
            .__widget-company {
                .__widget-body {
                    margin-top: 46px;
                }
            }
            .__widget-menu {
                position: absolute;
                right: 0;
                top: 0;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                .__menu {
                    position: absolute;
                    left: 0;
                    top: 100%;
                    margin-top: 14px;
                }
                
            }
        }
    }

    .__brand {
        img {
            width: ${210/DEFAULT_FONT_SIZE}em;
            height: auto;
            display: block;
            @media screen and (max-width: ${BREAKPOINT.MIN}px) {
                width: 150px;
            }
        }
    }
    .__widget {
        &-top {
            height: max(32px, ${46/DEFAULT_FONT_SIZE}em);
            display: flex;
            align-items: center;
            .__menu {
                font-size: max(12px, ${16/DEFAULT_FONT_SIZE}em);
                display: flex;
                align-items: center;
                margin-right: ${24/DEFAULT_FONT_SIZE}em;
                li {
                    &:after {
                        content: '|';
                        margin: 0 ${12/DEFAULT_FONT_SIZE}em;
                        font-weight: 300;
                        color: #D6D6DE;
                    }
                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
        
        &-body {
            margin-top: ${26/DEFAULT_FONT_SIZE}em;
            .__title {
                font-size: max(12px, ${16/DEFAULT_FONT_SIZE}em);
                margin-bottom: ${10/16}em;
                @media screen and (max-width: ${BREAKPOINT.MIN}px) {
                    margin-bottom: ${6/16}em;
                }
            }
            .__info {
                font-size: max(11px, ${13/DEFAULT_FONT_SIZE}em);
                font-weight: 500;
            }
            .__dls {
                display: flex;
                flex-wrap: wrap;
                gap: ${20/13}em;
            }
            p,
            dl {
                display: flex;
               
                margin-top: ${8/13}em;
                line-height: ${18/13}em;
                @media screen and (max-width: ${BREAKPOINT.MIN}px) {
                    margin-top: ${6/13}em;
                }
                dt {
                    white-space: nowrap;
                }
                dt {
                    &:after {
                        content: '|';
                        margin: 0 ${8/13}em;
                        font-weight: 300;
                        color: #D6D6DE;
                    }
                }
             
            }
        }
    }
`;