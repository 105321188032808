import React, { ReactNode, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../Contexts/AuthContext';
interface RequireAuthProps {
  children: ReactNode;
}

const RequireAdminAuth = ({ children }: RequireAuthProps) => {
  const [isAdminSignIn, setIsSignedIn] = useState<boolean | undefined>(undefined);
  const { admin_id } = useAuth();

  const fetchData = async () => {
    try {
      const response = await fetch('/api/auth/readAdminSession');
      console.log(response)
      if (response.ok) {
        const data = await response.json();

        setIsSignedIn(true);
      } else {
        setIsSignedIn(false);
      }
    } catch (error) {
      setIsSignedIn(false);
    }
  };

  useEffect(() =>  {
    if (!admin_id) {
      fetchData();
    } else {
      setIsSignedIn(true);
    }
  }, []);
  
  useEffect(() =>  {
  
  }, [isAdminSignIn]);

  console.log(isAdminSignIn);
  if (isAdminSignIn === false) 
  {
    return <Navigate to="/adminlogin" replace />;
  } 
  else if (isAdminSignIn === true) 
  {
    return <>{children}</>;
  }
  
  return <></>;
};

export default RequireAdminAuth;
