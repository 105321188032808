import {MainFooterContent, MainFooterInner, MainFooterWrapper} from "../styles/footer.js";
import {Button} from "../styles/btn.js";
import ImgLogo from '../imgs/logo.svg';
import {useContext} from "react";
import {LangContext} from "../Contexts/LangContext.jsx";

export default function MainFooter() {
    const { lang, setLang, t } = useContext(LangContext);

    return (
        <MainFooterWrapper>
            <MainFooterInner>
                <MainFooterContent>
                    <div className="__grid">
                        <div className="__widget __widget-company">
                            <div className="__widget-top">
                                <h1 className="__brand"><img src={ImgLogo} alt="OMOVALUE"/></h1>
                            </div>
                            <div className="__widget-body">
                                <h4 className="__title">{t('companyInfo')}</h4>
                                <div className="__info">
                                    <div className="__dls">
                                        <dl>
                                            <dt>{t('company')}</dt>
                                            <dd>{t('companyValue')}</dd>
                                        </dl>
                                        <dl>
                                            <dt>{t('ceo')}</dt>
                                            <dd>{t('ceoValue')}</dd>
                                        </dl>
                                    </div>
                                    <dl>
                                        <dt>{t('address')}</dt>
                                        <dd>{t('addressValue')}</dd>
                                    </dl>
                                    <dl>
                                        <dt>{t('regNumber')}</dt>
                                        <dd>694-86-02485</dd>
                                    </dl>
                                    <dl>
                                        <dt>{t('eComRegNumber')}</dt>
                                        <dd>2022-서울강남-00470</dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        <div className="__widget">
                            <div className="__widget-top __widget-menu">
                                <ul className="__menu">
                                    <li><a href="#">{t('termsOfService')}</a></li>
                                    <li><a href="#">{t('privacyPolicy')}</a></li>
                                </ul>
                                <Button href="#" className={'__cta'}>{t('footerApply')}</Button>
                            </div>
                            <div className="__widget-body">
                                <h4 className="__title">CONTACT</h4>
                                <div className="__info">
                                    <dl>
                                        <dt>{t('tel')}</dt>
                                        <dd>02-6953-1836</dd>
                                    </dl>
                                    <dl>
                                        <dt>{t('email')}</dt>
                                        <dd><a href="mailto:contact@headless.co.kr">contact@headless.co.kr</a></dd>
                                    </dl>
                                    <p>@headless Inc. All rights reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </MainFooterContent>
            </MainFooterInner>
        </MainFooterWrapper>
    )
}