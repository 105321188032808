import {MainCtaSection} from "../styles/cta.js";
import {MainSectionContainer, MainSectionInner} from "../styles/section.js";
import {Button} from "../styles/btn.js";
import ImgIconComment from "../imgs/comment-w.svg";
import ImgBg from "../imgs/cta-bg.svg";
import {useContext} from "react";
import {LangContext} from "../Contexts/LangContext.jsx";
export default function MainCta() {

    const {t, lang} = useContext(LangContext);
    return (
        <MainCtaSection>
            <div className="__bg">
                <svg width="1920" height="645" viewBox="0 0 1920 645" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <rect x="1245.88" y="103.824" width="564" height="1357.65" rx="282"
                              transform="rotate(39.7593 1245.88 103.824)" fill="url(#paint1_linear_132_852)"/>
                        <rect opacity="0.3" x="771.188" y="642.918" width="564" height="1357.65" rx="282"
                              transform="rotate(-136.875 771.188 642.918)" fill="url(#paint2_linear_132_852)"/>
                    </g>
                    <defs>
                        <linearGradient id="paint1_linear_132_852" x1="1479.78" y1="136.174" x2="1642.15"
                                        y2="750.37" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5368DE"/>
                            <stop offset="1" stopColor="#A44680"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_132_852" x1="1005.09" y1="675.268" x2="1167.46"
                                        y2="1289.46" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" stopOpacity="0.04"/>
                            <stop offset="1" stopColor="#5368DE"/>
                        </linearGradient>
                        <clipPath id="clip0_132_852">
                            <rect width="1920" height="644" fill="white" transform="translate(0 0.289062)"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <MainSectionInner>
                <MainSectionContainer>
                    <div data-anims>
                        <h2 className="__size-xl" data-anim dangerouslySetInnerHTML={{__html:t('ctaTitle')}}></h2>
                        <div className="__cta">
                            <Button className="__lg __white" href="#inquiry" data-anim><i><img src={ImgIconComment}
                                                      alt="comment"/></i><span>{t('ctaButton')}</span></Button>
                        </div>
                    </div>
                </MainSectionContainer>
            </MainSectionInner>
        </MainCtaSection>
    )
}