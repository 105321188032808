import React, { useRef, useEffect, useState } from 'react';
import './CustomerValueStatus.css';
import TittleBar from '../TittleBar';
import CBCVTab from '../../CBCVTab';
import useRESTAPI from '../Contexts/RESTAPI';
import { useAuth } from '../Contexts/AuthContext';
import { formatNumberWithCommas } from '../common/Auxiliary';
import Dropdown from './DropDown';
import { useLocation } from 'react-router-dom';
import NoReport from '../common/Norepot';
import SaveAsPDFButton from '../common/SaveAsPDFButton';

export const CustomerValueStatus = (): JSX.Element => {

  const printRef = useRef<HTMLDivElement>(null);

  const { user_id ,admin_id} = useAuth();

  const [cafe24AcqParam, setCafe24AcqParam] = useState(0);
  const [cafe24OrderParam, setCafe24OrderParam] = useState(0);
  const [coupangAcqParam, setCoupangAcqParam] = useState(0);
  const [coupangOrderParam, setCoupangOrderParam] = useState(0);
  const [naverAcqParam, setNaverAcqParam] = useState(0);
  const [naverOrderParam, setNaverOrderParam] = useState(0);

  const [lastRequest, setLastRequests] = useState<any>();
  const [reportData, setReportData] = useState<any>();
  const {
    items: requests,
    fetchItems: fetchRequests,

  } = useRESTAPI('/api/cbcv-request');


  const [isRecv, setRecv] = useState<boolean>(false);
  useEffect(() => {
  }, [isRecv]);

  useEffect(()  => {
    const _ = async () => {
      if (user_id === undefined) 
        return;
         const data = await fetchRequests({ cbcv_user_id: user_id, status: 5 });
         console.log();
        if(!data || data.length ===0)
          setRecv(true);
    }
    _();

  }, [user_id]);


  const location = useLocation();
  useEffect(() => {
    if (admin_id === undefined)
      return;
    const _ = async () => {
      const id = new URLSearchParams(location.search).get('request_id');
      if(id){
        const req = await fetchRequests({request_id:id})
        console.log(req);
        setLastRequests(req[0]);
      }
    }
    _();
  }, [admin_id]);


  useEffect(() => {

    setLastRequests(requests[requests.length - 1]);
  }, [requests]);

  useEffect(() => {
    if (lastRequest === undefined)
      return;
    console.log(lastRequest);
    const url = '/api/cbcv-api/brand_cbcv/'
    const _ = async () => {
      const data = {
        user_id: lastRequest.cbcv_user_id.id,
        request_id: lastRequest.request_id,
        cafe24_acq_param: cafe24AcqParam,
        cafe24_order_param: cafe24OrderParam,
        coupang_acq_param: coupangAcqParam,
        coupang_order_param: coupangOrderParam,
        naver_acq_param: naverAcqParam,
        naver_order_param: naverOrderParam,
        etc_acq_param: 0,
        etc_order_param: 0
      }
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      setRecv(true);
      setReportData(responseData)
    };
    _();
  }, [lastRequest, cafe24AcqParam, cafe24OrderParam, coupangAcqParam, coupangOrderParam, naverAcqParam, naverOrderParam]);

  function values(tittle: string) {


    let acqDropDown = async (value: string) => { };
    let orderDropDown = async (value: string) => { };

/*
0
: 
{name: 'cafe24', value: 3299610.94, acq_param: 0, order_param: 0}
1
: 
{name: 'naver', value: 75459.95, acq_param: 0, order_param: 0}
2
: 
{name: 'coupang', value: 2874261.29, acq_param: 0, order_param: 0}
length
: 
3*/

    let channel_value = undefined;
    if (tittle === '카페24') {
      channel_value  = reportData.data.channel_value.find((a:any)=>a.name==="cafe24")
      acqDropDown = async (value: string) => {
        setCafe24AcqParam(Number.parseInt(value))
      };
      orderDropDown = async (value: string) => {
        setCafe24OrderParam(Number.parseInt(value))
      };
    } else if (tittle === '스마트 스토어') {
      channel_value  = reportData.data.channel_value.find((a:any)=>a.name==="naver")
      acqDropDown = async (value: string) => {
        setNaverAcqParam(Number.parseInt(value))
      };
      orderDropDown = async (value: string) => {
        setNaverOrderParam(Number.parseInt(value))
      };
    } else if (tittle === '쿠팡') {
      channel_value  = reportData.data.channel_value.find((a:any)=>a.name==="coupang")
      acqDropDown = async (value: string) => {
        setCoupangAcqParam(Number.parseInt(value))
      };
      orderDropDown = async (value: string) => {
        setCoupangOrderParam(Number.parseInt(value))
      };
    }

    const options = [
      {
        key: '0',
        label: '기본',
      },
      {
        key: '1',
        label: 'UP (+10%)',
      },
      {
        key: '-1',
        label: 'DOWN (-10%)',
      },
    ];


  

   if(!channel_value)
    return(<></>)
    return (
      <div key={tittle} className="overlap-2">
        <div className="rectangle">
          <div className="flex h-1/2 border-b-2 border-gray-200">
            <div className="flex items-center pl-5 justify-start w-1/2">
              <div className="text-wrapper-11">{tittle}</div>
            </div>
            <div className="flex items-center pl-5 justify-start w-1/2">
              <div className="element-11">₩ {formatNumberWithCommas(channel_value.value)}</div>
            </div>
          </div>
          <div className="grid h-1/2 grid-cols-2">
            <div className="bg-gray-100">
              <div className="flex items-center h-full pl-5 justify-center">
                <div className="text-wrapper-11">신규 고객</div>
              </div>
            </div>
            <div className="bg-gray-100">
              <div className="flex items-center border-l-2 h-full pl-5 justify-center">
                <div className="text-wrapper-11">주문 금액</div>
              </div>
            </div>
            <div>
              <div className="flex items-center h-full  justify-start">
                <Dropdown
                  onOptionChange={acqDropDown}
                  selectedOptionKey={'0'}
                  options={options}
                  buttonClassName="px-4   w-[220px] h-[25x] flex justify-between items-center rounded-lg  "
                  dropdownClassName="flex flex-col border-[1px] border-[#D8DDE3]  w-[220px]  flex justify-between items-start rounded-lg bg-white "
                  itemClassName="px-4  w-full h-[30px]"
                  itemSelectedClassName="px-4 h-[30px] w-full  "
                  itemHoverClassName="px-4 h-[30px] w-full "
                />
              </div>
            </div>
            <div className="border-l-2">
              <div className="flex items-center h-full justify-start">
                <Dropdown
                  onOptionChange={orderDropDown}
                  selectedOptionKey={'0'}
                  options={options}
                  buttonClassName="px-4   w-[220px] h-[25x] flex justify-between items-center rounded-lg  "
                  dropdownClassName="flex flex-col border-[1px] border-[#D8DDE3]  w-[220px]  flex justify-between items-start rounded-lg bg-white "
                  itemClassName="px-4  w-full h-[30px]"
                  itemSelectedClassName="px-4 h-[30px] w-full  "
                  itemHoverClassName="px-4 h-[30px] w-full "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }


  
  if (!reportData) {
    return (
      <div className="flex  text-[#474A4E]">
        <CBCVTab></CBCVTab>
        <div className="BrandAnalysis w-full  min-w-[1200px]">
        <TittleBar  tittle='브랜드 가치 분석' />
          
          {isRecv ? <NoReport></NoReport> :  <div className='flex flex-col gap-11 h-full w-full justify-center items-center'/>}
        </div>
      </div>
    );
  }


  return (
    <div  className="flex text-[#474A4E]">
      <CBCVTab></CBCVTab>
      <div ref={printRef}  className="BrandValue w-full min-w-[1200px]">
        <TittleBar  tittle='브랜드 가치 분석' />
        {reportData &&
        <div ref={printRef} className='flex justify-center items-center'>
          <div className=" pt-[34px] div">
            <div className="w-[1095px] flex justify-between items-end">
              <div className="text-wrapper-9 ">고객 가치 현황 </div>
              <SaveAsPDFButton printRef={printRef}></SaveAsPDFButton>
            </div>
            <div className="h-5"></div>
            <div className="font-normal pl-5 flex justify-start items-center bg-white border-2 rounded-lg h-14 left-66 top-43 w-[885px]">
              가치 생성 일자&nbsp;&nbsp;  {reportData.data.report_created}
            </div>
            <div className="h-12"> </div>
            <div className="text-wrapper-7">사업자명 (서비스명)</div>
            <div className="h-4"> </div>
            <div className="flex  rounded-lg border-2 bg-white w-[1094px] h-[114px]">
              <div className="w-1/3 flex flex-col p-5">
                <div className="h-1/2">
                  <div>매출 대비 비용 비율</div>
                </div>
                <div className="flex h-1/2 justify-end items-end">
                  <div className="text-3xl font-bold">{reportData.data.cost_sga_rate}%</div>
                </div>
              </div>
              <div className="w-1/3 flex flex-col  p-5  border-l-2 border-r-2">
                <div className="h-1/2 ">
                  <div>자본 비용 비율</div>
                </div>
                <div className="flex h-1/2 justify-end items-end">
                  <div className="text-3xl font-bold">{reportData.data.capital_cost_rate}%</div>
                </div>
              </div>
              <div className="w-1/3 flex flex-col  p-5">
                <div className="h-1/2">
                  <div>브랜드 가치</div>
                </div>
                <div className="flex h-1/2 justify-end items-end">
                  <div className="element-8">₩ {formatNumberWithCommas(reportData.data.brand_value)}</div>
                </div>
              </div>
            </div>
            <div className="h-12"> </div>
            <div className="text-wrapper-7 ">채널별 가치</div>
            <div className="h-4"> </div>
            <div className="grid grid-cols-2 gap-8 w-[925px] ">
              {['카페24', '스마트 스토어', '쿠팡'].map((color, index) => {
                return values(color);
              })}
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
};
export default CustomerValueStatus;
