import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ProfileModal } from './ProfileModal';
import useRESTAPI from './Contexts/RESTAPI';
import { useAuth } from './Contexts/AuthContext';

interface TittleBar {
  tittle: string;
}

const AdminTittleBar: React.FC<TittleBar> = ({ tittle }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const {signOut} = useAuth();
  const navi = useNavigate();
  
  async  function signOutHandler() 
  {
    await signOut();
    await navi('/')

  }
  
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setIsModalOpen(false);
    }
  };
  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);

  return (
    <div className="flex w-full justify-between items-center h-[57px] border-b-2  border-gray-200 pr-5 ">
      <p className="pl-[23px] w-1/2 font-bold text-[#474a4e] text-[20px]">
        {tittle}
      </p>
 
        <div onClick={signOutHandler} className=' cursor-pointer flex gap-2 text-gray-500'>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_278_1217)">
              <mask id="mask0_278_1217" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="14">
                <path d="M14 0H0V14H14V0Z" fill="white" />
              </mask>
              <g mask="url(#mask0_278_1217)">
                <path d="M8 1H1V13H8" stroke="#A1A7B1" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6 7H12" stroke="#A1A7B1" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10 4L13 7L10 10" stroke="#A1A7B1" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_278_1217">
                <rect width="14" height="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
          로그아웃
        </div>
    </div>
  );
};

export default AdminTittleBar;
