import {MainSection, MainSectionContainer, MainSectionInner} from "../styles/section";
import {Button} from "../styles/btn";
import {MainKVContent, MainKVDesc, MainKVImg, MainKVSectionContainer} from "../styles/kv";
import ImgIconComment from "../imgs/comment.svg";
import ImgKV1 from "../imgs/kv-img1.png";
import {useContext} from "react";
import {LangContext} from "../Contexts/LangContext.jsx";
export default function MainKV() {
    const {lang, t} = useContext(LangContext);
    return (
        <MainSection>
            <MainSectionInner>
                <MainKVSectionContainer>
                    <MainKVContent>
                        <MainKVDesc data-anims>
                            <h2 className="__size-xxl" data-anim dangerouslySetInnerHTML={{__html:t('kvTitle')}}></h2>
                            <p className="__size-md" data-anim  dangerouslySetInnerHTML={{__html:t('kvDesc')}}></p>
                            <div className="__cta" data-anim>
                                <Button className="__accent __lg" href="#inquiry"><i><img src={ImgIconComment} alt="comment"/></i><span>{t('kvCta')}</span></Button>
                            </div>
                        </MainKVDesc>
                        <MainKVImg data-anims>
                            <div data-anim="img">
                                <img src={ImgKV1} alt={``} style={{aspectRatio: '1662/1034'}}/>
                            </div>
                        </MainKVImg>
                    </MainKVContent>
                </MainKVSectionContainer>
            </MainSectionInner>
        </MainSection>
    )
}
