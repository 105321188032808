import React, { useEffect, useState } from 'react';
import { Button } from '../Button';
import './findmyid.css';
import ModalBase from '../common/ModalBase';
import validator from 'email-validator';
import { useAuth } from '../Contexts/AuthContext';
import { useModalMessage } from '../common/CBCVModalMessageProvider';


export const FindMyID: React.FC<{}> = ()=> {
  const [isIDMode, setIDMode] = useState<boolean>(true);
  const { updateMessage } = useModalMessage();
  const [email, setEmail] = useState<string>('');
  const [identity, setIdentity] = useState<string>('');
  const { findMyID, newPasswordSession } = useAuth();

  const findPassword = async (identity: string, email: string) => {
    if (!validator.validate(email)) {
      updateMessage('error','올바른 이메일 양식을 입력해 주세요.');
      return;
    }
    try {
      await newPasswordSession(identity, email);
    } catch (error) {
      updateMessage('error','계정 정보가 존재하지 않아요. 이메일 주소를 한 번 더 확인해 보시겠어요?');
    }
  };

  const findID = async (email: string) => {
    try {
      if (!validator.validate(email)) {
        updateMessage('error','올바른 이메일 양식을 입력해 주세요.');
        return;
      }
      await findMyID(email);
    } catch (error) {
      updateMessage('error','앗! 계정 정보가 존재하지 않아요. 이메일 주소를 한 번 더 확인해 보시겠어요?');
    }
  };

  const tab = () => {
    if (!isIDMode) {
      return (
        <>
          <div>
            <div className="frame-3">
              <div className="text-wrapper-7">아이디</div>
            </div>

            <div className="div-wrapper">
              <input
                onChange={(e) => setIdentity(e.target.value)}
                placeholder="가입하신 아이디를 입력해 주세요."
                type="text"
                className="text-wrapper-5 w-full"
              />
            </div>
            <div className="h-5" />
            <div className="frame-3">
              <div className="text-wrapper-7">이메일</div>
            </div>

            <div className="div-wrapper">
              <input
                onChange={(e) => setEmail(e.target.value)}
                placeholder="가입 시 입력한 메일 주소를 입력해 주세요."
                type="text"
                className="text-wrapper-5 w-full"
              />
            </div>
          </div>
          <button
            onClick={() => {
              findPassword(identity, email);
            }}
            className="overlap-2 text-wrapper-2"
          >
            비밀번호 찾기
          </button>
        </>
      );
    }

    return (
      <>
        <div>
          <div className="frame-3">
            <div className="text-wrapper-7">이메일</div>
          </div>
          <div className="div-wrapper">
            <input
              onChange={(e) => setEmail(e.target.value)}
              placeholder="가입 시 입력한 메일 주소를 입력해 주세요."
              type="text"
              className="text-wrapper-5 w-full"
            />
          </div>
        </div>
        <button
          onClick={() => {
            findID(email);
          }}
          className="overlap-2 cursor-pointer text-wrapper-2"
        >
          아이디 찾기
        </button>
        <div className='h-14'></div>
      </>
    );
  };
  
  const a =
    'border-t-2 w-full h-12 border-r-2  border-l-2 border-t-black  border-r-gray-300  border-l-gray-300';
  const b = 'w-full h-12 border-b-2 border-b-gray-300';

  return (
    <div className="findmyid">
      <div className="div">
        <img
          className="img"
          alt="Element"
          src="/logo-b.png"
        />
        <div className="h-8"></div>
        <div className="overlap-group">
          <div>
            <div className="text-wrapper-6">아이디/비밀번호찾기</div>
            <div className="h-6"></div>
            <div className="flex ">
              <div className={isIDMode ? a : b}>
                <div onClick={() => setIDMode(true)} className="cursor-pointer text-subtittle">
                  아이디 찾기
                </div>
              </div>
              <div className={!isIDMode ? a : b}>
                <div
                  onClick={() => setIDMode(false)}
                  className="cursor-pointer text-subtittle"
                >
                  비밀번호 찾기
                </div>
              </div>
            </div>
          </div>
          {tab()}
        </div>
        <div className="h-16"></div>
        <div className="flex gap-2"></div>

      </div>
    </div>
  );
};

export default FindMyID;
