export const translations = {
    en: {
        login: "Login",
        applyFree: "Apply for Free Trial",
        apply: "Apply",
        companyInfo: "Company Info",
        company: "Company Name",
        companyValue: "Headless Inc.",
        ceo: "CEO",
        ceoValue: "Namgung Jihwan",
        address: "Address",
        addressValue: "17-107, Uisadang-daero 83, Yeongdeungpo-gu, Seoul, Republic of Korea",
        regNumber: "Reg. Number",
        eComRegNumber: "E-commerce Registration Number",
        footerApply: "Apply",
        termsOfService: "Terms of Service",
        privacyPolicy: "Privacy Policy",
        tel: "Tel",
        email: "Email",
        kvTitle: `<strong>Future Revenue Analysis</strong><br/> and <strong>Brand Customer<br/> Valuation Solutions</strong><br/> based on Brand Customer Data`,
        kvDesc: `Get future sales analysis and brand value evaluation<br/>with e-commerce customer data analyzed by AI!`,
        kvCta: "Apply for Free Trial",
        partnersTitle: `OMOVALUE<br/> collaborates with <strong>major investors</strong>`,
        summaryTitle: `<strong>Analyze future sales</strong> and <strong>understand brand customer value</strong> more accurately<br/> using a bottom-up approach!`,
        summaryDescTitle: 'Total Customer Value of Each Sales Channel',
        summaryDesc1: 'Own Mall Customer Data',
        summaryDesc2: 'Coupang Order Data',
        summaryDesc3: 'Naver Smart Store Order Data',
        feature1SubTitle: '<strong>Sales Forecast</strong>',
        feature1Title: `We provide <strong>predictive analysis</strong> of <strong>future sales</strong> by sales channel.`,
        feature1Desc: `Accurately estimate the lifetime value of individual customersand enhance your business by strengthening customer data analysis,<br/> growing your business into a customer-centric model!`,
        feature2SubTitle: '<strong>Scenario Analysis</strong>',
        feature2Title: `Check <strong>various scenarios</strong><br/> in response to changes in customer behavior.`,
        feature2Desc: `Adjust channel variables such as purchase probability, churn probability, new customer acquisition,<br/> and order amount to explore future revenue scenarios!`,
        clvTitle: `<strong>Experience future value analysis<br/> of Customer Lifetime Value</strong> through AI-powered data processing`,
        feature3SubTitle: '<strong>Customer Analysis</strong>',
        feature3Title: `We analyze <strong>individual customer segments.</strong>`,
        feature3Desc: `We analyze the core customer segments of your brand.<br/> Identify key customers who can generate significant future revenue and efficiently allocate limited marketing resources.`,
        feature4SubTitle: '<strong>Product Analysis</strong>',
        feature4Title: `We provide <strong>product analysis</strong> through<br/> the examination of customer behavior`,
        feature4Desc: `Understand customer behavior data by analyzing purchase cycles, products bought by new customers, and seasonal popular products.`,
        ctaTitle: `Continuous Brand Growth<br/> Join Omovalue`,
        ctaButton: `Apply for Free Trial`,
        contactTitle: `<strong>Apply for Service</strong>`,
        contactDesc: `Upon verifying the information you provide internally,<br/> we will contact you as soon as possible.`,
        contactInput1: 'Company Name',
        contactInput2: 'Name',
        contactInput3: 'Job Title',
        contactInput4: 'Email',
        contactInput5: 'Phone',
        contactSubmit: 'Apply',
        contactInputCheck1: 'Company Name field is required',
        contactInputCheck2: 'Name field is required',
        contactInputCheck3: 'Job Title field is required',
        contactInputCheck4: 'Email field is required',
        contactInputCheck5: 'Phone field is required',
        contactSubmitSuccess: 'Apply completed',
    },
    ko: {
        login: "로그인",
        applyFree: "무료 사용 신청",
        apply: "신청하기",
        companyInfo: "사업자정보확인",
        company: "회사명",
        companyValue: "헤드리스 주식회사",
        ceo: "대표",
        ceoValue: "남궁지환",
        address: "주소",
        addressValue: "서울특별시 영등포구 의사당대로83 (오투타워) 위워크 17-107",
        regNumber: "사업자등록번호",
        eComRegNumber: "통신판매번호",
        footerApply: "사용 신청하기",
        termsOfService: "이용약관",
        privacyPolicy: "개인정보처리방침",
        tel: "전화",
        email: "이메일",
        kvTitle: `고객 데이터 기반<br/><strong>미래 매출 분석</strong> 및<br/><strong>브랜드 고객 가치 평가 솔루션</strong>`,
        kvDesc: `AI로 분석된 브랜드의 전자상거래 고객 데이터로<br/> 미래 매출 분석과 브랜드 가치 평가를 받아보세요!`,
        kvCta: "무료 사용 신청",
        partnersTitle: `오모벨류는<br/><strong>주요 투자사</strong>와 함께 하고 있습니다.`,
        summaryTitle: `<span>Bottom-Up 방식으로</span> <span>더 정확하게</span><br/> <span><strong>미래 매출을 분석</strong>하고</span> <strong>브랜드 고객 가치</strong>를 알아보세요!`,
        summaryDescTitle: '각 판매 채널 고객 가치의 합',
        summaryDesc1: '자사몰 고객 데이터',
        summaryDesc2: '쿠팡 주문 데이터',
        summaryDesc3: '네이버 스마트스토어 주문 데이터',
        feature1SubTitle: '<strong>매출 예상</strong>',
        feature1Title: `판매 채널별 <strong>미래 매출</strong>을 <strong>예상 분석</strong>해 드려요`,
        feature1Desc: `개별 고객의 생애 매출을 정확하게 추정하고, 고객 데이터 분석을 강화하여 사업을 고객 중심 모델로 키워나가보세요!`,
        feature2SubTitle: '<strong>시나리오 분석</strong>',
        feature2Title: `고객의 행동 변화에 맞춰<br/> <strong>다양한 시나리오</strong>를 확인하세요`,
        feature2Desc: `구매 확률, 이탈 확률, 신규 고객 유입, 주문 금액 등의 채널 변수들을 조정하며 미래 매출 시나리오를 확인할 수 있어요!`,
        clvTitle: `AI를 활용한 데이터 처리로 <strong>Customer Lifetime Value<br/> 미래가치 분석을 경험해보세요</strong>`,
        feature3SubTitle: '<strong>고객 분석</strong>',
        feature3Title: `<strong>개별 고객 세그먼트</strong>를 분석해드려요`,
        feature3Desc: `브랜드 핵심 고객층을 객별 고객 세그먼트로 분석해 드려요.<br/> 미래 매출을 많이 발생 할 수 있는 핵심 고객을 파악하고 한정된 마케팅 자원을 효율적으로 투입할 수 있어요!`,
        feature4SubTitle: '<strong>상품 분석</strong>',
        feature4Title: `고객 행동 분석을 통해 <strong>상품 분석</strong>을 제공해요`,
        feature4Desc: `고객 구매 주기, 신규 고객의 구매 상품, 계절별 인기 상품 분석으로 고객 행동 데이터를 파악해보세요!`,
        ctaTitle: `지속적인 브랜드 성장<br/> 오모벨류와 함께해보세요`,
        ctaButton: `무료 사용 신청`,
        contactTitle: `<strong>서비스 사용 신청하기</strong>`,
        contactDesc: `등록해주신 정보를 내부적으로 확인 후,<br/> 최대한 빠른 시일 내에 연락드릴 예정입니다.`,
        contactInput1: '회사명',
        contactInput2: '성함',
        contactInput3: '직책',
        contactInput4: '이메일',
        contactInput5: '전화번호',
        contactSubmit: '제출하기',
        contactInputCheck1: '회사명 항목은 필수입력 항목입니다.',
        contactInputCheck2: '성함은 필수입력 항목입니다.',
        contactInputCheck3: '직책은 필수입력 항목입니다.',
        contactInputCheck4: '이메일은 필수입력 항목입니다.',
        contactInputCheck5: '전화번호는 필수입력 항목입니다.',
        contactSubmitSuccess: '신청이 완료되었습니다.',
    },
};