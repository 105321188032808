/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from 'react';

interface Props {
  className: any;
}

export const PieChartIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`product-icon-1 ${className}`}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3_28495"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <path d="M18 0H0V18H18V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_3_28495)">
        <path
          d="M14.4877 10.421C14.3799 12.0847 13.6369 13.6433 12.4125 14.7747C11.188 15.9061 9.57565 16.5238 7.90868 16.5C6.2236 16.4548 4.61998 15.7653 3.42793 14.5735C2.23588 13.3816 1.54613 11.7781 1.50068 10.093C1.47665 8.42603 2.09406 6.81354 3.22528 5.58889C4.3565 4.36425 5.91502 3.62107 7.57868 3.51301C7.63341 3.51085 7.68801 3.51979 7.7392 3.53929C7.79038 3.55879 7.8371 3.58844 7.87652 3.62646C7.91595 3.66449 7.94727 3.7101 7.96861 3.76054C7.98995 3.81099 8.00085 3.86524 8.00068 3.92001V10H14.0817C14.1365 9.99911 14.191 10.0095 14.2416 10.0305C14.2923 10.0516 14.3381 10.0828 14.3762 10.1223C14.4142 10.1618 14.4438 10.2087 14.463 10.2601C14.4822 10.3114 14.4906 10.3662 14.4877 10.421Z"
          stroke="#BCC2C9"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.5 6.5C16.5 5.17392 15.9732 3.90215 15.0355 2.96447C14.0979 2.02678 12.8261 1.5 11.5 1.5V6.1C11.5 6.20609 11.5421 6.30783 11.6172 6.38284C11.6922 6.45786 11.7939 6.5 11.9 6.5H16.5Z"
          stroke="#BCC2C9"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
export default PieChartIcon