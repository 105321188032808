import {css} from "styled-components";
import {DEFAULT_FONT_SIZE, BREAKPOINT, COLOR_ACCENT} from "./vars.js";

export const typoStyles = css`
    font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    font-size: ${DEFAULT_FONT_SIZE}px;
    color: #6B6C75;
    letter-spacing: -0.03em;
    background: #fff;

    word-break: keep-all;
    @media screen and (max-width: ${BREAKPOINT.TABLET}px) {
        font-size: 16px;
    }
    @media screen and (max-width: ${BREAKPOINT.MOBILE}px) {
        font-size: 14px;
    }

    @media screen and (max-width: ${BREAKPOINT.MIN}px) {
        font-size: 12px;
    }
    .__color-accent {
        color: ${COLOR_ACCENT};
    }
    
    strong {
        font-weight: inherit;
    }
    
    .__size-xxl {
        font-size: clamp(${42/DEFAULT_FONT_SIZE}em, ${60/1440 * 100}vw, ${60/DEFAULT_FONT_SIZE}em);
        font-weight: 700;
        letter-spacing: -0.03em;
        line-height: ${84/60}em;
    }

    .__size-xl {
        font-size: ${40/DEFAULT_FONT_SIZE}em;
        font-weight: 700;
        line-height: ${56/40}em;
        letter-spacing: -0.03em;
    }

    .__size-lg {
        font-size: ${24/DEFAULT_FONT_SIZE}em;
        font-weight: 700;
        line-height: ${32/24}em;
        letter-spacing: -0.03em;
    }
    
    .__size-md {
        font-size: ${20/DEFAULT_FONT_SIZE}em;
        font-weight: 500;
        letter-spacing: -0.03em;
        line-height: ${30/20}em;
    }
    
    &[data-lang="en"] {
        .__size-xxl {
            font-size: clamp(${34 / DEFAULT_FONT_SIZE}em, ${54 / 1440 * 100}vw, ${54 / DEFAULT_FONT_SIZE}em);
        }
    }
`;