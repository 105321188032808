import styled from "styled-components";
import {DEFAULT_FONT_SIZE, BREAKPOINT, COLOR_ACCENT} from "./vars.js";

export const MainPartnersContent = styled.div`
    padding-bottom: ${180/DEFAULT_FONT_SIZE}em;
    &:before {
        content: '';
        height: clamp(${180/DEFAULT_FONT_SIZE}em, ${296/1280*100}vw, ${296/DEFAULT_FONT_SIZE}em);
        display: block;
        width: 100%;
        background: linear-gradient(174.8deg, rgba(231, 219, 255, 0.7) 8.96%, #FFFFFF 74.24%);
        margin-bottom: ${20/DEFAULT_FONT_SIZE}em;
        
    }
`;

export const MainPartnersList = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1920px;
    margin: 0 auto;
    .__item {
        width: 20%;
        min-width: 200px;
        img {
            display: block;
            width: 100%;
        }
    }
`;